.sw-block {
    @include props-block-model;
    @include props-margin;
    @include props-width;
    @include props-colors;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    @include block-model;
    @include margin;
    @include width;
    @include colors;

    &_hoverable {
        &:hover {
            background: var(--background-hover);
            border-color: var(--border-color-hover);
            box-shadow: var(--box-shadow-hover);
        }
    }

    &__content {
        &_start,
        &_main,
        &_end {
            @include props-block-model;
            @include props-width;
            @include props-margin;

            @include block-model;
            @include width;
            @include margin;

            position: relative;
        }
    }

    &__footer,
    &__header {
        @include props-block-model;
        @include props-width;
        @include props-margin;

        @include block-model;
        @include width;
        @include margin;
    }
}

// implemented
.sw-block {
    // 16 24 24 24 основные
    // 32 32 20 32 синие блоки
    // 24 32 24 32
    // 32 32 32 32
    // 12 24 24 20 боковой про баланс
    // 20 16 20 16 боковые
    --padding-top: 16px;
    --padding-start: 24px;
    --padding-end: 24px;
    --padding-bottom: 24px;
    --background: var(--sw-background);
    --border-radius: var(--sw-border-radius);
    --margin-bottom: 20px;

    &__header {
        --margin-bottom: 16px;

        &:last-child {
            --margin-bottom: 0;
        }

        .sw-block_info & {
            --margin-bottom: calc(var(--sw-multiple-px) * 6);
            --padding-bottom: calc(var(--sw-multiple-px) * 6);
            --border-width: 0 0 1px 0;
            --border-style: solid;
            --border-color: var(--sw-border-color-dark);
        }
    }

    &__content {
        &_start {
            flex-shrink: 0;
        }
        &_main {
            --width: 100%;

            &__left {
                flex-shrink: 0;
                width: 200px;
                margin-right: 40px;

                app-dateplate + app-dateplate {
                    display: block;
                    margin-top: 12px;
                }

                .sw-chip_status {
                    --margin-top: 8px;
                }
            }
            &__center {
                width: 100%;
            }
            .sw-block__footer {
                --padding-top: 12px;
                --margin-start: 0;
                --margin-end: 0;
                --padding-start: 0;
                --padding-end: 0;
            }
        }
        &_end {
            --padding-start: calc(var(--sw-multiple-px) * 10);
            --border-width: 0 0 0 1px;
            --border-style: solid;
            --border-color: var(--sw-border-color);
            --width: 337px;
            --margin-start: calc(var(--sw-margin) * 2);
            flex-shrink: 0;
        }
    }

    &__footer {
        --margin-top: 32px;
        --margin-start: -24px;
        --margin-end: -24px;
        --border-width: 1px 0 0 0;
        --border-style: solid;
        --border-color: var(--sw-border-color);
        --padding-top: 20px;
        --padding-start: 24px;
        --padding-end: 24px;

        .sw-block_info & {
            --margin-top: 16px;
            --margin-start: -20px;
            --margin-end: -32px;
            --padding-start: 20px;
            --padding-end: 32px;

            &_normal {
                --margin-top: calc(var(--sw-multiple-px) * 6);
                --padding-top: calc(var(--sw-multiple-px) * 6);
                --margin-start: 0;
                --margin-end: 0;
                --padding-start: 0;
                --padding-end: 0;
                --border-color: var(--sw-border-color-dark);
            }

            > .swh-flex {
                margin-left: 70px;
            }

            .sw-h6 {
                margin-bottom: var(--sw-multiple-px);
                line-height: 16px;
            }
        }

        aside & {
            --margin-top: 0;
            --margin-start: -16px;
            --margin-end: -16px;
            --padding-top: 20px;
            --padding-start: 16px;
            --padding-end: 16px;
        }
    }

    .sw-block-total {
        float: right;
        border-top: 1px solid var(--sw-border-color);
        padding-top: calc(var(--sw-multiple-px) * 2);
    }

    &_outline {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--sw-border-color);
        --border-color-hover: var(--sw-border-color-dark);

        .sw-toolbar_baseline + & {
            --margin-top: calc(var(--sw-multiple-px) * 4);
            --padding-top: 24px;
        }

        & + .sw-toolbar_baseline {
            --margin-top: calc(var(--sw-multiple-px) * 12);
        }
    }
    &_shadow {
        // с тенью
        --box-shadow: 0px 15px 15px -10px rgba(239, 240, 242, 0.4);
        --box-shadow-hover: 0px 10px 20px -10px rgba(144, 150, 162, 0.2);
    }
    &_mini {
        --padding-top: 8px;
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-bottom: 8px;
    }
    &_info {
        // 32 32 20 32 синие блоки
        --padding-top: 32px;
        --padding-start: 32px;
        --padding-end: 32px;
        --padding-bottom: 20px;
        --background: var(--sw-background-info);

        .sw-block & {
            --margin-top: 20px;
            --margin-bottom: 0;
            --padding-top: 8px;
            --padding-start: 12px;
            --padding-end: 12px;
            --padding-bottom: 8px;

            .sw-table_clear td {
                --padding-top: 2px;
                --padding-bottom: 2px;
            }
        }

        app-legal-document & {
            --margin-top: 12px;
            --padding-top: 16px;
            --padding-start: 16px;
            --padding-end: 16px;
            --padding-bottom: 16px;

            a {
                font-weight: 500;
                letter-spacing: 0.25px;
                text-decoration: none;
            }
        }

        sw-skeleton {
            --background: var(--sw-border-color-dark);
        }

        @media (max-width: 576px) {
            .sw-block__footer > .swh-flex {
                display: block;
                margin-left: 0;

                .sw-text-size_mini {
                    font-size: 14px;
                    line-height: 20px;
                }

                > *:not(.swh-no-margin) {
                    margin-bottom: 20px;
                }
            }
        }
    }
    &_info-secondary {
        // желтоватые блоки
        --padding-top: 16px;
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-bottom: 16px;
        --background: var(--sw-background-info-secondary);
    }
    &_secondary {
        // серые
        --background: var(--sw-background-medium);
    }
    // &_top-line {
    //     --margin-top: calc(var(--sw-margin) * 2);
    //     --padding-top: var(--sw-padding);
    //     --border-width: 1px 0 0 0;
    //     --border-style: solid;
    //     --border-color: var(--sw-background-medium);
    // }

    aside & {
        // 20 16 20 16 боковые
        --padding-top: 20px;
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-bottom: 20px;

        hr {
            --margin-top: calc(var(--sw-multiple-px) * 4);
            --margin-bottom: calc(var(--sw-multiple-px) * 4);
        }

        &__header {
            // --margin-bottom: 16px;
            h6 a {
                text-decoration: none;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 2px;
                    right: 2px;
                    bottom: -2px;

                    border-bottom: 1px dashed var(--sw-link-color);
                }
            }

            &_underline {
                --margin-start: -16px;
                --margin-end: -16px;
                --margin-top: -20px;

                --padding-start: 16px;
                --padding-end: 16px;
                --padding-top: 12px;
                --padding-bottom: 12px;

                --border-width: 0 0 1px 0;
                --border-style: solid;
                --border-color: var(--sw-border-color);
            }
        }

        &.sw-block_outline {
            sw-toolbar:first-child {
                margin-top: -10px;
            }
        }
    }

    aside.sw-aside_left & {
        // 20 16 20 16 боковые
        --padding-top: 16px;
        --padding-start: 20px;
        --padding-end: 20px;
        --padding-bottom: 16px;
    }

    &_warning-message {
        @extend .sw-block_info-secondary;

        > sw-icon {
            --sw-icon-medium-size: 20px;
            float: left;
            margin-right: calc(var(--sw-multiple-px) * 3);
        }

        .sw-block__content {
            overflow: hidden;
            line-height: 20px;

            .sw-list_bullet {
                --margin-top-list: 3px;
            }
        }

        @media (max-width: 576px) {
            app-wasteplace-widget &,
            app-facility-widget & {
                --margin-bottom: 0;
                --margin-top: 20px;
            }

            .sw-block__content .sw-list_bullet {
                flex-wrap: wrap;
            }
        }
    }

    &_on-map {
        --width: 357px;
        --padding-start: calc(var(--sw-multiple-px) * 4);
        --padding-end: calc(var(--sw-multiple-px) * 4);

        position: absolute;
        z-index: 150;
        top: calc(var(--sw-multiple-px) * 4);
        left: calc(var(--sw-multiple-px) * 4);

        .sw-close {
            position: absolute;
            right: 16px;
            top: 16px;
        }
    }

    &_item {
        --padding-top: 32px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-bottom: 32px;
        --border-width: 0 0 1px 0;
        --border-style: solid;
        --border-color: var(--sw-border-color);

        .sw-block__content_end {
            --width: 432px;
            --padding-start: 84px;
            --margin-start: 32px;
        }
    }

    &_search {
        --max-width: 612px;
        --padding-top: 20px;
        --padding-start: 20px;
        --padding-end: 20px;
        --padding-bottom: 20px;
        --margin-bottom: 0;

        position: absolute;
        z-index: 150;
        bottom: 0;
        left: 0;

        &.sw-block_shadow {
            --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
        }

        .sw-block__footer {
            --margin-bottom: -20px;
            --margin-start: -20px;
            --margin-end: -20px;
            --padding-top: var(--sw-padding);
            --padding-bottom: var(--sw-padding);
            --padding-start: calc(var(--sw-multiple-px) * 4);
            --padding-end: calc(var(--sw-multiple-px) * 4);
        }
    }

    &_segment {
        --padding-top: 12px;
        --padding-start: 12px;
        --padding-end: 12px;
        --padding-bottom: 12px;
        --margin-bottom: 0;
        --border-width: 1px;
        --border-style: solid;
        --border-color: transparent;

        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            --border-color: var(--sw-background-dark);
        }

        > sw-button {
            margin-left: calc(var(--sw-multiple-px) * 2);
        }

        sw-trip-line {
            --width: 301px;
        }

        &.sw-block--active {
            --background: var(--sw-background-dark);
        }
    }

    &_editable {
        @extend .sw-block_hoverable;
        --padding-top: 8px;
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-bottom: 8px;
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--sw-border-color-dark);
        --border-color-hover: var(--sw-text-secondary);
        --margin-bottom: calc(var(--sw-multiple-px) * 2);

        display: block;
        position: relative;
        cursor: pointer;

        .sw-list_bullet {
            --color: var(--sw-color-action);
        }

        > sw-icon {
            --sw-icon-small-size: 12px;
            --sw-icon-medium-size: 16px;
            position: absolute;
            right: 16px;
            bottom: 10px;
            visibility: hidden;
            color: var(--sw-color-action);
        }

        &:hover {
            sw-icon {
                visibility: visible;
            }
        }
    }

    &_pane {
        --padding-top: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        --margin-bottom: 0;
        --border-radius: 4px 4px 0px 0px;
        height: 100%;

        .sw-block__header {
            --height: 36px;

            --margin-bottom: 0;
            --padding-start: 16px;
            --padding-end: 4px;
            --border-width: 0 0 1px;
            --border-color: var(--sw-border-color);
            --border-style: solid;
            --border-radius: 4px 4px 0px 0px;
            position: relative;
            display: flex;
            align-items: center;
            height: var(--height);

            sw-tabs {
                --height: 36px;
                --font-size: 13px;
                margin-left: 20px;
            }

            &__btns {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 11;
                background: linear-gradient(
                    270deg,
                    var(--sw-color-white) 75%,
                    rgba(var(--sw-color-white-rgb), 0.8) 94.14%
                );
                padding-right: var(--padding-end);
                padding-left: var(--padding-end);
                border-radius: 0 4px 0 0;

                display: flex;
                align-items: center;
            }
        }

        .sw-block__content {
            height: calc(100% - 36px);
        }

        &--expanded {
            --border-width: 1px;
            --border-style: solid;
            --border-color: var(--sw-border-color);
            --border-color-hover: var(--sw-border-color-dark);
            --box-shadow: 0px 15px 15px -10px rgba(239, 240, 242, 0.4);

            position: absolute;
            z-index: 152;
        }
    }

    &_widget {
        &_outline {
            @extend .sw-block_outline;
        }

        @media (max-width: 992px) {
            &,
            &_outline {
                --padding-start: 0;
                --padding-end: 0;
                --padding-top: 0;
                --padding-bottom: 0;
                --border-width: 0;

                sw-button {
                    display: inline-block;
                }
            }
        }

        @media (max-width: 576px) {
            &,
            &_outline {
                sw-button {
                    display: block;
                }
            }
        }
    }

    @media (max-width: 576px) {
        --margin-bottom: 16px;
    }
}
