.sw-tooltip-static {
    /**
     * @prop --background: Фон тултипа
     * @prop --color: цвет текста тултипа
     *
     * @prop --border-radius: радиус скругления тултипа
     * @prop --box-shadow: тень тултипа
     *
     * @prop --padding-start: начальный внутренний отступ тултипа
     * @prop --padding-end: конечный внутренний отступ тултипа
     * @prop --padding-top: верхний внутреннний отступ тултипа
     * @prop --padding-bottom: нижний внутренниц отступ тултипа
     *
     * @prop --min-height: минимальная высота тултипа
     * @prop --min-width: минимальная ширина тултипа
     * @prop --width: ширина тултипа
     * @prop --max-width: максимальная ширина тултипа
     *
     * @prop --font-size: размер шрифта тултипа
     * @prop --font-weight: жирность шрифта тултипа
     * @prop --line-height: межстрочный интервал шрифта тултипа
     *
     * @prop --height-arrow: высота стрелки тултипа
     * @prop --width-arrow: ширина стрелки тултипа
     */
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --box-shadow: initial;
    --background: initial;
    --min-height: initial;
    --max-height: initial;
    --min-width: initial;
    --width: initial;
    --max-width: initial;
    --color: inherit;
    --font-size: inherit;
    --font-weight: inherit;
    --line-height: initial;

    --height-arrow: initial;
    --width-arrow: initial;
    --border-color-arrow: var(--background);

    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    box-sizing: border-box;
    padding-left: var(--padding-start);
    padding-right: var(--padding-end);
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    border-radius: var(--border-radius);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    box-shadow: var(--box-shadow);
    background: var(--background);
    min-height: var(--min-height);
    max-height: var(--max-height);
    min-width: var(--min-width);
    width: var(--width);
    max-width: var(--max-width);
    color: var(--color);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);

    .sw-tooltip__arrow {
        position: absolute;
    }
}

// bottom
.sw-tooltip-static .sw-tooltip__arrow {
    top: calc(var(--height-arrow) / -1);
    border-left: calc(var(--width-arrow) / 2) solid transparent;
    border-right: calc(var(--width-arrow) / 2) solid transparent;
    border-bottom: var(--height-arrow) solid var(--border-color-arrow);
    left: 50%;
    transform: translateX(-50%);
}

// implemented
.sw-tooltip-static {
    --padding-start: calc(var(--sw-multiple-px) * 3);
    --padding-end: calc(var(--sw-multiple-px) * 3);
    --padding-top: calc(var(--sw-multiple-px) * 3);
    --padding-bottom: calc(var(--sw-multiple-px) * 3);
    --border-radius: var(--sw-border-radius);
    --background: var(--sw-text-color);
    --min-height: 40px;
    --min-width: 100px;
    --max-width: 312px;
    --color: var(--sw-text-color-contrast);
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 16px;

    --height-arrow: 4px;
    --width-arrow: 12px;
}

.sw-tooltip-static.sw-tooltip_light{
    --max-width: 405px;
    --max-height: 380px;
    --background: var(--sw-background);
    --color: var(--sw-text-color);

    --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--sw-background-medium);

    --height-arrow: 8px;
    --width-arrow: 24px;

    overflow: auto;
}
