.sw-trip-tape {
    .sw-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .sw-chip_status {
            --margin-bottom: 4px;
        }

        > * {
            &:first-child {
                max-width: 180px;
                width: 100%;
            }
        }
    }

    table {
        --margin-bottom: 0;

        & + .sw-item {
            --border-width: 0;
        }
    }

    .sw-dl_inline {
        margin-right: calc(var(--sw-multiple-px) * 4);
    }

    &__price {
        position: relative;
        padding-right: calc(var(--sw-multiple-px) * 4);
        cursor: pointer;

        sw-icon {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -4px;
            font-size: 8px;
        }
    }

    &.sw-block {
        .sw-map-wrapper {
            --height: 400px;
            --margin-top: var(--sw-margin);
        }
    }
}
