.sw-filter__additional {

    .sw-control {

        &__range {
            .sw-control__field {
                --padding-field-end: 0;
            }

            .sw-control__field-group__end {
                sw-button.sw-btn_clear {
                    --padding-start: 10px;
                    --padding-end: 10px;
                }
            }
        }
    }
}
