.sw-dispatcher-panel {
    flex-shrink: 0;
    position: relative;
    height: 100%;
    width: 388px; // по макетам 384, но тогда не все помещается, так как 4px съедает скролл
    margin-right: 48px;
    border-radius: var(--sw-border-radius);
    transition: margin-left 300ms ease-in-out;
    will-change: margin-left;
    pointer-events: all;

    &--closed {
        margin-left: -376px;
    }

    &__controls {
        position: absolute;
        right: -24px;
        top: 12px;
        display: flex;
        flex-direction: column;

        sw-button {
            --background: var(--sw-color-white);
            margin-bottom: 8px;
        }
    }

    &__main {
        position: relative;
        height: 100%;
        background: var(--sw-color-white);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        border-radius: var(--sw-border-radius);
        transition: opacity 500ms;
        will-change: opacity;

        .sw-dispatcher-panel--closed & {
            opacity: 0;
        }

        sw-tabs {
            --height: 36px;
            --padding-top: 12px;
            --padding-start: 16px;
            --padding-end: 16px;
            --padding-bottom: 0;
            --border-width: 0 0 1px 0;
            --border-color: var(--sw-border-color);
            --border-style: solid;
            --tab-margin-end: 16px;
            --font-size: 14px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &_list {
            @extend .sw-dispatcher-panel__content;
            height: calc(100% - 49px);
        }
    }

    .sw-toolbar_header-dispatcher {
        background: var(--sw-color-white);

        app-input-default {
            width: 274px;

            .sw-control,
            .sw-control__field-group__end sw-button.sw-btn_clear {
                --height: 30px;
            }
        }
    }

    .sw-list_underline {
        > .sw-item {
            --padding-top: 16px;
            --padding-bottom: 16px;
            --padding-end: 16px;

            &:hover {
                background: var(--sw-background-info);
            }
        }
    }

    .sw-marker {
        width: 4px;
        height: 16px;
        margin-right: 12px;
        background: var(--sw-color-base);
    }

    .sw-list_trip {
        + hr {
            --margin-top: 12px;
            --margin-bottom: 8px;
            --border-style: dashed;
            --border-color: var(--sw-border-color-dark);
            margin-left: 32px;
        }
    }

    .sw-dateplate__time_small {
        font-size: 13px;
    }

    app-left-task-item {
        sw-toolbar > *[slot='end'] {
            position: relative;
            top: -10px;
        }
    }

    app-left-vehicle-item {
        sw-toolbar > *[slot='start'] {
            align-items: center;
        }
    }

    app-left-position-item {
        position: relative;

        .sw-btn-create {
            display: none;
            position: absolute;
            bottom: 0;
            right: 16px;
        }
    }

    .sw-item:hover > app-left-position-item {
        .sw-btn-create {
            display: block;
        }
    }
}

.sw-dispatcher-panel__card {
    header {
        position: relative;
        border-radius: var(--sw-border-radius) var(--sw-border-radius) 0 0;
        background: var(--sw-background-medium);
        padding: 16px;
        text-align: center;

        .sw-back {
            position: absolute;
            left: 16px;
            top: 16px;
        }

        h5 {
            margin: 8px 0 2px;
        }

        .sw-list_bullet {
            --margin-bottom-list: 24px;
            justify-content: center;

            > .sw-item {
                &:last-child {
                    --padding-end: 0;
                }
            }
        }

        .sw-list_trip {
            --margin-start-list: 0;
            text-align: left;

            + hr {
                --border-style: solid;
                --margin-top: 16px;
                --margin-bottom: 16px;
                margin-left: 0;
            }
        }

        .sw-vehicle-number {
            text-align: left;
        }

        .sw-row-elements {
            --margin-end: 8px;
        }

        hr {
            --margin-top: 16px;
            --margin-bottom: 12px;
            --border-color: var(--sw-border-color-dark);
        }
    }

    &__sign {
        --border-radius: 50%;
    }

    main {
        padding: 32px 16px;
    }

    .swh-ellipsis {
        display: block;
    }

    sw-accordion {
        &::part(header) {
            --padding-top: 0;
            --padding-bottom: 10px;
        }

        &::part(body) {
            --padding-bottom: 24px;
        }

        .sw-list_underline:not(.sw-scheduler) {
            --margin-start-list: -16px;
            --margin-end-list: -16px;

            > .sw-item {
                &:first-child {
                    --border-width: 1px 0;
                    --margin-top: 2px;
                }
            }
        }

        .sw-list_history:not(.sw-list_trip_time) {
            --margin-end-list: -10px;

            > .sw-item {
                --padding-start: 24px;

                &::before {
                    width: 7px;
                    height: 7px;
                }

                &::after {
                    top: 14px;
                    left: 4px;
                }

                .sw-list_bullet > .sw-item {
                    --padding-end: 8px;
                }
            }

            hr {
                --border-style: dashed;
                --margin-top: 8px;
                --margin-bottom: 8px;
                margin-right: 10px;
                border-radius: 4px 4px 0 0;
            }
        }

        .sw-list_gallery {
            flex-wrap: wrap;

            .sw-figure {
                --width: 59px;
                --height: 59px;
                --border-radius: 2px;
                --border-width: 0;
            }

            > .sw-item {
                --margin-end: 8px;
                --margin-bottom: 8px;
            }
        }

        .sw-list_bullet:not(.sw-list_history .sw-list_bullet) {
            --margin-start-list: 16px;
        }

        .sw-dl_dot-leaders {
            --width-dt: 35%;
            --width-dd: 65%;
            --padding-start-dd: 6px;
            --margin-bottom-dt: 12px;
            --margin-bottom-dd: 12px;
            --color-dt: #888888;
            // --color-dot: var(--sw-text-color-secondary);
            // --margin-bottom-dl: 0;
            dt {
                font-size: 12px;
                line-height: 16px;

                &:after {
                    top: -2px;
                }
            }

            dd {
                font-size: 13px;
                line-height: 16px;
            }
        }
    }

    dd {
        .sw-coordinates {
            margin-top: -10px;
            // white-space: nowrap;
            color: var(--sw-text-color);
        }
    }

    .sw-block_warning-message {
        --margin-top: 12px;
        --margin-bottom: 16px;
        font-size: 13px;
        line-height: 20px;

        > sw-icon {
            float: none;
            margin-bottom: 12px;
        }

        .sw-list_bullet {
            --margin-bottom-list: 4px;
            flex-wrap: wrap;
        }
    }

    .sw-scheduler {
        --margin-top-list: 12px;
        --margin-bottom-list: 24px;
        --width: auto;

        > .sw-item {
            --padding-top: 4px;
            --padding-bottom: 4px;
        }
    }

    .sw-contact {
        padding-bottom: 12px;
        margin-bottom: 16px;
        border-bottom: 1px solid var(--sw-border-color);

        &:first-of-type {
            --margin-top: 12px;
        }

        + .sw-contact {
            --margin-top: 0;
        }
    }
}
