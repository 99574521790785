.sw-control-interval {
    @include props-block-model;
    @include props-width;
    @include props-colors;

    @include block-model;
    @include width;
    @include colors;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    sw-timepicker,
    sw-datepicker {
        --box-shadow: none;
        --border-width: 0;
    }
}

.sw-control-interval {
    --background: var(--sw-color-white);
    --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
    --border-radius: var(--sw-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--sw-background-medium);

    app-timeinterval & {
        --width: 418px;
        // --max-width: 100%;
        --padding-top: 26px;

        sw-timepicker {
            --padding-top: 0;
        }

        p {
            margin: 0 16px 8px;
        }
    }

    app-dateinterval & {
        --width: 792px;
        --padding-start: 20px;
        --padding-end: 20px;
        --padding-bottom: 12px;
        --padding-top: 8px;
    }
}
