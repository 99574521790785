.sw-summary-page {
    .sw-grid_card {
        --sw-grid-column-padding-top: 0;
        --sw-grid-column-padding-bottom: 32px;
    }

    .sw-block {
        --padding-top: 24px;
        --padding-bottom: 24px;
        --padding-start: 24px;
        --padding-end: 24px;
        --margin-bottom: 0;

        h5 {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
        }
    }

    .sw-list {
        > .sw-item {
            --margin-bottom: 16px;

            &:last-child {
                --margin-bottom: 0;
            }
        }
    }

    .sw-graph {
        display: flex;
        justify-content: stretch;
        width: 100%;

        > *:first-child {
            width: 100%;
        }

        &__title {
            font-size: 10px;
            font-weight: 500;
            line-height: 16px;
            text-transform: uppercase;
            letter-spacing: 1;
        }
        &__bar {
            height: 24px;
            background: var(--sw-color-primary);
            border-radius: 2px;
            min-width: 1px;

            &_secondary {
                background: var(--sw-text-color-secondary);
            }
        }
        &__count {
            flex-shrink: 0;
            align-self: flex-end;
            width: 50px;
            font-size: 14px;
            line-height: 24px;
            text-align: right;
        }
    }
}
