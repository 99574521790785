.swh-float-left {
    float: left !important;
}

.swh-float-right {
    float: right !important;
}

.swh-float-start {
    float: left !important;
}
[dir=rtl] .swh-float-start, :host-context([dir=rtl]) .swh-float-start {
    float: right !important;
}

.swh-float-end {
    float: right !important;
}
[dir=rtl] .swh-float-end, :host-context([dir=rtl]) .swh-float-end {
    float: left !important;
}

@media (min-width: 576.98px) {
    .swh-float-sm-left {
        float: left !important;
    }

    .swh-float-sm-right {
        float: right !important;
    }

    .swh-float-sm-start {
        float: left !important;
    }
    [dir=rtl] .swh-float-sm-start, :host-context([dir=rtl]) .swh-float-sm-start {
        float: right !important;
    }

    .swh-float-sm-end {
        float: right !important;
    }
    [dir=rtl] .swh-float-sm-end, :host-context([dir=rtl]) .swh-float-sm-end {
        float: left !important;
    }
}
@media (min-width: 768px) {
    .swh-float-md-left {
        float: left !important;
    }

    .swh-float-md-right {
        float: right !important;
    }

    .swh-float-md-start {
        float: left !important;
    }
    [dir=rtl] .swh-float-md-start, :host-context([dir=rtl]) .swh-float-md-start {
        float: right !important;
    }

    .swh-float-md-end {
        float: right !important;
    }
    [dir=rtl] .swh-float-md-end, :host-context([dir=rtl]) .swh-float-md-end {
        float: left !important;
    }
}
@media (min-width: 992px) {
    .swh-float-lg-left {
        float: left !important;
    }

    .swh-float-lg-right {
        float: right !important;
    }

    .swh-float-lg-start {
        float: left !important;
    }
    [dir=rtl] .swh-float-lg-start, :host-context([dir=rtl]) .swh-float-lg-start {
        float: right !important;
    }

    .swh-float-lg-end {
        float: right !important;
    }
    [dir=rtl] .swh-float-lg-end, :host-context([dir=rtl]) .swh-float-lg-end {
        float: left !important;
    }
}
@media (min-width: 1024.98px) {
    .swh-float-xl-left {
        float: left !important;
    }

    .swh-float-xl-right {
        float: right !important;
    }

    .swh-float-xl-start {
        float: left !important;
    }
    [dir=rtl] .swh-float-xl-start, :host-context([dir=rtl]) .swh-float-xl-start {
        float: right !important;
    }

    .swh-float-xl-end {
        float: right !important;
    }
    [dir=rtl] .swh-float-xl-end, :host-context([dir=rtl]) .swh-float-xl-end {
        float: left !important;
    }
}
@media (min-width: 1200px) {
    .swh-float-xxl-left {
        float: left !important;
    }

    .swh-float-xxl-right {
        float: right !important;
    }

    .swh-float-xxl-start {
        float: left !important;
    }
    [dir=rtl] .swh-float-xxl-start, :host-context([dir=rtl]) .swh-float-xxl-start {
        float: right !important;
    }

    .swh-float-xxl-end {
        float: right !important;
    }
    [dir=rtl] .swh-float-xxl-end, :host-context([dir=rtl]) .swh-float-xxl-end {
        float: left !important;
    }
}
