app-map {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.sw-map {
    height: 100%;
    width: 100%;

    &__base-control {
        position: absolute;
        z-index: 150; // из-за слоев leafleat
        top: 50%;
        right: calc(var(--sw-multiple-px) * 5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);

        sw-tooltip {
            --padding-top: 4px;
            --padding-bottom: 4px;
            --min-height: 24px;
            --min-width: auto;
        }

        .sw-btn-group_h {

            sw-button {
                --border-color: var(--sw-border-color-dark);

                --background: var(--sw-color-white);
                --color: var(--sw-text-color-secondary);

                --background-activated: var(--sw-color-info-tint);
                --color-activated: vvar(--sw-text-color-secondary);

                --background-hover: var(--sw-background-medium);
                --color-hover: var(--sw-text-color-secondary);

                --background-focused: var(--background-hover);
                --color-focused: var(--color-hover);

                &:hover {
                    &::part(native) {
                        border-color: var(--border-color);
                    }

                    sw-icon {
                        color: var(--color-hover);
                    }
                }
            }
        }

        & > * {
            margin-bottom: calc(var(--sw-multiple-px) * 8);

            &:last-child {
                margin-bottom: 0;
            }

            &:only-child {
                margin-bottom: 0;
            }
        }

        .sw-figure & {
            top: 8px;
            right: 8px;
            transform: translateY(0);
        }

        // types
        &_top {
            top: 20px;
            transform: translateY(0);
        }
    }

    &-wrapper {
        /*
        * @prop --height: Высота обертки, желательно в пикселях.
        *                 Без определенной высоты, компонент карты не будет понимать до чего расстягиваться
        * @prop --width: Ширина обертки
        * @prop --margin-start: внешний начальный отступ обертки
        * @prop --margin-end: внешний конечный отступ обертки
        * @prop --margin-top: внешний верхний отступ обертки
        * @prop --margin-bottom: внешний нижний отступ обертки
        */
        --height: 500px;
        --width: 100%;
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;

        position: relative;
        height: var(--height);
        width: var(--width);
        margin: var(--margin-top) var(--margin-end) var(--margin-bottom) var(--margin-start);
    }

    &-circle {
        float: right;
        width: 180px;
        height: 180px;
        margin-left: var(--sw-margin);

        .sw-map {
            clip-path: circle(90px);
        }
    }
}

// implemented
.sw-map-wrapper {
    &_small {
        --height: 260px;
        --margin-bottom: 32px;
    }

    &_dd {
        @extend .sw-map-wrapper;
        --height: 100px;
        --width: 350px;
        border: 1px solid var(--sw-border-color);
        border-radius: var(--sw-border-radius);
    }

    app-route-widget & {
        --height: 400px;
    }

    @media (max-width: 1180px) {
        &_dd {
            --height: 106px;
        }
    }

    @media (max-width: 576px) {
        app-route-widget & {
            --height: 184px;
            border: 1px solid var(--sw-border-color);
            border-radius: 8px;

            .sw-map {
                border-radius: 8px;
            }

            .sw-open-btn,
            .sw-mobile-content {
                position: absolute;
                top: 8px;
                left: 8px;
                z-index: 40;
            }

            .sw-mobile-content {
                right: 8px;
            }

            .sw-mobile-content:not(.sw-modal .sw-mobile-content){
                display: none;
            }
        }

        &_dd {
            --height: 72px;
        }
    }
}
