.sw-form {
    .sw-map-wrapper {
        --height: 350px;
    }
}

.sw-control {
    /*
    * @prop --padding-field-start: внутренний начальный отступ поля
    * @prop --padding-field-end: внутренний конечный отступ поля
    * @prop --padding-field-top: внутренний верхний отступ поля
    * @prop --padding-field-bottom: внутренний нижний отступ поля
    *
    * @prop --background: фон поля
    * @prop --background-hover: фон поля при наведении
    * @prop --background-focus: фон поля при фокусе посредством клавиш
    * @prop --background-active: фон поля при состоянии active
    * @prop --background-disabled: фон отключенного поля 

    * @prop --border-radius: радиус скругления поля
    * @prop --border-width: толщина границы поля
    * @prop --border-style: стиль границы поля
    * @prop --border-style-hover: стиль границы при наведении
    * @prop --border-style-focus: стиль границы при фокусе посредством клавиш
    * @prop --border-style-active: стиль границы при состоянии active
    * @prop --border-style-disabled: стиль границы отключенного поля
    * @prop --border-style-estimated: стиль границы расчетного поля
    * @prop --border-color: цвет границы поля
    * @prop --border-color-hover: цвет границы поля при при наведении
    * @prop --border-color-focus: цвет границы поля при фокусе посредством клавиш
    * @prop --border-color-active: цвет границы поля при состоянии active
    * @prop --border-color-disabled: цвет границы отключенного поля
    *
    * @prop --control-width: ширина контрола, по умолчанию не задана
    *
    * @prop --color: цвет текста поля 
    * @prop --color-hover: цвет текста поля при наведении
    * @prop --color-focus: цвет текста поля при фокусе посредством клавиш
    * @prop --color-active: цвет текста поля при состоянии active
    * @prop --color-disabled: цвет текста отключенного поля
    *
    * @prop --color-label: цвет метки
    * @prop --font-size-label: размер шрифта метки
    *
    * @prop --height: высота поля
    * @prop --font-size: размер шрифта поля
    * @prop --size-icon: размер иконки
    */
    @include props-margin;

    --padding-field-start: 0;
    --padding-field-end: 0;
    --padding-field-top: 0;
    --padding-field-bottom: 0;

    --background-hover: var(--background);
    --background-focus: var(--background);
    --background-active: var(--background);
    --background-disabled: var(--background);
    --border-radius: initial;
    --border-width: initial;
    --border-color: initial;
    --border-color-hover: var(--border-color);
    --border-color-focus: var(--border-color);
    --border-color-active: var(--border-color);
    --border-color-disabled: var(--border-color);
    --border-style: initial;
    --border-style-hover: var(--border-style);
    --border-style-focus: var(--border-style);
    --border-style-active: var(--border-style);
    --border-style-disabled: var(--border-style);
    --border-style-estimated: var(--border-style);
    --color: inherit;
    --color-hover: var(--color);
    --color-focus: var(--color);
    --color-active: var(--color);
    --color-disabled: var(--color);

    --font-size: inherit;
    --size-icon: initial;

    --font-size-label: var(--font-size);
    --color-label: var(--color);
    position: relative;
    width: var(--control-width);

    @include margin;

    &__label {
        display: block;
        margin-bottom: var(--sw-multiple-px);
        font-size: var(--font-size-label);
        font-weight: 500;
        line-height: 20px;
        color: var(--color-label);

        .sw-color {
            color: var(--sw-color-base);
        }
    }

    &__field-group {
        position: relative;
        display: flex;
        align-items: center;
        background: var(--background);
        border-radius: var(--border-radius);
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);

        &-wrapper {
            display: flex;
            align-items: center;
            width: 100%;

            sw-button {
                flex-shrink: 0;
            }
        }

        &:hover {
            background: var(--background-hover);
            border-color: var(--border-color-hover);
            border-style: var(--border-style-hover);
            color: var(--color-hover);
        }
        &:focus,
        &--focused {
            background: var(--background-focus);
            border-color: var(--border-color-focus);
            border-style: var(--border-style-focus);
            color: var(--color-focus);

            .sw-control__field-group__end {
                sw-button.sw-btn_clear:not(.sw-eraser) {
                    sw-icon {
                        color: var(--color-focused);
                    }
                }
            }
        }
        &:active,
        &--activated {
            background: var(--background-active);
            border-color: var(--border-color-active);
            border-style: var(--border-style-active);
            color: var(--color-active);

            .sw-control__field-group__end {
                sw-button.sw-btn_clear:not(.sw-eraser) {
                    sw-icon {
                        color: var(--color-activated);
                    }
                }
            }
        }
        &:disabled,
        &[disabled],
        &--disabled {
            background: var(--background-disabled);
            border-color: var(--border-color-disabled);
            border-style: var(--border-style-disabled);
            color: var(--color-disabled);
            pointer-events: none;

            input,
            select,
            .sw-control__field-group__start,
            .sw-control__field-group__end {
                background: transparent;
            }

            .sw-select__trigger {
                background: var(--background-disabled);
            }
        }

        &_estimated {
            border-style: var(--border-style-estimated);

            &:hover,
            &:focus,
            &.sw-control__field-group--focused,
            &:active,
            &.sw-control__field-group--activated,
            &:disabled,
            &[disabled],
            &.sw-control__field-group--disabled {
                background: var(--background);
                border-color: var(--border-color);
                border-style: var(--border-style-estimated);
                color: var(--color);
            }
        }

        sw-icon {
            width: var(--size-icon);
            height: var(--size-icon);
            margin: 0;
            color: var(--sw-placeholder-color);
        }

        button {
            flex-shrink: 0;
            outline: 0;
            border: 0;
            padding: 0;
            margin: 0;
            background: transparent;
            min-width: var(--height);
            height: var(--height);

            > & {
                width: 33%;
            }

            // для кнопок инкримента в числовом поле
            > &:first-of-type {
                text-align: right;
            }
            > &:last-of-type {
                text-align: left;
            }
        }

        sw-badge.sw-badge-medium {
            border-radius: 2px;
            padding: 0 6px;
            text-transform: uppercase;
        }

        &__start,
        &__end {
            flex-shrink: 0;
            min-width: var(--height);
            background: var(--background);
            color: var(--sw-placeholder-color);
            text-align: center;
        }

        &__start {
            border-radius: var(--border-radius) 0 0 var(--border-radius);

            + .sw-control__field,
            + .sw-control__field .sw-select {
                padding-left: 0;
            }
        }
        &__end {
            display: flex;
            align-items: center;
            border-radius: 0 var(--border-radius) var(--border-radius) 0;

            > * {
                flex-shrink: 0;
                min-width: var(--height);
            }

            button {
                border-left: 1px solid var(--sw-border-color);
            }

            sw-button.sw-btn_clear {
                --padding-end: calc(var(--sw-multiple-px) * 3);
                --padding-start: calc(var(--sw-multiple-px) * 3);

                :hover {
                    sw-icon {
                        color: var(--color-hover);
                    }
                }
            }

            .sw-eraser {
                visibility: hidden;

                .sw-control__field-group:hover & {
                    visibility: visible;
                }
            }
        }
    }

    &__field-wrapper {
        position: relative;
        flex: 1 1 auto;
    }

    &__field {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: var(--height);
        outline: none;
        border: none;
        border-radius: var(--border-radius);
        background: transparent;
        padding-left: var(--padding-field-start);
        padding-right: var(--padding-field-end);
        padding-top: var(--padding-field-top);
        padding-bottom: var(--padding-field-bottom);
        font-size: var(--font-size);
        font-weight: 400;

        &::placeholder {
            color: var(--sw-placeholder-color);
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        // .sw-list_bullet {
        //     --margin-top: 0;
        //     --margin-botom: 0;

        //     .sw-item {
        //         &::before {
        //             content: '\2022';
        //             padding-right: var(--padding-start)
        //         }

        //         &:first-child {
        //             --padding-start: 8px;

        //             &::before {
        //                 display: inline-block;
        //             }
        //         }
        //     }
        // }

        .sw-control__field-group_estimated &,
        &[disabled],
        .sw-control__field-group--disabled & {
            pointer-events: none;
        }

        &.swh-no-padding,
        &.swh-no-padding + .sw-mask {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__units {
        display: block;
        border-left: 1px solid var(--sw-border-color);
        padding-left: var(--sw-padding);
        padding-right: var(--sw-padding);
        font-size: var(--font-size);
        font-weight: 500;
        line-height: var(--height);
        white-space: nowrap;
        letter-spacing: 0.3px;
    }

    &__note {
        font-size: calc(var(--font-size) - 2px);
        font-weight: 400;
        line-height: 12px;
        color: var(--sw-text-color-secondary);

        p {
            margin-top: calc(var(--sw-multiple-px) * 2);
            margin-bottom: 0;
        }

        .sw-color {
            color: var(--sw-color-base);
        }
    }

    textarea {
        resize: vertical;
    }
}

// implemented
.sw-control {
    --height: 34px;

    --padding-field-start: var(--sw-padding);
    --padding-field-end: var(--sw-padding);

    --background: var(--sw-color-white);
    --border-radius: var(--sw-border-radius);
    --border-width: 1px;
    --border-color: var(--sw-border-color-dark);
    --border-color-hover: var(--sw-text-color-secondary);
    --border-color-focus: var(--sw-color-primary);
    --border-color-active: var(--sw-color-primary);
    --border-style: solid;
    --border-style-estimated: dashed;
    --color: var(--sw-text-color);
    --font-size: 13px;
    --font-size-label: calc(var(--font-size) + 1px);

    --size-icon: 16px;

    --background-disabled: var(--sw-background-medium);
    // --border-color-disabled: var(--sw-border-color-dark);
    // --border-style-disabled: var(--border-style-disabled);
    // --color-disabled: var(--color-disabled);

    .sw-form & {
        --margin-bottom: calc(var(--sw-multiple-px) * 5);
    }

    .sw-form .sw-grid_accordion & {
        --margin-bottom: var(--sw-multiple-px);
    }

    .sw-form_oneline & {
        --margin-bottom: 0;
    }

    app-modal-permission-info .sw-form & {
        --margin-bottom: 32px;
    }

    &__units {
        color: var(--sw-text-color-secondary);
    }

    &__label {
        .sw-grid_accordion & {
            --font-size-label: 12px;
            --color-label: var(--sw-text-color-secondary);
            font-weight: 400;
            line-height: 16px;
        }

        .sw-form_oneline & {
            line-height: 36px;
        }
    }

    &__field-group {
        &-wrapper {
            .sw-control__field-group {
                margin-right: 32px;
                width: calc(100% - 32px);
            }

            sw-button {
                --border-radius: 50%;
                margin-left: 8px;
            }
        }

        > button {
            --size-icon: 12px;
        }

        &__end {
            button {
                min-width: calc(var(--height) + var(--sw-multiple-px) * 2);
            }

            sw-button.sw-btn_clear {
                --height: 34px;
                --background-hover: transparent;
                --background-focused: transparent;
                --background-activated: transparent;
                --color-hover: var(--sw-color-action);
                --color-focused: var(--sw-color-action);
                --color-activated: var(--sw-color-action);

                &.sw-eraser {
                    --padding-end: calc(var(--sw-multiple-px) * 2);
                    --padding-start: calc(var(--sw-multiple-px) * 2);
                }
            }
        }
    }

    textarea {
        --padding-field-top: 10px;
        --padding-field-bottom: 10px;
        min-height: 64px;
    }

    sw-button,
    .sw-figure_btn {
        input[type='file'] {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
        }
    }

    .sw-figure {
        --height: 134px;
    }

    .sw-list_control-dd {
        --position-top: var(--height);
    }

    .sw-loader {
        --background: transparent;
        --min-width: 20px;
        --width: 20px;
        --max-width: 20px;
        --height: 20px;

        --spinner-size: 20px;
        --spinner-background: transparent;
        --spinner-color: var(--sw-color-primary);
        position: static;
        margin: 0;
        padding: 0;

        &__spinner {
            margin: 0;
        }
    }

    // types
    &_datetime {
        position: relative;

        > .sw-control__note {
            display: flex;
            align-items: center;
            margin-top: 8px;

            .sw-checkbox label::before {
                margin-left: 0;
            }
        }

        .sw-control-interval {
            // position: absolute;
            // z-index: 10;
            // top: 40px;
        }
    }
    @media (max-width: 992px) {
        &--activated {
            app-select-search &,
            app-search-filter & {
                --border-radius: 0;
                --border-width: 1px 0 1px 0;
                position: fixed;
                z-index: 1002;
                left: 0;
                right: 0;
                top: 0;

                .sw-eraser {
                    display: none;
                }

                .sw-control__label {
                    display: none;
                }

                .sw-list_control-dd {
                    --max-height: calc(100vh - 36px - 1px);
                    --margin-top-list: 1px;
                    --padding-top-list: 0;
                    --padding-end-list: 20px;
                    --padding-start-list: 20px;
                    --border-radius-list: 0;

                    --border-width: 0 0 1px 0;
                    --border-color: var(--sw-border-color);
                    --border-style: solid;
                    --padding-end: 0;
                    --padding-start: 0;
                    --padding-top: 24px;
                    --padding-bottom: 20px;

                    height: var(--max-height);

                    .sw-item {
                        --background-hover: var(--background);

                        &.sw-radio,
                        &.sw-checkbox {
                            border-bottom: 1px solid var(--border-color);
                            // margin-top: 0; // не обязательно?
                        }
                    }
                }
            }
        }
    }
}

.sw-eraser_outer {
    position: absolute;
    z-index: 1;
    right: 0;
    top: -8px;
}

// MEDIA
.sw-control {
    @media (max-width: 992px) {

        &:not(app-lot-proposition-item-mobile &) {
            --height: 42px;
        }
    }
}
