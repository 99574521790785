.swh-text-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
}

.swh-text-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
}

.swh-text-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
}

@media (min-width: 576px) {
    .swh-text-sm-uppercase {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: uppercase !important;
    }

    .swh-text-sm-lowercase {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: lowercase !important;
    }

    .swh-text-sm-capitalize {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: capitalize !important;
    }
}
@media (min-width: 768px) {
    .swh-text-md-uppercase {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: uppercase !important;
    }

    .swh-text-md-lowercase {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: lowercase !important;
    }

    .swh-text-md-capitalize {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: capitalize !important;
    }
}
@media (min-width: 992px) {
    .swh-text-lg-uppercase {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: uppercase !important;
    }

    .swh-text-lg-lowercase {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: lowercase !important;
    }

    .swh-text-lg-capitalize {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: capitalize !important;
    }
}
@media (min-width: 1200px) {
    .swh-text-xl-uppercase {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: uppercase !important;
    }

    .swh-text-xl-lowercase {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: lowercase !important;
    }

    .swh-text-xl-capitalize {
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: capitalize !important;
    }
}
