.sw-page {
    @include props-padding;
    @include padding;
}

.sw-page {
    --padding-bottom: 120px;

    &-header {
        border-bottom: 1px solid var(--sw-border-color);
        margin-bottom: 32px;

        &.swh-sticky {
            background: var(--sw-background);
        }

        &--scrolling.swh-sticky {
            z-index: 160;

            h1 {
                font-size: 20px;
                font-weight: 400;
                line-height: normal;
            }

            sw-toolbar {
                // &::part(end) {
                //     position: relative;
                //     top: 15px;
                // }

                & + .sw-list_bullet {
                    --margin-bottom-list: calc(var(--sw-multiple-px) * 3);
                }
            }
        }

        sw-tabs {
            position: relative;
            bottom: -1px;
        }

        sw-button {
            sw-icon.sw-icon-medium {
                margin-left: -8px;
                margin-right: -8px;
                font-size: 20px;
            }
        }

        .sw-dropdown sw-button.sw-dropdown__trigger sw-icon.sw-icon-small {
            --sw-icon-small-size: 20px;
            margin-left: -8px;
            margin-right: -8px;
        }
    }

    @media (max-width: 576px) {
        --padding-bottom: 88px;
    }
}
