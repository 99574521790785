.sw-message-plane {
    @include props-padding;
    @include props-colors;
    @include props-font;

    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    @include colors;
    @include font;

    @extend .sw-container;

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > *:not(.sw-message-plane__close){
            width: 100%;
        }

        > .sw-message-plane__close {
            flex-shrink: 0;
        }
    }
}

.sw-message-plane {
    --padding-top: calc(var(--sw-multiple-px) * 3);
    --padding-bottom: calc(var(--sw-multiple-px) * 3);
    --background: var(--sw-color-base, var(--sw-white-color));
    --color: var(--sw-color-contrast, var(--sw-text-color));
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 20px;

    &.sw-color-warning {
        --color: var(--sw-text-color);

        .sw-message-plane__close {
            --color: var(--sw-text-color);
            --background-hover: rgba(var(--sw-color-white-rgb), .1);
            --background-activated: rgba(var(--sw-color-tint), .1);
            --background-focused: rgba(var(--sw-color-white-rgb), .1);
        }
    }
}
