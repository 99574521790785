.sw-filter {
    @include props-padding;
    @include padding;

    &__base {
        @include props-margin;
        @include margin;
        display: flex;
        align-items: center;

        &__wrapper {
            display: flex;
            width: 100%;
        }

        filter {
            width: 100%;
        }

        > sw-button {
            --multiple: 11;
            --height: 56px;
            --border-radius: 0 var(--sw-border-radius) var(--sw-border-radius) 0;
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 1px;
            flex-shrink: 0;
        }
    }

    &__additional {
        @include props-padding;
        @include padding;
    }

    @media (max-width: 992px) {
        .sw-filter__base {
            &,
            .sw-filter__base__wrapper {
                display: block;
            }

            > sw-button {
                --multiple: 4;
                --height: 44px;
                --border-radius: var(--sw-border-radius);
                margin-right: 32px;
            }
        }
    }
}

// implemented
.sw-filter {
    --padding-top: 16px;
    --padding-bottom: 20px;

    &__base {
        --margin-bottom: 16px;
        background: var(--sw-background);
        border-radius: var(--sw-border-radius);
    }

    &__additional {
        --sw-grid-columns: 15;
        --padding-top: calc(var(--sw-multiple-px) * 5 - 5px);

        // > sw-row > sw-col {
        //     &:nth-child(5n+1) {
        //         --sw-grid-column-padding-start: 0;
        //     }
        //     &:nth-child(5n) {
        //         --sw-grid-column-padding-end: 0;
        //     }
        // }
    }

    .sw-list_control-dd-wrapper {
        --padding-bottom: 0;
    }

    app-search-filter .sw-list_control-dd {
        .sw-infinite-scroll-content {
            padding-bottom: 60px;

            .sw-item + sw-button {
                position: fixed;
                z-index: 150;
                bottom: 12px;
                left: 20px;
                right: 20px;
            }
        }

        @media (min-width: 992.98px) {
            overflow: hidden;

            .sw-infinite-scroll-content {
                overflow: auto;
                height: 182px;
            }
        }
    }

    @media (max-width: 992px) {
        .sw-filter__additional {
            --sw-grid-columns: 10;
        }
    }

    @media (max-width: 576px) {
        .sw-filter__base {
            + .swh-padding-bottom {
                --sw-coef: 0 !important;
            }
        }

        .sw-filter__additional {
            --sw-grid-columns: 5;
            --padding-top: 0;
            --padding-bottom: 20px;
        }

        .sw-filter-apply {
            --height: 44px;
            display: block;
            margin: 32px 0;
        }
    }
}
