.sw-breadcrumbs {
    --padding-top: initial;
    --padding-bottom: initial;
    --margin-bottom: initial;
    --text-decoration: initial;
    --color: initial;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    margin-bottom: var(--margin-bottom);
    color: var(--color);

    a {
        text-decoration: var(--text-decoration);
        color: var(--color);
    }

    .sw-container & {
        padding-left: 0;
        padding-right: 0;
    }
}

// implements
.sw-breadcrumbs {
    --padding-top: 16px;
    --padding-bottom: 16px;
    --margin-bottom: 16px;
    --text-decoration: none;
    --color: var(--sw-text-color-secondary);

    .sw-list {
        --font-size: 12px;
        --line-height: 16px;
    }

    .sw-item {
        --margin-end: 8px;
    }

    sw-icon {
        --sw-icon-small-size: 12px;
        margin-right: var(--margin-end);
    }
}
