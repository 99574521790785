.sw-card {
    @include props-block-model;
    @include props-margin;
    @include props-width;
    @include props-colors;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    @include block-model;
    @include margin;
    @include width;
    @include colors;

    &:hover {
        background: var(--background-hover);
        border-color: var(--border-color-hover);
        box-shadow: var(--box-shadow-hover);
    }
}

.sw-card {
    --padding-top: 12px;
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-bottom: 36px;
    --background: var(--sw-background);
    --border-radius: var(--sw-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--sw-border-color);
    --border-color-hover: var(--sw-color-primary);

    // так как карточка распологается внутри флекс контейнера, и имеет в дочерних канву
    // (канва не дружит с флекс контейнером вычисляя свои размеры)
    // необходимо задать на каком либо уровне обертки фиксированную, а не плавающую ширину.
    // для адаптивного дизайна - необходимо будет, либо отступы делать динамическими,
    // либо для каждой точки задавать свою фиксированную ширину
    --width: 316px;
    height: 100%;
    position: relative;
    line-height: 20px;

    .sw-map-wrapper {
        --height: 132px;
        --margin-bottom: var(--sw-margin);
        position: relative;

        &, .leaflet-container {
            border-radius: var(--sw-border-radius);
        }

        .sw-dropdown {
            position: absolute;
            z-index: 150;
            top: 4px;
            right: 4px;

            &__trigger {
                --height: 28px;
                --background: var(--sw-color-white);
                --padding-start: 12px;
                --padding-end: 12px;
                --color: var(--sw-placeholder-color);

                width: 28px;
            }
        }
    }

    .sw-chip_status {
        --margin-bottom: 8px;
        --margin-top: 8px;
    }

    &__date {
        position: absolute;
        bottom: 8px;
        left: var(--padding-start);
        right: var(--padding-end);
        color: var(--sw-text-color-secondary)
    }

    &.sw-card_with-button {
        --padding-bottom: calc(36px + 16px + var(--sw-padding));

        > sw-button,
        > sw-skeleton:last-child {
            position: absolute;
            bottom: 16px;
            left: var(--sw-padding);
            right: var(--sw-padding);
        }
    }

    // type
    &_trip {
        --padding-top: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        --border-radius: 0;
        --width: auto;
        --border-color: transparent;
        --border-color-hover: var(--sw-background-dark);
        --margin-bottom: calc(var(--sw-multiple-px) * 4);
        height: auto;
        cursor: pointer;

        .sw-card__header {
            height: 36px;
            padding: 0 var(--sw-padding);
            background: var(--sw-background-medium);
            line-height: 36px;
        }

        .sw-card__body {
            padding: var(--sw-padding);
        }

        &--selected {
            --border-color: var(--sw-color-action);
            --border-color-hover: var(--border-color);
        }

        &-wrapper {
            height: 500px;
            overflow: auto;
            padding-right: calc(var(--sw-multiple-px) * 2);
        }
    }
}
