* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

html {
    width: 100%;
    height: 100%;
    text-size-adjust: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    // position: fixed;
    // width: 100%;
    // max-width: 100%;
    height: 100%;
    // max-height: 100%;
    // text-rendering: optimizeLegibility;
    // overflow: hidden;
    // touch-action: manipulation;
    // -webkit-user-drag: none;
    // -ms-content-zooming: none;
    // word-wrap: break-word;
    // overscroll-behavior-y: none;
    // text-size-adjust: none;
}

.sw-container {
    /*
    * @prop --max-width-container: максимальная ширина контента
    * @prop --padding-side: высчитываемая величина внутренних начального и конечного отступов
    */
    padding: 0 calc(50% - 680px);
    --padding-side: calc(var(--max-width-container) / 2);
    padding: 0 calc(50% - var(--padding-side));

    &::after,
    &::before {
        display: table;
        content: '';
        clear: both;
    }
}
.sw-container_margin {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--max-width-container);
    padding-left: 40px;
    padding-right: 40px;
}

.sw-container,
.sw-container_margin {
    --max-width-container: 1440px;

    // @media (min-width: 319.98px) {
    //     --max-width-container: 300px;
    // }

    // @media (min-width: 575.98px) {
    //     --max-width-container: 536px;
    // }

    // @media (min-width: 767.98px) {
    //     --max-width-container: 720px;
    // }

    // @media (min-width: 991.98px) {
    //     --max-width-container: 948px;
    // }

    @media (min-width: 1199.98px) {
        --max-width-container: 1120px;
    }

    @media (min-width: 1279.98px) {
        --max-width-container: 1200px;
    }

    @media (min-width: 1359.98px) {
        --max-width-container: 1280px;
    }

    @media (min-width: 1436px) {
        --max-width-container: 1360px;
    }

    @media (max-width: 1435px) {
        // --max-width-container: 1360px;
        padding: 0 40px;
    }

    @media (max-width: 1024px) {
        padding: 0 32px;
    }

    @media (max-width: 576.98px) {
        padding: 0 20px;
    }
}

hr {
    --margin-top: initial;
    --margin-bottom: initial;
    --border-color: var(--sw-border-color);
    --border-style: solid;
    border-width: 1px 0 0;
    border-color: var(--border-color);
    border-style: var(--border-style);
    height: 0;
    margin-top: var(--margin-top, calc(var(--sw-multiple-px) * 8));
    margin-bottom: var(--margin-bottom, calc(var(--sw-multiple-px) * 8));

    section & {
        --margin-top: calc(var(--sw-multiple-px) * 6);
        --margin-bottom: calc(var(--sw-multiple-px) * 6);

        &.sw-hr_no-top {
            --margin-top: 0;
        }
    }
}
