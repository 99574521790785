.sw-referral-page {
    .sw-section {
        --margin-bottom: 0;

        &__content {
            --padding-start: 40px;
            --padding-end: 40px;

            h2 {
                font-weight: 500;
            }

            @media (max-width: 1280px) {
                --padding-start: 0;
                --padding-end: 0;
            }

            @media (max-width: 1024px) {
            }

            @media (max-width: 576px) {
                h2 {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }

        // 1 section
        &_banner {
            position: relative;
            background: var(--sw-background-medium) url('/assets/images/referral-bg.png') no-repeat 50% 50%;
            background-size: cover;

            .sw-section__content {
                --padding-top: 0;
                --padding-bottom: 90px;
                --padding-start: 0;
                --padding-end: 0;
                position: relative;
                z-index: 2;
                text-align: center;
            }

            .sw-breadcrumbs {
                margin-bottom: 0px;
            }

            .sw-updates {
                --margin-top-list: 46px;
                --margin-bottom-list: 40px;
                justify-content: center;

                > .sw-item {
                    --font-size: 13px;
                    --color: var(--sw-text-color);
                }
            }

            h1 {
                padding-bottom: 16px;
            }

            p:not(app-top-notifications p) {
                margin: 0 0 40px;
                font-size: 18px;
                line-height: 24px;
            }

            .sw-animation {
                position: absolute;
                z-index: 1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
            }

            @media (max-width: 1024px) {
                h1 {
                    font-size: 45px;
                    line-height: 52px;
                }
            }

            @media (max-width: 576px) {
                .sw-section__content {
                    --padding-bottom: 72px;
                    text-align: left;
                }
                h1 {
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 36px;
                }
                .sw-updates {
                    --margin-top-list: 24px;
                    --margin-bottom-list: 32px;
                    justify-content: flex-start;
                }
                p {
                    margin: 0 0 24px;
                }

                sw-button {
                    display: block;
                    margin-right: 0;

                    &:first-child {
                        margin-bottom: 12px;
                    }
                }
            }
        }

        // 2 section
        &_how {
            .sw-section__content {
                --padding-top: 72px;
                --padding-bottom: 72px;
            }

            h2 {
                margin-bottom: 52px;
                text-align: center;
            }

            sw-grid {
                sw-col {
                    --sw-grid-column-padding-start: 12px;
                    --sw-grid-column-padding-end: 12px;
                    --sw-grid-column-padding-top: 10px;
                    --sw-grid-column-padding-bottom: 10px;

                    &:first-child {
                        --sw-grid-column-padding-start: 0;
                    }
                    &:last-child {
                        --sw-grid-column-padding-end: 0;
                    }

                    .sw-block {
                        --padding-top: 24px;
                        --padding-bottom: 4px;
                        --margin-bottom: 0;
                        --border-radius: 12px;
                        // height: 100%;

                        h5 {
                            margin-bottom: 12px;
                            font-weight: 500;
                        }

                        p {
                            margin: 0 0 8px;
                            font-size: 16px;
                            line-height: 24px;

                            &.sw-block__decor {
                                margin: 0;
                                font-weight: 700;
                                font-size: 64px;
                                line-height: 64px;
                                letter-spacing: 0.5px;
                                color: rgba(var(--sw-text-color-rgb), 0.15);
                            }
                        }

                        &:not(.sw-block_secondary) {
                            --background: rgba(var(--sw-color-primary-rgb), 0.15);

                            .sw-block__decor {
                                color: rgba(var(--sw-color-primary-rgb), 0.35);
                            }
                        }
                    }
                }

                sw-row {
                    align-items: stretch;

                    &:first-child {
                        sw-col {
                            --sw-grid-column-padding-top: 0;
                        }
                    }
                    &:last-child {
                        sw-col {
                            --sw-grid-column-padding-bottom: 0;
                        }
                    }
                }
            }

            .sw-block_center {
                --margin-top: 52px;
                --padding-top: 40px;
                --padding-bottom: 48px;
                --padding-start: 40px;
                --padding-end: 40px;
                --border-radius: 12px;
                --background: var(--sw-color-action-shade) url('/assets/images/bg-referral.jpeg')
                    no-repeat 50% 50%;
                --color: var(--sw-color-white);
                background-size: cover;
                // text-align: center;

                .sw-sign,
                sw-button {
                    margin: auto;
                }

                h2 {
                    margin: 32px auto 16px;
                }

                p {
                    max-width: 800px;
                    margin: 0 auto 40px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                }
            }

            @media (max-width: 576px) {
                .sw-section__content {
                    --padding-top: 64px;
                    --padding-bottom: 64px;
                }

                h2 {
                    margin-bottom: 40px;
                    text-align: left;
                }

                sw-grid {
                    sw-col {
                        --sw-grid-column-padding-start: 0;
                        --sw-grid-column-padding-end: 0;
                        --sw-grid-column-padding-top: 6px;
                        --sw-grid-column-padding-bottom: 6px;
                    }
                    sw-row {
                        &:first-child {
                            sw-col:last-child {
                                --sw-grid-column-padding-top: 6px;
                            }
                        }
                        &:last-child {
                            sw-col:first-child {
                                --sw-grid-column-padding-bottom: 6px;
                            }
                        }
                    }
                }

                .sw-block_center {
                    --margin-top: 48px;
                    --padding-top: 28px;
                    --padding-bottom: 24px;
                    --padding-start: 24px;
                    --padding-end: 24px;

                    .sw-sign {
                        display: none;
                    }

                    h2 {
                        margin: 0 0 20px;
                        text-align: left;
                    }

                    p {
                        text-align: left;
                    }

                    sw-button {
                        display: block;
                    }
                }
            }
        }

        // 3 section
        &_faq {
            .sw-section__content {
                --padding-top: 72px;
                --padding-bottom: 72px;
            }

            h2 {
                margin-bottom: 32px;
                text-align: center;
            }

            sw-accordion {
                --margin-bottom: 12px;
                position: relative;

                &::part(header) {
                    --border-radius: 12px;
                    --border-width: 0;
                    --padding-start: calc(var(--sw-multiple-px) * 6);
                    --padding-end: calc(var(--sw-multiple-px) * 6);
                    --padding-top: calc(var(--sw-multiple-px) * 6);
                    --padding-bottom: calc(var(--sw-multiple-px) * 6);
                    --background: var(--sw-background);
                    position: relative;
                    font-size: 18px;
                    line-height: 24px;
                }

                &::part(body) {
                    --border-radius: 0 0 12px 12px;
                    --padding-start: calc(var(--sw-multiple-px) * 6);
                    --padding-end: calc(var(--sw-multiple-px) * 6);
                    --padding-bottom: calc(var(--sw-multiple-px) * 6);
                    --padding-top: 6px;
                    --background: var(--sw-background);
                    --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
                    position: absolute;
                    top: calc(100% - 12px);
                    left: 0;
                    right: 0;
                    max-height: 285px;
                    overflow: auto;
                    font-size: 14px;
                    line-height: 20px;
                }

                &.sw-accordion--opened {
                    z-index: 150;
                    border-radius: 12px;
                    background: var(--sw-background);

                    &::part(header) {
                        --color: var(--sw-color-primary);
                        z-index: 1;
                    }
                }
            }

            .sw-row-elements {
                margin-top: 48px;
            }

            @media (max-width: 576px) {
                .sw-section__content {
                    --padding-top: 64px;
                    --padding-bottom: 64px;
                }

                h2 {
                    text-align: left;
                }

                .swh-text-center {
                    text-align: left !important;
                }

                .sw-row-elements {
                    sw-button {
                        display: block;
                        margin-right: 0;

                        &:first-child {
                            margin-bottom: 12px;
                        }
                    }
                }
            }
        }
    }
}
