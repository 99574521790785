.sw-dispatcher-panel-filter {
    overflow: hidden;
    height: 100%;

    header {
        sw-toolbar {
            --padding-start: 16px;
            --padding-end: 16px;
            --padding-top: 12px;
            --padding-bottom: 0;
        }
    }

    main {
        padding: 16px;
        height: calc(100% - 46px);
        overflow-y: auto;
        overflow-x: hidden;

        .sw-control {
            --margin-bottom: 24px;
        }
    }
}

.sw-dispatcher-filter-map-panel {
    display: flex;
    margin-right: 24px;
    margin-bottom: 12px;
    pointer-events: all;

    > * {
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }

    .sw-control__field_action {
        cursor: pointer;

        sw-icon {
            margin-right: 8px;
            color: var(--sw-text-color-secondary);
        }

        sw-badge {
            --height-large: 20px;
            --font-size-large: 13px;
            --margin-end: 0;
            align-items: center;

            // @media only screen and ( -webkit-max-device-pixel-ratio: 1 ){
            //     line-height: var(--height-large);
            // }
        }

        + .sw-control__field-group__end {
            cursor: pointer;
        }
    }

    app-select {
        --control-width: 206px;

        .sw-select__trigger_noempty {
            color: var(--sw-color-primary);
        }
    }
}
