.sw-radio {
    /**
     * @prop --background: фон radio
     * @prop --background-checked: фон при установленном radio
     * @prop --background-disabled: фон при отключенном radio
     * @prop --disabled-opacity: прозрачность чекбокса при отключенном radio
     *
     * @prop --color: цвет метки radio
     * @prop --color-checked: цвет метки при установленном radio
     * @prop --color-disabled: цвет метки при отключенном radio
     *
     * @prop --transition: переход
     *
     * @prop --border-radius: радиус скругления
     * @prop --border-width: толщина границы
     * @prop --border-style: стиль границы
     * @prop --border-color: цвет границы
     * @prop --border-color-checked: цвет границы при установленном radio
     * @prop --border-width-checked: толщина границы при установленном radio
     * @prop --border-color-disabled: цвет границы при отключенном radio
     *
     * @prop --size: Размер значка radio.
     */

    --background-checked: var(--sw-color-primary);
    --border-color-checked: var(--sw-color-primary);
    --color-checked: var(--color);
    --color-disabled: var(--color);
    --transition: none;
    position: relative;
    user-select: none;
    z-index: 2;

    &.sw-color {
        --background-checked: var(--sw-color-base);
        --border-color-checked: var(--sw-color-base);
    }

    label {
        position: relative;
    }

    &__label {
        position: relative;
        display: flex;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        border: 0;
        background: transparent;
        cursor: pointer;
        appearance: none;
        outline: none;
        align-items: flex-start;

        &::-moz-focus-inner {
            border: 0;
        }

        &::before {
            content: '';
            flex-shrink: 0;
            border-radius: var(--border-radius);
            position: relative;
            width: var(--size);
            height: var(--size);
            margin-left: calc(var(--size) / 2);
            margin-right: calc(var(--size) / 2);
            transition: var(--transition);
            border-width: var(--border-width);
            border-style: var(--border-style);
            border-color: var(--border-color);
            background: var(--background);
            box-sizing: border-box;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        clip: rect(0 0 0 0);
        opacity: 0;
        overflow: hidden;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:checked + .sw-radio__label {
            color: var(--color-checked);

            &::before {
                border-color: var(--border-color-checked);
                border-width: var(--border-width-checked);
                background: var(--background-checked);
            }
        }

        &:disabled + .sw-radio__label {
            pointer-events: none;
            color: var(--color-disabled);

            &::before {
                opacity: var(--disabled-opacity);
            }
        }
    }

    // types
    &_right {
        direction: rtl;
        text-align: left;

        .sw-radio__label {
            justify-content: flex-end;
        }
    }

    &_tab {
        width: 100%;

        .sw-radio__label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        input {
            &:checked + .sw-radio__label {
                background: var(--border-color-checked);
                color: var(--background-checked);
            }
        }

        &:first-child .sw-radio__label {
            border-radius: var(--sw-border-radius) 0 0 var(--sw-border-radius);
        }
        &:last-child .sw-radio__label {
            border-radius: 0 var(--sw-border-radius) var(--sw-border-radius) 0;
        }
    }

    &_block {
        .sw-radio__label {
            display: block;

            &::before {
                display: block;
                float: left;
            }
        }
    }
}

.sw-radio {
    --border-radius: 50%;
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--sw-border-color-dark);
    --border-color-checked: var(--sw-color-action);
    --border-width-checked: 4px;
    --background: transparent;
    --background-checked: var(--sw-background);
    --color: var(--sw-text-color);
    --color-disabled: var(--sw-text-color-secondary);
    --size: 16px;
    --disabled-opacity: 0.5;

    .sw-radio__label {
        margin-right: var(--sw-padding);

        &.sw-label-hidden {
            margin-right: 0;
        }
    }

    &:not(.swh-inline-block, .sw-radio_tab, .sw-list_control-dd .sw-radio)
        + &:not(.swh-inline-block, .sw-radio_tab, .sw-list_control-dd .sw-radio),
    .sw-control__label + &:not(.swh-inline-block, .sw-radio_tab) {
        margin-top: calc(var(--sw-multiple-px) * 5);
    }

    .sw-control &,
    .sw-list_control-dd & {
        --background-selected: var(--background);
        --color-selected: var(--color-checked);

        border: 0;

        .sw-radio__label {
            --border-radius: 50%;
            &::before {
                margin-left: 0;
                margin-right: calc(var(--size) / 2);
            }
        }

        &.sw-radio_right {
            .sw-radio__label {
                &::before {
                    margin-right: 0;
                    margin-left: calc(var(--size) / 2);
                }
            }
        }

        &.sw-item {
            border-radius: 0;
        }

        &.sw-item--selected {
            .sw-radio__label {
                color: var(--color-checked);

                &::before {
                    border-color: var(--border-color-checked);
                    border-width: var(--border-width-checked);
                    background: var(--background-checked);
                }
            }
        }
    }

    // types
    &_tab {
        .sw-radio__label {
            height: 148px;
            background: var(--sw-background-medium);
            margin: 0;
            font-size: 14px;
            font-weight: 500;

            sw-icon {
                margin-bottom: 18px;
            }

            &::before {
                position: absolute;
                top: 12px;
                right: 8px;
                border: none;
                width: 20px;
                height: 20px;
            }
        }

        input {
            &:checked + .sw-radio__label {
                &::before {
                    background: url('/assets/images/check-circle.svg');
                }
            }
        }
    }

    &_block {
        .sw-radio__label {
            margin-top: calc(var(--sw-multiple-px) * 2);
            margin-right: 0;
            border: 1px solid var(--sw-border-color);
            border-radius: var(--sw-border-radius);
            padding: calc(var(--sw-multiple-px) * 4) var(--sw-padding);
        }

        input {
            &:checked + .sw-radio__label {
                border-color: var(--border-color-checked);
                background: var(--sw-background-info);
            }
        }
    }
}
