.sw-list-page {
    &__head {
        background: var(--sw-background-medium);

        app-referral-partners & {
            background: var(--sw-background);
        }

        .sw-breadcrumbs {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__title {
        display: flex;
        align-items: center;

        h1 {
            padding: 0;
        }
    }
    &__filters {
        background: var(--sw-background-medium);
    }
    &__body {
        background: transparent;
    }

    @media (max-width: 992px) {
        .sw-list-page__head {
            background: transparent;
        }
        .sw-list-page__filters {
            background: transparent;
        }
        .sw-list-page__body {
            background: var(--sw-background-medium);

            .sw-block_shadow {
                --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1),
                    inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
                --border-radius: 8px;

                --sw-corner-right: 14px;
                --sw-corner-top: 14px;

                hr {
                    --margin-top: 20px;
                    --margin-bottom: 20px;
                    --border-style: dashed;
                }

                .sw-accordion_info:last-child {
                    --margin-start: -24px;
                    --margin-end: -24px;
                    --margin-bottom: -24px;
                    --sw-border-radius: 8px;

                    &::part(header) {
                        --padding-top: 20px;
                        --padding-bottom: 20px;
                    }
                    &::part(body) {
                        --padding-bottom: 20px;
                    }

                    &:not(.sw-accordion--opened) {
                        &::part(header) {
                            --border-radius: 0 0 var(--sw-border-radius);
                        }
                    }

                    &.sw-accordion--opened {
                        &::part(header) {
                            --border-radius: 0;
                        }
                    }
                }
            }
        }

        .sw-announcement & {
            .sw-container {
                padding: 0;
            }
            &__body {
                background: transparent;
            }
        }
    }

    @media (max-width: 576px) {
        .sw-list-page__title {
            display: block;

            .sw-tab-status {
                margin: 12px 0 16px;
            }
        }

        .sw-list-page__body {
            .sw-block_shadow {
                --padding-start: 20px;
                --padding-end: 20px;

                .sw-list_bullet > .sw-item {
                    --padding-start: 8px;
                }

                .sw-accordion_info {
                    table,
                    tbody,
                    tr,
                    td {
                        display: block;
                    }

                    td.swh-text-right {
                        --padding-start: 0;
                        text-align: left !important;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 500;
                    }

                    tr:not(:last-child) {
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }
}
