table {
    @include props-margin;
    @include margin;
    width: 100%;

    &.sw-table-fixed {
        table-layout: fixed;
    }
}

tr {
    vertical-align: top;
}

th,
td {
    @include props-block-model;
    @include props-colors;
    @include props-font;

    @include colors;
    @include font;
    @include block-model;
}

// implemented
th {
    --padding-start: 8px;
    --padding-top: 8px;
    --padding-end: 8px;
    --padding-bottom: 8px;
    --font-size: 13px;
    --font-weight: 500;
    --line-height: 20px;

    text-align: left;

    &:last-child:not(.sw-th_last-left) {
        --padding-end: 24px;
        text-align: right;
    }

    .sw-table_mini & {
        --padding-top: 2px;
        --padding-bottom: 2px;
        --font-size: 10px;
        --line-height: 12px;
        text-transform: uppercase;
        min-width: 66px;

        &:last-child {
            --padding-end: 8px;
            text-align: left;
        }
    }

    thead & {
        --background: var(--sw-background-medium);
        --color: var(--sw-text-color-secondary);
    }
    thead tr.sw-th_no-padding &,
    thead &.sw-th_no-padding {
        --padding-bottom: 0;
    }
    thead tr.sw-th_no-padding + tr & {
        --padding-top: 0;
    }

    tbody & {
        --background: var(--sw-background-info);
        --color: var(--sw-text-color);
        --border-width: 0 0 1px 0;
        --border-style: solid;
        --border-color: var(--sw-background-dark);
    }
}

td {
    --padding-start: 8px;
    --padding-top: 16px;
    --padding-end: 8px;
    --padding-bottom: 16px;
    --border-style: solid;
    --border-color: var(--sw-border-color);
    --border-width: 0 0 1px 0;
    --line-height: 20px;

    tbody & {
        .sw-dropdown {
            &__trigger {
                // --height: 28px;
                --background: var(--sw-color-white);
                // --padding-start: 12px;
                // --padding-end: 12px;
                --color: var(--sw-placeholder-color);

                // width: 28px;
            }
            &__body {
                --top: 36px;
            }

            .sw-list_control-dd {
                --margin-start-list: 0;
                --margin-end-list: 0;
                --margin-top-list: 0;
                --margin-bottom-list: 0;
                width: var(--width);
            }
        }

        sw-icon {
            --sw-icon-small-size: 16px;
            color: var(--sw-placeholder-color);
        }
    }

    &:last-child {
        --padding-end: var(--sw-padding);
    }

    &.sw-table__cell-btns {
        --padding-top: 8px;
    }

    .sw-tr-collapsed {
        // position: relative;
        // padding-right: calc(var(--sw-multiple-px) * 4);
        cursor: pointer;

        sw-icon {
            // position: absolute;
            // right: 0;
            // top: 50%;
            // margin-top: -4px;
            font-size: 8px;
            color: var(--sw-text-color);
        }
    }

    .sw-td-no-border &,
    &.sw-td-no-border {
        --border-width: 0;
    }

    .sw-td-narrow & {
        --padding-top: 4px;
        --padding-bottom: 4px;
        position: relative;
        top: -10px;
    }
    .sw-td-narrow.sw-td-no-border & {
        --padding-top: 0;
        --padding-bottom: 4px;
    }

    .sw-tr--active & {
        --background: var(--sw-background-info);
    }
}

table {
    --margin-bottom: calc(var(--sw-multiple-px) * 6);

    .sw-form & .sw-control,
    .sw-control {
        --margin-bottom: 0;
    }

    .sw-checkbox__label,
    .sw-radio__label {
        &::before {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .sw-dropdown_action {
        sw-button.sw-dropdown__trigger {
            --color-hover: var(--sw-color-action);

            sw-icon {
                color: currentColor;
            }
        }

        &:hover {
            sw-button.sw-dropdown__trigger {
                --color: var(--color-hover);
            }
        }
    }

    .sw-coordinates {
        margin-bottom: 0;
    }

    .sw-tooltip_dropdown {
        --box-shadow: none;
        padding: 0;

        .sw-list_control-dd {
            --margin-top-list: 0;
            position: static;
        }
    }
}

.sw-table_schedule {
    --margin-top: 13px;

    td {
        --padding-start: 12px;
        --padding-end: 12px;

        &:first-child {
            --padding-start: 0;
            min-width: 240px;
        }

        min-width: 218px;

        &.sw-table__cell_action {
            --padding-end: 0;
            min-width: 0;
        }

        .sw-control {
            max-width: 226px;
            white-space: nowrap;
        }
    }
}

.sw-table_hoverable {
    tbody tr:hover td {
        background: var(--sw-background-info);
    }
}

.sw-table_clear {
    --margin-bottom: 0;

    td {
        // --padding-start: 8px;
        --padding-top: 8px;
        // --padding-end: 8px;
        --padding-bottom: 8px;
        --border-width: 0;

        &:first-child {
            --padding-start: 0;
        }
        &:last-child {
            --padding-end: 0;
        }

        &.sw-td_top-zero {
            --padding-top: 0;
        }
        &.sw-td_bottom-zero {
            --padding-bottom: 0;
        }
    }
}
