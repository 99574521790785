.sw-card-balance {
    position: relative;
    border-radius: 5px;
    background: var(--sw-color-base);

    img {
        display: block;
    }

    sw-icon {
        --sw-icon-medium-size: 20px;
        position: absolute;
        right: 10px;
        bottom: 8px;
        color: var(--sw-color-white);
    }

    &__title {
        position: absolute;
        top: 5px;
        left: 25px;
        font-size: 8px;
        line-height: 16px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: var(--sw-background-dark);
    }
}
