sw-grid.sw-grid_table {
    --sw-grid-columns: 24;
    margin-bottom: calc(var(--sw-multiple-px) * 6);

    > sw-row,
    > * > sw-row {
        border-bottom: 1px solid var(--sw-border-color);

        > sw-col {
            --sw-grid-column-padding-start: 8px;
            --sw-grid-column-padding-end: 8px;
            --sw-grid-column-padding-top: 12px;
            --sw-grid-column-padding-bottom: 12px;

            line-height: 20px;

            &.sw-col_th {
                --sw-grid-column-padding-top: 8px;
                --sw-grid-column-padding-bottom: 8px;

                background: var(--sw-background-medium);

                font-size: 13px;
                font-weight: 500;
                text-align: left;
                color: var(--sw-text-color-secondary);
            }

            &.sw-table__cell-btns {
                --sw-grid-column-padding-top: 8px;
            }
        }

        &.sw-row_thead {
            border-bottom: 0;

            &.sw-th_no-padding > sw-col,
            > sw-col.sw-th_no-padding {
                --sw-grid-column-padding-bottom: 0;
            }
            &.sw-th_no-padding + & > sw-col {
                --sw-grid-column-padding-top: 0;
            }
        }

        &.sw-td-no-border {
            border-width: 0;
        }

        &.sw-row_tbody:hover {
            background: var(--sw-background-info);
        }

        &.sw-td-narrow {
            position: relative;
            top: -16px;

            > sw-col {
                --sw-grid-column-padding-top: 4px;
                --sw-grid-column-padding-bottom: 4px;
            }

            &.sw-td-no-border > sw-col {
                --sw-grid-column-padding-top: 0;
                --sw-grid-column-padding-bottom: 4px;
            }
        }
    }

    & & {
        margin-bottom: 0;

        > sw-row,
        > * > sw-row {
            &:first-child {
                > sw-col {
                    --sw-grid-column-padding-top: 0;
                }
            }

            &:last-child {
                border-bottom: 0;

                > sw-col {
                    --sw-grid-column-padding-bottom: 0;
                }
            }

            &.sw-tr_no-border {
                border-bottom: 0;
            }

            > sw-col {
                --sw-grid-column-padding-start: 0;
                --sw-grid-column-padding-end: 0;
            }
        }
    }
}

sw-grid.sw-grid_table {
    .sw-dropdown_action {
        sw-button.sw-dropdown__trigger {
            --color-hover: var(--sw-color-action);

            sw-icon {
                color: currentColor;
            }
        }

        &:hover {
            sw-button.sw-dropdown__trigger {
                --color: var(--color-hover);
            }
        }
    }

    .sw-dropdown {
        app-vehicle-number + sw-button.sw-dropdown__trigger {
            --background: transparent;
            --background-hover: transparent;
            position: relative;
            top: -4px;
        }
    }

    .sw-coordinates {
        margin-bottom: 0;
    }

    .sw-btn-expanded {
        position: relative;
        top: -9px;
    }

    > sw-row,
    > * > sw-row {
        > sw-col {
            .sw-dropdown {
                &__trigger {
                    --background: var(--sw-color-white);
                    --color: var(--sw-placeholder-color);
                }
                &__body {
                    --top: 36px;
                }

                &_vehicle {
                    .sw-dropdown__trigger {
                        --color: var(--sw-color-base);
                    }
                }
            }

            sw-button sw-icon {
                --sw-icon-small-size: 16px;
                color: var(--sw-placeholder-color);
            }

            .sw-tr-collapsed {
                cursor: pointer;

                sw-icon {
                    font-size: 8px;
                    color: var(--sw-text-color);
                }
            }
        }
    }
}
