.sw-btn-group {
    display: flex;
    border-radius: var(--border-radius);

    &__item {
        &:first-child {
            > sw-button::part(native) {
                border-radius: var(--border-radius) 0 0 var(--border-radius);
                border-left: var(--border-width) var(--border-style) var(--border-color);
            }
        }
        &:last-child {
            > sw-button {
                &::part(native) {
                    border-radius: 0 var(--border-radius) var(--border-radius) 0;
                }
                &.sw-btn_clear::part(native)::after {
                    opacity: 0;
                }
            }
        }
        &:only-child {
            > sw-button::part(native) {
                border-radius: var(--border-radius);
            }
        }

        > sw-button {
            --outline-width: none;
            --outline-color: none;
            --outline-style: none;

            &::part(native) {
                border-radius: 0;
                border: var(--border-width) var(--border-style) var(--border-color);
                border-left: none;
            }

            &.sw-btn_large {
                sw-icon {
                    font-size: 24px;
                }
            }

            &.sw-btn_clear {
                &::part(native)::after {
                    top: var(--padding-top);
                    bottom: var(--padding-bottom);
                    left: auto;
                    opacity: 1;
                }

                sw-icon[slot='icon-only'] {
                    margin-left: 0;
                    margin-right: 0;
                }
                @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
                    sw-icon[slot='icon-only'] {
                        -webkit-margin-start: 0;
                        margin-inline-start: 0;
                        -webkit-margin-end: 0;
                        margin-inline-end: 0;
                    }
                }
            }
        }
    }

    &--disabled {
        .sw-btn-group__item > sw-button {
            cursor: default;
            pointer-events: none;

            &::part(native) {
                background: var(--background-disabled);
                opacity: var(--disabled-opacity);
                color: var(--color-disabled);
            }
        }
    }

    // types
    &_h {
        flex-direction: column;

        .sw-btn-group__item {
            > sw-button {
                &::part(native) {
                    border: var(--border-width) var(--border-style) var(--border-color);
                    border-top: none;
                }
            }

            &:first-child {
                > sw-button::part(native) {
                    border-radius: var(--border-radius) var(--border-radius) 0 0;
                    border-top: var(--border-width) var(--border-style) var(--border-color);
                }
            }
            &:last-child {
                > sw-button::part(native) {
                    border-radius: 0 0 var(--border-radius) var(--border-radius);
                }
            }
        }
    }
}

// implemented
.sw-btn-group {
    --border-radius: var(--sw-border-radius);

    .sw-btn-group__item > sw-button.sw-btn_solid {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--sw-border-color);

        --background: var(--sw-background-medium);
        --color: var(--sw-text-color);

        --background-activated: var(--sw-color-action);
        --color-activated: var(--sw-text-color-contrast);

        --background-hover: var(--sw-color-action-shade);
        --color-hover: var(--sw-text-color-contrast);

        --background-focused: var(--background-hover);
        --color-focused: var(--color-hover);

        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        sw-icon {
            color: var(--sw-text-color-secondary);
        }

        &.sw-btn--active::part(native) {
            border-color: var(--background-activated);
        }

        &:hover::part(native),
        &:focus-visible::part(native) {
            border-color: var(--background-hover);
        }

        // объединение правил 100 - 107, приводит к неработоспособности каждой из групп, ховер - точно перестает работать.
        &.sw-btn--active,
        &:hover {
            sw-icon {
                color: var(--sw-text-color-contrast);
            }
        }

        &::part(native):active,
        &:focus-visible::part(native) {
            sw-icon {
                color: var(--sw-text-color-contrast);
            }
        }

        .sw-dispatcher-page .sw-header & {
            --border-color: rgba(var(--sw-background-info-rgb), 0.1);

            --background: rgba(var(--sw-background-medium-rgb), 0.1);
            --color: var(--sw-color-white);

            --background-activated: var(--sw-color-primary);
            --color-activated: var(--color);

            --background-hover: var(--sw-color-primary-shade);
            --color-hover: var(--color);

            --color-focused: var(--color);
        }
    }

    &--disabled .sw-btn-group__item > sw-button.sw-btn_solid,
    & .sw-btn-group__item > sw-button.sw-btn_solid.sw-btn--disabled {
        &::part(native) {
            --background-disabled: var(--sw-background-dark);
            --color-disabled: var(--sw-text-color-secondary);
        }

        &.sw-btn--active::part(native) {
            --background-disabled: var(--sw-text-color-secondary);
            --color-disabled: var(--sw-text-color-contrast);
            background: var(--background-disabled);
            border-color: var(--background-disabled);
            color: var(--color-disabled);
        }
    }

    .sw-btn-group__item > sw-button.sw-btn_clear {
        --background: var(--sw-color-info-tint);
        --color: var(--sw-placeholder-color);

        --color-activated: var(--sw-color-action);

        --background-hover: var(--background);
        --color-hover: var(--sw-color-action);

        --background-focused: var(--background);
        --color-focused: var(--color);

        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        sw-icon {
            color: currentColor;
        }

        &::part(native)::after {
            border-right: 1px solid var(--sw-border-color);
        }
    }

    + .sw-control__note {
        margin-top: calc(var(--sw-multiple-px) * 3);
    }

    &_h {
        .sw-btn-group__item > sw-button.sw-btn_outline {
            &.sw-btn--active {
                --background-activated: var(--background);
                --color-activated: var(--sw-color-primary);
                --color-hover: var(--color-activated);
                background: var(--background-activated);
                border-color: var(--background-activated);
                color: var(--color-activated);
            }
        }
    }
}
