/** Application global CSS Variables **/
:root {
    /**
    **
    ** COLORS
    **
    **/

    /*** Theme colors ***/

    /** primary **/
    --sw-color-primary: #00A2AD;
    --sw-color-primary-rgb: 0,162,173;
    --sw-color-primary-contrast: #ffffff;
    --sw-color-primary-contrast-rgb: 255,255,255;
    --sw-color-primary-shade: #008f98;
    --sw-color-primary-shade-rgb: 0, 143, 152;
    --sw-color-primary-tint: #1aabb5;

    /** secondary **/
    --sw-color-secondary: #43B1F2;
    --sw-color-secondary-rgb: 67,177,242;
    --sw-color-secondary-contrast: #ffffff;
    --sw-color-secondary-contrast-rgb: 255,255,255;
    --sw-color-secondary-shade: #3b9cd5;
    --sw-color-secondary-shade-rgb: 59, 156, 213;
    --sw-color-secondary-tint: #56b9f3;

    /** tertiary **/
    --sw-color-tertiary: #817AF9;
    --sw-color-tertiary-rgb: 128,122,249;
    --sw-color-tertiary-contrast: #ffffff;
    --sw-color-tertiary-contrast-rgb: 255,255,255;
    --sw-color-tertiary-shade: #726bdb;
    --sw-color-tertiary-shade-rgb: 114, 107, 219;
    --sw-color-tertiary-tint: #8e87fa;

    /** action **/
    --sw-color-action: #164982;
    --sw-color-action-rgb: 22,73,130;
    --sw-color-action-contrast: #ffffff;
    --sw-color-action-contrast-rgb: 255,255,255;
    --sw-color-action-shade: #123A68;
    --sw-color-action-shade-rgb: 18, 58, 104;
    --sw-color-action-tint: #456D9B;

    /** info **/
    --sw-color-info: #C4C4C4;
    --sw-color-info-rgb: 196,196,196;
    --sw-color-info-contrast: #ffffff;
    --sw-color-info-contrast-rgb: 255,255,255;
    --sw-color-info-shade: #acacac;
    --sw-color-info-shade-rgb: 172, 172, 172;
    --sw-color-info-tint: #F3F3F3;

    /** success **/
    --sw-color-success: #78C649;
    --sw-color-success-rgb: 120,198,73;
    --sw-color-success-contrast: #ffffff;
    --sw-color-success-contrast-rgb: 255,255,255;
    --sw-color-success-shade: #6aae40;
    --sw-color-success-shade-rgb: 106, 174, 64;
    --sw-color-success-tint: #86cc5b;
    // --sw-color-success-tint: #E4F4DB;

    /** warning **/
    --sw-color-warning: #F6BF36;
    --sw-color-warning-rgb: 246,191,54;
    --sw-color-warning-contrast: #ffffff;
    --sw-color-warning-contrast-rgb: 255,255,255;
    --sw-color-warning-shade: #d8a830;
    --sw-color-warning-shade-rgb: 216, 168, 48;
    --sw-color-warning-tint: #f7c54a;
    // --sw-color-warning-tint: #FDF2D7;

    /** danger **/
    --sw-color-danger: #ED1A34;
    --sw-color-danger-rgb: 237,26,52;
    --sw-color-danger-contrast: #ffffff;
    --sw-color-danger-contrast-rgb: 255,255,255;
    --sw-color-danger-shade: #d1172e;
    --sw-color-danger-shade-rgb: 209, 23, 46;
    --sw-color-danger-tint: #ef3148;
    // --sw-color-danger-tint: #FBD1D6;

    /*** Stepped Colors ***/
    --sw-color-white: #ffffff;
    --sw-color-white-rgb: 255, 255, 255;

    --sw-color-step-50: #f2f2f2;
    --sw-color-step-100: #e6e6e6;
    --sw-color-step-150: #d9d9d9;
    --sw-color-step-200: #cccccc;
    --sw-color-step-250: #bfbfbf;
    --sw-color-step-300: #b3b3b3;
    --sw-color-step-350: #a6a6a6;
    --sw-color-step-400: #999999;
    --sw-color-step-450: #8c8c8c;
    --sw-color-step-500: #808080;
    --sw-color-step-550: #737373;
    --sw-color-step-600: #666666;
    --sw-color-step-650: #595959;
    --sw-color-step-700: #4d4d4d;
    --sw-color-step-750: #404040;
    --sw-color-step-800: #333333;
    --sw-color-step-850: #262626;
    --sw-color-step-900: #191919;
    --sw-color-step-950: #0d0d0d;

    --sw-color-black: #000000;
    --sw-color-black-rgb: 0, 0, 0;

    /*** Base colors ***/

    /** text colors **/
    --sw-text-color: #111214;
    --sw-text-color-rgb: 17, 18, 20;
    --sw-text-color-secondary: #9096A2;
    --sw-text-color-secondary-rgb: 144, 150, 162;
    --sw-text-color-contrast: var(--sw-color-white);
    --sw-text-color-contrast-rgb: var(--sw-color-white-rgb);

    /* link color */
    --sw-link-color: var(--sw-color-action);
    --sw-link-color-rgb: var(--sw-color-action-rgb);

    /** placeholder color  **/
    --sw-placeholder-color: #BCC1CC;
    --sw-placeholder-color-rgb: 188, 193, 204;

    /** background light **/
    --sw-background: var(--sw-color-white);
    --sw-background-rgb: var(--sw-color-white-rgb);

    /** background medium **/
    --sw-background-medium: #F7F8F9;
    --sw-background-medium-rgb: 247, 248, 249;

    /** background dark **/
    --sw-background-dark: #F1F1F3;
    --sw-background-dark-rgb: 241, 241, 243;

    /** background info **/
    --sw-background-info: #F1F6FB; // rgba(var(--sw-color-warning-rgb), 0.1)
    --sw-background-info-rgb: 241, 246, 251;

    /** background info secondary **/
    --sw-background-info-secondary: #FEF9EB;
    --sw-background-info-secondary-rgb: 254, 249, 235;

    /** background header **/
    --sw-header-background: #061943;
    --sw-header-background-rgb: 6, 25, 67;

    /** background footer **/
    --sw-footer-background: #020C22;
    --sw-footer-background-rgb: 2, 12, 34;

    /** border light  **/
    --sw-border-color: #E8E9EB;
    --sw-border-color-rgb: 232, 233, 235;

    /** border dark  **/
    --sw-border-color-dark: #C4C7CC;
    --sw-border-color-dark-rgb: 196, 199, 204;

    /** shadow **/
    --sw-shadow-color-rgb: 239, 240, 242;

    /**
    **
    ** APPLICATION VARIABLES
    **
    **/

    /** Font family of the app **/
    --sw-font-family: 'Gordita', sans-serif;

    /** Базовое число пикселей, используемое для кратности определяемых размеров **/
    --sw-multiple-px: 4px;

    /** Коэффиницет кратности по умолчанию **/
    --sw-coef: 3;

    /** Adjust the safe area inset top of the app **/
    // --sw-safe-area-top: ;

    /** Adjust the safe area inset right of the app **/
    // --sw-safe-area-right: ;

    /** Adjust the safe area inset bottom of the app **/
    // --sw-safe-area-bottom: ;

    /** Adjust the safe area inset left of the app **/
    // --sw-safe-area-left: ;

    /** Adjust the margin **/
    --sw-margin: calc(var(--sw-multiple-px) * var(--sw-coef)); // 12px

    // --sw-margin-1x: var(--sw-multiple-px); // 4px
    // --sw-margin-2x: calc(var(--sw-multiple-px) * 2); // 8px
    // --sw-margin-3x: calc(var(--sw-multiple-px) * 3); // 12px
    // --sw-margin-4x: calc(var(--sw-multiple-px) * 4); // 16px
    // --sw-margin-5x: calc(var(--sw-multiple-px) * 5); // 20px
    // --sw-margin-6x: calc(var(--sw-multiple-px) * 6); // 24px
    // --sw-margin-8x: calc(var(--sw-multiple-px) * 8); // 32px

    /** Adjust the padding **/
    --sw-padding: calc(var(--sw-multiple-px) * var(--sw-coef));

    // --sw-padding-1x: var(--sw-multiple-px); // 4px
    // --sw-padding-2x: calc(var(--sw-multiple-px) * 2); // 8px
    // --sw-padding-3x: calc(var(--sw-multiple-px) * 3); // 12px
    // --sw-padding-4x: calc(var(--sw-multiple-px) * 4); // 16px
    // --sw-padding-5x: calc(var(--sw-multiple-px) * 5); // 20px
    // --sw-padding-6x: calc(var(--sw-multiple-px) * 6); // 24px
    // --sw-padding-8x: calc(var(--sw-multiple-px) * 8); // 32px

    /** Adjust the box shadow **/
    // --sw-box-shadow: ;

    /** Adjust the border-radius **/
    --sw-border-radius: 4px;

    /**
    **
    ** GRID VARIABLES
    **
    **/
    /** Number of columns in the grid **/
    // --sw-grid-columns: ;
    /** Padding of the grid **/
    --sw-grid-padding: 0;
    /** Padding of the grid for xs breakpoints **/
    // --sw-grid-padding-xs: ;
    /** Padding of the grid for sm breakpoints **/
    // --sw-grid-padding-sm: ;
    /** Padding of the grid for md breakpoints **/
    // --sw-grid-padding-md: ;
    /** Padding of the grid for lg breakpoints **/
    // --sw-grid-padding-lg: ;
    /** Padding of the grid for xl breakpoints **/
    // --sw-grid-padding-xl: ;
    /** Padding of the grid columns for xs breakpoints **/
    // --sw-grid-column-padding-xs: ;
    /** Padding of the grid columns for sm breakpoints **/
    // --sw-grid-column-padding-sm: ;
    /** Padding of the grid columns for md breakpoints **/
    // --sw-grid-column-padding-md: ;
    /** Padding of the grid columns for lg breakpoints **/
    // --sw-grid-column-padding-lg: ;
    /** Padding of the grid columns for xl breakpoints **/
    // --sw-grid-column-padding-xl: ;
}

/**
**
** SW SCSS Variables
**
**/

/** breakpoints **/
$breakpoints: (
    4k:            3840px, // (3840*2160) (3440*1440)
    tv:            2560px, // (2560*1440)
    wide:          1920px,
    large:         1680px, //1680,
    default:       1440px, // 1366
    notebook:      1280px, //1280
    tablet:        1024px,
    handhelds:     800px, // 768, 800
    mobile:        480px,
    small:         320px,
) !default;
