app-lot-propositions {
    .sw-btn_prev,
    .sw-btn_next {
        --border-color: var(--sw-border-color-action);
        position: absolute;
        top: 50%;
        margin-top: -22px;
    }
    .sw-btn_prev {
        left: -22px;
    }
    .sw-btn_next {
        right: -22px;
    }

    .sw-chip_clear {
        --padding-start: 0;
        --padding-end: 0;
    }

    hr {
        --border-color: var(--sw-border-color-dark);
        --border-style: dashed;
        --margin-top: 20px;
        --margin-bottom: 20px;
    }

    @media (max-width: 576px) {
        .sw-block {
            &__header {
                .sw-block_info & {
                    // --border-style: dashed;
                }

                > .swh-flex {
                    > * {
                        width: 100%;
                        flex-shrink: 0;
                    }
                }
            }
            &__footer_normal {
                .sw-block_info & {
                    --margin-top: 20px;
                    --padding-top: 20px;
                }
            }
        }

        &,
        &-mobile {
            .sw-file {
                .swh-ellipsis {
                    max-width: 198px;
                }
            }

            sw-chip {
                --margin-start: 0;
                --margin-end: 0;

                &.sw-chip_clear {
                    --padding-start: 0;
                    --padding-end: 0;
                }
            }

            .sw-btn_prev,
            .sw-btn_next {
                position: static;
                margin-top: 0;
            }

            .sw-block__footer {
                .sw-btn_solid {
                    display: block;
                }
                sw-toolbar {
                    flex-wrap: wrap;

                    &::part(start),
                    &::part(end) {
                        width: 100%;
                        flex-shrink: 0;
                    }

                    .sw-row-elements[slot] {
                        display: block;

                        > * {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

app-lot-proposition {
    .sw-block {
        --padding-bottom: 16px;
        --padding-start: 16px;
        --padding-end: 16px;
        --margin-bottom: 8px;

        .sw-control__label {
            --font-size-label: 13px;
            --color-label: var(--sw-text-color-secondary);
            font-weight: 400;
        }
    }

    .sw-grid_form > sw-row:not(:last-child) > sw-col {
        --sw-grid-column-padding-bottom: 24px;
    }

    @media (max-width: 576px) {
        .sw-grid_form {
            > sw-row > sw-col {
                --sw-grid-column-padding-bottom: 16px;
                --sw-grid-column-padding-start: 0;
                --sw-grid-column-padding-end: 0;
            }
            > sw-row:last-child > sw-col:last-child {
                --sw-grid-column-padding-bottom: 0;
            }
        }

        .sw-text_field-height {
            line-height: 20px;
        }
    }
}

#propositions {
    @media (max-width: 576px) {
        &.sw-block {
            --padding-top: 20px;
            --padding-start: 20px;
            --padding-end: 20px;
        }
    }
}

.sw-modal_propositions {
    .sw-modal {
        &-wrap {
            --max-height: 100%;
        }
        &__header {
            --padding-start-header: 20px;
        }
        &__content {
            --padding-top-content: 0;
            --padding-bottom-content: 0;
            --padding-start-content: 0;
            --padding-end-content: 0;
        }
    }
    .sw-block_info {
        --padding-top: 20px;
        --padding-start: 20px;
        --padding-end: 20px;
        --padding-bottom: 0;
        --margin-bottom: 0;
        min-height: 100%;

        .sw-block__header {
            --margin-bottom: 20px;
            --padding-bottom: 20px;
        }

        .sw-block__footer {
            --padding-top: 20px;
            --padding-start: 20px;
            --padding-end: 20px;
            --padding-bottom: 20px;
            --margin-start: -20px;
            --margin-end: -20px;
            position: sticky;
            bottom: 0;
            background: var(--sw-color-white);
        }
    }

    hr {
        --border-color: var(--sw-border-color-dark);
        // --border-style: dashed;
        --margin-top: 20px;
        --margin-bottom: 20px;
    }
}
