.sw-coordinates {
    display: inline-flex;
    align-items: center;
    margin-bottom: 12px;
    color: var(--sw-text-color-secondary);

    sw-button {
        --padding-start: 18px;
        --padding-end: 18px;
        margin-left: var(--sw-multiple-px);
    }

    sw-icon {
        font-size: 12px;
    }
}
