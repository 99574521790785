/* Core CSS required for components to work properly */
@import "./src/styles/global/core";

/* Basic CSS for apps built */
@import "./src/styles/global/normalize";
@import "./src/styles/global/structure";
@import "./src/styles/global/typography";

/* Optional CSS utils that can be commented out */
@import "./src/styles/global/utils/display-utils";
@import "./src/styles/global/utils/padding";
@import "./src/styles/global/utils/float-elements";
@import "./src/styles/global/utils/text-alignment";
@import "./src/styles/global/utils/text-decoration";
@import "./src/styles/global/utils/text-transformation";
@import "./src/styles/global/utils/flex-utils";
@import "./src/styles/global/utils/other";

/* Mixins */
@import './src/styles/global/mixins/flex';
@import './src/styles/global/mixins/media-query';
@import './src/styles/global/mixins/gradients';
@import './src/styles/global/mixins/text-ellipsis';
@import "./src/styles/global/mixins/props";

$path: '/assets';

/* Leaflet */
@import "leaflet/main";
@import "leaflet/popup";
@import "leaflet/tooltip";
// @import "leaflet/draw";
// @import "leaflet/context-menu";
// @import "leaflet/marker-cluster";
// @import "leaflet/marker-cluster_default";
// @import "leaflet/marker-highlight";
@import "leaflet/oldie";


/* Layout Components of alphabet */
@import 'layout-components/aside';
@import 'layout-components/footer';
@import 'layout-components/header';
@import 'layout-components/layout';
@import 'layout-components/section';

/* Base Components of alphabet */
@import 'base-components/accordion';
@import 'base-components/alert';
@import 'base-components/avatar';
@import 'base-components/backdrop';
@import 'base-components/balance';
@import 'base-components/block';
@import 'base-components/breadcrumbs';
@import 'base-components/button-group';
@import 'base-components/card';
@import 'base-components/cckeditor';
@import 'base-components/contact';
@import 'base-components/cookie';
@import 'base-components/coordinates';
@import 'base-components/corner';
@import 'base-components/counter';
@import 'base-components/dateplate';
@import 'base-components/dl';
@import 'base-components/dropdown';
@import 'base-components/dummy';
@import 'base-components/e-sign';
@import 'base-components/empty';
@import 'base-components/figure';
@import 'base-components/gallery';
@import 'base-components/grid';
@import 'base-components/interrogation';
@import 'base-components/list';
@import 'base-components/loader';
@import 'base-components/message-plane';
@import 'base-components/modal';
@import 'base-components/paginator';
@import 'base-components/percentage-bar';
@import 'base-components/referral-block';
@import 'base-components/row-elements';
@import 'base-components/scheduler';
@import 'base-components/scroll';
@import 'base-components/search';
@import 'base-components/sign';
@import 'base-components/slider';
@import 'base-components/status';
@import 'base-components/stepper';
@import 'base-components/tab';
@import 'base-components/tabs.cmp';
@import 'base-components/tag';
@import 'base-components/timeline';
@import 'base-components/toast';
@import 'base-components/toolbar';
@import 'base-components/tooltip';
@import 'base-components/totop';
@import 'base-components/other';

/* Form Components */
@import 'form-components/form.base';
@import 'form-components/checkbox';
@import 'form-components/datetime-interval';
@import 'form-components/file';
@import 'form-components/footer';
@import 'form-components/mask';
@import 'form-components/options';
@import 'form-components/radio';
@import 'form-components/select';
@import 'form-components/select-date';
@import 'form-components/switcher';
@import 'form-components/vehicle-number';
@import 'form-components/controls.range';
@import 'form-components/controls.number-buttons';
@import 'form-components/form-group';
@import 'form-components/display-error';

/* Table Components */
@import 'table-components/table.base';
@import 'table-components/grid.table';
@import 'table-components/table-twice';
@import 'table-components/cells';

/* Filters Components */
@import 'filter-components/filter';
@import 'filter-components/control.base';
@import 'filter-components/control.additional';
@import 'filter-components/tag';

/* Trip Components */
@import 'trip/trip-tape';
@import 'trip/trip-ticket';
@import 'trip/trip';


/* MAP */
@import 'map-components/map';
// @import 'map/map-toolbox';
// @import 'map/map-toolbar';
// @import 'map/palette';
// @import 'map/map';
// @import 'map/cluster';
// @import 'map/popup';
// @import 'map/map-timeline';

/* Pages */
@import 'pages/page';
@import 'pages/dispatcher-page';
@import 'pages/dummy-page';
@import 'pages/entrance-page';
@import 'pages/legal-page';
@import 'pages/list-page';
@import 'pages/main-page';
@import 'pages/referral-page';
@import 'pages/service-page';
@import 'pages/simple-page';
@import 'pages/summary-page';

/* Monitoring */
@import 'monitoring/base';
@import 'monitoring/filters';
@import 'monitoring/panels';
@import 'monitoring/widgets';

/* Blocks */
@import 'blocks/carrier-tasks-group';
@import 'blocks/faq';
@import 'blocks/lot-item';
@import 'blocks/notifications';
@import 'blocks/propositions';
@import 'blocks/other';
