.sw-control_select-date {
    width: 275px;

    .sw-dropdown__body {
        --top: calc(var(--height) + 3px);
        --right: 0;
        --left: auto;
        --width: 330px;
        --padding-start: calc(var(--sw-multiple-px) * 3);
        --padding-end: calc(var(--sw-multiple-px) * 3);
        --padding-top: calc(var(--sw-multiple-px) * 3);
        --padding-bottom: calc(var(--sw-multiple-px) * 3);
        --background: var(--sw-background);
        --border-width: 1px;
        --border-color: var(--sw-background-medium);

        .sw-grid_form  > sw-row > sw-col {
            --sw-grid-column-padding-start: calc(var(--sw-multiple-px) * 1.5);
            --sw-grid-column-padding-end: calc(var(--sw-multiple-px) * 1.5);

            &:first-child {
                --sw-grid-column-padding-start: 0;
            }
            &:last-child {
                --sw-grid-column-padding-end: 0;
            }
        }
    }
}
