.sw-header {
    /*
    * @prop --height:
    * @prop --padding-top: верхний внутренний отступ
    * @prop --padding-bottom: нижний внутренний отступ
    * @prop --background: фон шапки
    * @prop --color: цвет текста в шапке
    * @prop --color-link: цвет ссылок в шапке
    * @prop --color-link-hover: цвет ссылок в шапке при наведении
    * @prop --color-link-disabled: цвет отключенных ссылок в шапке
    */
    --height: initial;
    --padding-top: initial;
    --padding-bottom: initial;
    --color-link: var(--sw-color-action);
    --color-link-hover: var(--color-link);
    --color-link-disabled: var(--color-link);

    height: var(--height);
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    background: var(--background);
    color: var(--color);

    &-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    a {
        color: var(--color-link);

        &:hover,
        &.active {
            color: var(--color-link-hover);
        }

        &:disabled {
            color: var(--color-link-disabled);
        }
    }

    &__logo {
        /*
        * @prop --margin-top: внешний верхний отступ лого
        * @prop --margin-end: внешний конечный отступ лого
        * @prop --margin-bottom: внешний нижний отступ лого
        * @prop --margin-start: внешний начальный отступ лого
        */
        display: inline-block;
        flex-shrink: 0;
        margin-left: var(--margin-start);
        margin-right: var(--margin-end);
        margin-top: var(--margin-top);
        margin-bottom: var(--margin-bottom);
    }

    &.sw-container {
        &::before,
        &::after {
            display: none;
        }
    }
}

// implemented
.sw-header {
    --height: 56px;
    --background: var(--sw-header-background);
    --color: var(--sw-text-color-contrast);
    --color-link: var(--color);
    --color-link-hover: var(--sw-color-primary);
    --color-link-disabled: var(--sw-placeholder-color);

    a {
        line-height: 20px;
        text-decoration: none;

        &[nohref] {
            cursor: default;
        }
    }

    &__logo {
        --margin-end: calc(var(--sw-multiple-px) * 11);
    }

    &__menu,
    &__user-menu {
        & > .sw-item {
            --margin-end: calc(var(--sw-padding) * 2 - 4px);
        }

        .sw-dropdown {
            display: flex;
            height: var(--height);
            align-items: center;

            &__trigger {
                --padding-start: 0;
                --padding-end: 0;
                min-width: 36px;
            }
        }
    }

    &__menu {
        &.sw-list {
            --font-weight: 500;
            --font-size: 14px;

            &_h {
                > .sw-item {
                    --margin-end: calc(var(--sw-multiple-px) * 6);
                }
            }

            .sw-item {
                .sw-dropdown__body {
                    --left: 0;
                    --right: auto;
                }
            }
        }

        .sw-dropdown__body {
            --padding-top: var(--sw-padding);
            --padding-bottom: var(--sw-padding);
            --padding-start: 0;
            --padding-end: 0;

            .sw-item {
                --margin-bottom: 0;
                --padding-top: calc(var(--sw-multiple-px) * 2);
                --padding-bottom: calc(var(--sw-multiple-px) * 2);
                --padding-start: var(--sw-padding);
                --padding-end: var(--sw-padding);
                --background-hover: var(--sw-background-dark);
            }
        }
    }

    &__user-menu {
        & > .sw-item:last-child {
            --margin-end: 0;
        }
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__profile {
        sw-chip {
            // хардкод, чтобы не добавлялось дефолтное инлайновое вертикальное позиционирование
            // при этом блочное поведение нам тоже не нужно
            float: left;
            --margin-start: 0;
            --margin-end: 0;
            --margin-bottom: 0;
            --margin-top: 8px;
            --height: 20px;
            --border-radius: 12px;
        }

        .sw-avatar {
            position: relative;

            &:not(.sw-avatar_small) {
                margin-right: 20px;
            }

            &_small {
                font-size: var(--font-size);
            }

            sw-icon {
                --sw-icon-small-size: 12px;
                position: absolute;
                bottom: 0;
                right: -4px;
                margin-right: 0;
                background: var(--color);
                border-radius: 50%;
                color: var(--sw-header-background);
            }

            &:hover {
                color: var(--color-hover);
            }
        }

        .sw-dropdown {
            &__trigger {
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }

        .sw-list_menu {
            --margin-bottom: 0;
            --border-width: 1px 0 0 0;
            --border-style: solid;
            --border-color: var(--sw-border-color);

            --padding-top-list: 20px;
        }
    }

    .sw-dropdown {
        &__body {
            --sw-margin: 18px;
            --color: var(--sw-text-color);
            --color-link: var(--sw-color-action);
            --color-link-hover: var(--color-link);
            --color-link-disabled: var(--color-link);

            .sw-list_underline {
                a,
                sw-button {
                    --color: var(--sw-text-color);
                }
            }
        }
    }

    .sw-lang {
        --padding-top: 3px;
        --padding-bottom: 3px;
        --padding-start: calc(20px - 36px / 4);
        --border-width: 0 0 0 1px;
        --border-style: none none none solid;
        --border-color: transparent transparent transparent rgba(224, 222, 220, 0.5);

        .sw-dropdown {
            --width: 112px;
            --top: 32px;
            height: 24px;
            line-height: 24px;

            &__body {
                &::before {
                    position: absolute;
                    content: '';
                    top: -16px;
                    height: 16px;
                    left: 0;
                    right: 0;
                }

                .sw-list_select {
                    --padding-top-list: calc(var(--sw-multiple-px) * 3);
                    --padding-bottom-list: calc(var(--sw-multiple-px) * 3);
                    --border-radius-list: var(--sw-border-radius);
                }
            }
        }
    }

    .sw-search_global {
        --background-hover: var(--sw-color-white);
        --background-focus: var(--sw-color-white);
        --background-active: var(--sw-color-white);
        --background: rgba(var(--sw-color-white-rgb), 0.1);
        --border-color: rgba(241, 246, 251, 0.3);
        --border-color-hover: var(--border-color-focus);

        .sw-control__field-group__start,
        .sw-control__field-group__end,
        .sw-control__field {
            background: transparent;
        }

        width: 208px;
        max-width: 980px;
        margin-right: 8px;
    }

    &_search-active {
        .sw-header__menu {
            display: none;
        }

        .sw-header__user-menu {
            flex-shrink: 0;
            width: calc(100% - 154px); // ширина логотипа + отступ

            & > .sw-item:first-child {
                width: 100%;

                .sw-search_global {
                    --background: var(--background-hover);
                    --border-color: var(--border-color-focus);
                    width: 100%;
                }
            }
        }
    }

    &_home {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 160;

        @media (min-width: 1024.98px) {
            --background: transparent;
        }
    }

    app-header-mobile & {
        position: relative;
    }

    .sw-menu-burger {
        --background: var(--sw-header-background);
        --border-radius: 50%;

        position: fixed;
        z-index: 1001;
        right: 16px;
        top: 8px;

        // transition: top 200ms;
    }

    &-dropdown {
        position: fixed;
        z-index: 1001;
        overflow-x: hidden;
        overflow-y: auto;
        right: 16px;
        top: 56px;
        bottom: 12px;
        width: 344px;
        // height: calc(100% - 56px);
        background: var(--sw-color-white);
        border-radius: var(--sw-border-radius);
        box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
        padding: 32px 20px 20px;

        .sw-avatar {
            margin-right: 20px;

            + div {
                sw-chip {
                    --margin-start: 0;
                }
            }
        }

        .sw-list_menu {
            --color: var(--sw-text-color);
            --background-hover: transparent;
            --margin-bottom: 24px;
        }

        .sw-list_overline {
            --margin-top-list: 20px;
            --padding-start: 0;
            --padding-top: 16px;
            --padding-bottom: 16px;
            --margin-bottom: 0;
            --font-size: 14px;

            sw-button:not(.sw-item) {
                --padding-start: 0;
                --padding-top: 16px;
                --padding-bottom: 16px;
            }
        }

        .sw-item {
            a {
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;

                + .sw-item__child {
                    margin-top: 12px;
                }
            }

            &__child {
                a {
                    --padding-top: 8px;
                    --padding-bottom: 8px;
                    --padding-start: 20px;
                    font-weight: 400;
                    font-size: 16px;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        padding-right: 40px;

        .sw-header__logo img {
            display: block;
        }

        .sw-header__user-menu {
            sw-button.sw-btn_clear {
                --color: var(--sw-color-white);
                --background-hover: transparent;
                --background-activated: transparent;
                --background-focused: transparent;
            }
        }

        .sw-header-wrap {
            height: 100%;
        }

        &.sw-header--fixed {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: 1001;
        }
    }

    @media (max-width: 768px), (max-device-width: 576px) {
        .sw-header-dropdown {
            right: 0;
            left: 0;
            bottom: auto;
            width: 100%;
            max-height: 100%;
            overflow: auto;
            border-radius: 0;
        }
    }

    @media only screen and (max-height: 575.98px) and (orientation: landscape) {
        .sw-header-dropdown {
            bottom: 0;
        }
    }

    @media (max-width: 576px) {
        .sw-header__logo img {
            height: 36px;
        }
    }
}
