.sw-figure {
    /**
    * @prop --background: фон кнопки или подложки картинки
    * @prop --border-radius: радиус скругления
    * @prop --border-width: толщина границы
    * @prop --border-style: стиль границы
    * @prop --border-color: цвет границы
    *
    * @prop --height: высота
    * @prop --width: ширина
    * @prop --min-width: минимальная ширина
    *
    * @prop --font-size: размер шрифта
    * @prop --font-weight: жирность шрифта
    */
    --background: initial;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --height: initial;
    --width: initial;
    --min-width: initial;
    --font-size: inherit;
    --font-weight: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    height: var(--height);
    width: var(--width);
    background: var(--background);
    border-radius: var(--border-radius);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);

    img, video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: var(--border-radius);
    }

    &_btn {
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);
        outline: none;
        font-size: var(--font-size);
        font-weight: var(--font-weight);

        sw-icon {
            --sw-icon-small-size: 16px;
            margin-bottom: calc(var(--sw-multiple-px) * 2);
        }

        &.sw-color {
            border-color: var(--sw-color-base);
            color: var(--sw-color-base);
        }
    }
}

.sw-figcaption {
}

// implemented
.sw-figure {
    --height: 168px;
    --width: 210px;
    --background: var(--sw-color-white);
    --border-radius: var(--sw-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--sw-border-color);
    --font-size: 13px;
    --font-weight: 500;
    margin-right: calc(var(--sw-multiple-px) * 2);

    &:last-child {
        margin-right: 0;
    }

    &__close {
        position: absolute;
        top: var(--sw-multiple-px);
        right: var(--sw-multiple-px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin: 0;
        outline: 0;
        border-radius: 50%;
        background: rgba(var(--sw-color-white-rgb), .5);
        padding: 0;
    }

    .swh-ellipsis {
        max-width: calc(100% - 32px);
    }

    .sw-percentage-bar {
        --height: 4px;
        width: 178px;
    }

    .sw-list_gallery & {
        --border-radius: none;
    }

    .sw-control & {
        margin-top: calc(var(--sw-multiple-px) * 3);
    }

    // types
    &_btn {
        --border-width: 1px;
        --border-style: dashed;
        --border-color: var(--sw-color-action);
    }
    &_long {
        --width: auto;
        margin-bottom: calc(var(--sw-multiple-px) * 8);
    }
}
