.sw-mask {
    position: absolute;
    width: 100%;
    opacity: .4;
    pointer-events: none;
    //border: none;
    //padding: 0;
    border-color: transparent!important;
    top: 0;
    left: 0;
    outline: none;

    .sw-input-group__field ~ & {
        padding: 8px 0 9px 2px;
    }
}
