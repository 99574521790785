.sw-modal {
    /*
    * @prop --backdrop-opacity:	прозрачность подложки
    * @prop --background: фон модалки
    * @prop --border-radius: радиус скругления модалки
    * @prop --border-width:	толщина границы
    * @prop --border-style:	стиль границы
    * @prop --border-color:	цвет границы
    * @prop --box-shadow: тень модального окна
    * @prop --height: высота модального окна
    * @prop --width: ширина моадльного окна
    * @prop --max-height: максимальная высота модального окна
    * @prop --max-width: максимальная ширина моадльного окна
    * @prop --min-height: минимальная высота модального окна
    * @prop --min-width: минимальная ширина модального окна
    *
    * @prop --top-close: позиционирование кнопки закрытия по верху окна
    * @prop --bottom-close: позиционирование кнопки закрытия по низу окна
    * @prop --left-close: позиционирование кнопки закрытия слева окна
    * @prop --right-close: позиционирование кнопки закрытия справа окна
    *
    * @prop --padding-start-header: внутренний начальный отступ шапки модального окна
    * @prop --padding-end-header: внутренний конечный отступ шапки модального окна
    * @prop --padding-top-header: внутренний верхний отступ шапки модального окна
    * @prop --padding-bottom-header: внутренний нижний отступ шапки модального окна
    *
    * @prop --padding-start-content: внутренний начальный отступ контента модального окна
    * @prop --padding-end-content: внутренний конечнй отступ контента модального окна
    * @prop --padding-top-content: внутренний верхний отступ контента модального окна
    * @prop --padding-bottom-content: внутренний начальный отступ контента модального окна
    */
    --backdrop-opacity: initial;
    --background: initial;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --box-shadow: initial;
    --height: initial;
    --width: initial;
    --max-height: initial;
    --max-width: initial;
    --min-height: initial;
    --min-width: initial;

    position: fixed;
    z-index: 1101;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: var(--background);
    border-radius: var(--border-radius);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    box-shadow: var(--box-shadow);
    height: var(--height);
    max-height: var(--max-height);
    min-height: var(--min-height);
    width: var(--width);
    max-width: var(--max-width);
    min-width: var(--min-width);

    &-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__close {
        position: absolute;
        z-index: 1;
        right: var(--right-close);
        top: var(--top-close);
        left: var(--left-close);
        bottom: var(--bottom-close);
    }

    &__header {
        padding-left: var(--padding-start-header);
        padding-right: var(--padding-end-header);
        padding-top: var(--padding-top-header);
        padding-bottom: var(--padding-bottom-header);
    }

    &__content {
        height: 100%;
        overflow: auto;
        padding-left: var(--padding-start-content);
        padding-right: var(--padding-end-content);
        padding-top: var(--padding-top-content);
        padding-bottom: var(--padding-bottom-content);
    }

    &__footer {
        padding-left: var(--padding-start-footer);
        padding-right: var(--padding-end-footer);
        padding-top: var(--padding-top-footer);
        padding-bottom: var(--padding-bottom-footer);
    }

    &__header,
    &__footer {
        flex-shrink: 1;
    }

    &__header_shadow {
        @extend .sw-modal__header;
        box-shadow: var(--box-shadow);
    }

    // types
    &_map {
        .sw-map-wrapper {
            --margin-start: calc(var(--padding-start-content) * -1);
            --margin-end: calc(var(--padding-end-content) * -1);
            --margin-bottom: calc(var(--padding-bottom-content) * -1);
            --width: calc(100% + var(--padding-end-content) + var(--padding-start-content));
        }
    }

    &_fullscreen {
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
    }

    &_dummy {
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
    }
}

// implemented
.sw-modal {
    --background: var(--sw-color-white);
    --border-radius: var(--sw-border-radius);
    --box-shadow: 0px 15px 15px -10px rgba(239, 240, 242, 0.4);
    // --height:;
    --width: 100%;
    --max-height: calc(100vh - var(--sw-multiple-px) * 20);
    --max-width: 976px;
    --min-height: 62.5vh; //640px;
    // --min-width:;

    --top-close: calc(var(--sw-multiple-px) * 5);
    --right-close: calc(var(--sw-multiple-px) * 5);

    --padding-start-header: calc(var(--sw-multiple-px) * 10);
    --padding-end-header: calc(var(--sw-multiple-px) * 10);
    --padding-top-header: calc(var(--sw-multiple-px) * 5);
    --padding-bottom-header: calc(var(--sw-multiple-px) * 5);

    --padding-start-content: calc(var(--sw-multiple-px) * 10);
    --padding-end-content: calc(var(--sw-multiple-px) * 10);
    --padding-top-content: calc(var(--sw-multiple-px) * 8);
    --padding-bottom-content: calc(var(--sw-multiple-px) * 8);

    --padding-start-footer: calc(var(--sw-multiple-px) * 10);
    --padding-end-footer: calc(var(--sw-multiple-px) * 10);
    --padding-top-footer: calc(var(--sw-padding) * 2);
    --padding-bottom-footer: calc(var(--sw-padding) * 2);

    + .sw-backdrop {
        --backdrop-opacity: 0.6;
        --opacity: var(--backdrop-opacity);
    }

    &,
    &-wrap {
        &--scrolling {
            .sw-modal__header {
                position: relative;
                height: 119px; // 76px

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 76px;
                    box-shadow: var(--box-shadow);
                }

                h1 {
                    padding-top: 0;
                    font-size: 28px;
                    line-height: 36px;
                }
            }
        }
    }

    &__header {
        h1 {
            padding-bottom: 0;
            padding-top: 27px;
            transition: padding-top 150ms, font-size 150ms, line-height 150ms;
        }
    }

    &__content {
        section {
            margin-bottom: 40px;
        }
    }

    &__footer {
        box-shadow: 0px -15px 15px -10px rgba(239, 240, 242, 0.4);
    }

    .sw-toast {
        --box-shadow: none;
    }

    // types
    &_map {
        --height: 640px;

        h4 {
            margin-right: 16px;
        }

        .sw-map-wrapper {
            --height: calc(80% - var(--margin-bottom));

            .sw-open-btn {
                display: none;
            }

            .sw-map {
                border-radius: 0 0 var(--border-radius) var(--border-radius);
            }
        }
    }

    &_gallery {
        --background: transparent;
        --top-close: calc(var(--sw-multiple-px) * 10);
        --right-close: calc(var(--sw-multiple-px) * 10);

        + .sw-backdrop {
            --backdrop-opacity: 0.9;
            --opacity: var(--backdrop-opacity);
        }

        .sw-modal__content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            // app-gallery-modal {
            // height: 100%;
            // }
        }

        img,
        video {
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
        }

        sw-button {
            --background: transparent;
            --border-color: var(--sw-color-white);
            --color: var(--sw-color-white);
            --color-hover: var(--sw-text-color);

            &.sw-prev,
            &.sw-next {
                position: absolute;
                top: calc(50% - 18px); // половина на высоты кнопок
            }

            &.sw-prev {
                left: 40px;
            }

            &.sw-next {
                right: 40px;
            }
        }

        .sw-loader_inline {
            --height: auto;
        }

        app-plot-visit-facts,
        #plotCanvas {
            width: 100%;
            height: 100%;
        }

        .plot-container {
            width: 100%;
            height: 100%;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            overflow: hidden;

            > .svg-container {
                margin: auto;
            }
        }
    }

    &_video {
        --background: transparent;
        --top-close: 20px;
        --right-close: 78px;

        + .sw-backdrop {
            --backdrop-opacity: 0.9;
            --opacity: var(--backdrop-opacity);
        }

        .sw-modal__content {
            --padding-start-content: 0;
            --padding-end-content: 0;
            --padding-top-content: 0;
            --padding-bottom-content: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    &_small {
        --max-height: 580px;
        --max-width: 780px;

        --padding-top-header: calc(var(--sw-multiple-px) * 12);
        --padding-bottom-header: calc(var(--sw-multiple-px) * 4);
        --padding-top-content: calc(var(--sw-multiple-px) * 4);

        &.sw-modal_map {
            --max-height: 490px;
            --max-width: 976px;

            --padding-top-content: calc(var(--sw-multiple-px) * 8);
        }
    }

    &_ossig-confirm {
        --min-height: 565px;
        --max-height: 658px;
        --max-width: 780px;

        --padding-top-header: 20px;
        --padding-bottom-header: 24px;
    }

    &_xs {
        --min-height: 401px;
        --max-height: 430px;
        --max-width: 664px;
        --padding-bottom-header: 0;
        --padding-top-content: 0;
        --padding-bottom-content: 0;
    }

    &_mobile-bottom {
        --width: calc(100% - 16px);
        --min-height: 0;

        --padding-start-header: calc(var(--sw-multiple-px) * 5);
        --padding-end-header: calc(var(--sw-multiple-px) * 5);
        --padding-top-header: calc(var(--sw-multiple-px) * 5);
        --padding-bottom-header: calc(var(--sw-multiple-px) * 5);

        --padding-top-content: 0;
        --padding-bottom-content: calc(var(--sw-multiple-px) * 5);
        --padding-start-content: calc(var(--sw-multiple-px) * 5);
        --padding-end-content: calc(var(--sw-multiple-px) * 5);

        --padding-start-footer: calc(var(--sw-multiple-px) * 5);
        --padding-end-footer: calc(var(--sw-multiple-px) * 5);
        --padding-top-footer: calc(var(--sw-multiple-px) * 5);
        --padding-bottom-footer: calc(var(--sw-multiple-px) * 5);
        bottom: 0;
        top: auto;
        margin: 8px;

        .sw-list_underline {
            --border-style: dashed;
            --padding-top-list: 0;
            --padding-bottom-list: 0;

            --padding-bottom: 16px;
            --padding-top: 0;
            --margin-bottom: 16px;

            > .sw-item {
                &:last-child {
                    border: none;
                }
            }
        }

        .sw-control & {
            --padding-top-content: calc(var(--sw-multiple-px) * 5);

            .sw-control__label {
                --font-size-label: 18px;
                line-height: 24px;
                color: var(--sw-text-color);
            }

            .sw-radio__label::before {
                margin-left: 0;
            }

            sw-button {
                --height: 44px;
                margin: 40px 0 20px;
            }
        }
    }

    &_role {
        --max-width: 664px;
        --max-height: 598px;
        --padding-top-content: 52px;
    }

    &_filter {
        --max-width: 512px;
        --max-height: 568px;

        --padding-start-header: 24px;
        --padding-end-header: 24px;

        --padding-top-content: 24px;
        --padding-bottom-content: 24px;
        --padding-start-content: 24px;
        --padding-end-content: 24px;

        --padding-start-footer: 24px;
        --padding-end-footer: 24px;
        --padding-top-footer: 20px;
        --padding-bottom-footer: 20px;

        .sw-control {
            --margin-bottom: 20px;
        }
    }

    @media (max-width: 992px) {
        &:not(.sw-modal_fullscreen, .sw-modal_mobile-bottom, .sw-modal_dummy) {
            --width: calc(100% - 72px);
            --min-height: auto;
            --max-height: 100%;

            margin: 40px 36px;
            bottom: auto;

            .sw-modal-wrap {
                max-height: var(--max-height);
            }
        }

        .sw-modal__footer {
            .sw-btn_clear {
                margin-left: -16px;
                margin-right: -16px;
            }
        }
    }

    @media (max-width: 665px) {
        &_signing {
            sw-accordion {
                .sw-file {
                    .sw-list_bullet {
                        flex-wrap: wrap;

                        .sw-item::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 650px) {
        &_signing {
            sw-accordion {
                .sw-file .swh-ellipsis{
                    --width: 300px !important;
                }
            }
        }
    }

    @media (max-width: 576px) {
        &:not(.sw-modal_mobile-bottom, .sw-modal_dummy) {
            --min-height: 100vh;
            --width: 100%;
            --border-radius: 0;
            --box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);

            --top-close: 9px; // 12
            --right-close: auto;
            --left-close: 12px;

            --padding-start-header: 60px;
            --padding-end-header: calc(var(--sw-multiple-px) * 5);
            --padding-top-header: calc(var(--sw-multiple-px) * 4);
            --padding-bottom-header: calc(var(--sw-multiple-px) * 4);

            --padding-start-content: calc(var(--sw-multiple-px) * 5);
            --padding-end-content: calc(var(--sw-multiple-px) * 5);

            --padding-start-footer: calc(var(--sw-multiple-px) * 5);
            --padding-end-footer: calc(var(--sw-multiple-px) * 5);
            --padding-top-footer: calc(var(--sw-multiple-px) * 4);
            --padding-bottom-footer: calc(var(--sw-multiple-px) * 4);

            margin: 0;
            bottom: 0;
        }

        .sw-modal__header {
            h1 {
                padding-top: 0;
                font-size: 18px;
                line-height: 24px;
            }
            h4 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .sw-modal__footer {
            box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
        }

        app-signature {
            .sw-modal__footer {
                sw-button {
                    display: block;
                }
            }
        }

        &.sw-modal_video {
            --top-close: 8px;
            --right-close: 8px;
            --left-close: auto;
            padding: 0;

            .sw-modal__close {
                z-index: 100;
            }

            app-video-modal {
                display: flex;
                flex-direction: column;
                height: 100vh;
                justify-content: center;
            }

            .sw-modal__content {
                position: relative;
                height: 0;
                padding-top: 56.25%; /* 16:9 */
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;

                iframe {
                    // height: auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        &.sw-modal_map {
            --padding-top-content: 0;
            --padding-bottom-content: 0;
            --padding-start-content: 0;
            --padding-end-content: 0;

            --top-close: 8px;
            --right-close: 8px;
            --left-close: auto;

            .sw-desktop-content {
                display: none;
            }

            .sw-map-wrapper {
                --height: 100vh;
            }

            .sw-mobile-content {
                .sw-control {
                    --height: 34px;
                    --control-width: calc(100% - 8px);
                    margin-right: 8px;
                }

                .sw-list_bullet > .sw-item {
                    --font-size: 16px;
                    --line-height: 24px;
                    --font-weight: 500;
                    --color: var(--sw-text-color);
                }
            }
        }

        &.sw-modal_signing {
            app-signing {
                display: contents;
            }

            .sw-modal-wrap {
                --max-height: auto;
            }

            .sw-modal__footer {
                sw-toolbar {
                    &::part(start),
                    &::part(end) {
                        width: calc(50% - 4px);
                    }

                    > *[slot] {
                        display: block;
                    }

                    sw-button {
                        display: block;
                    }
                }
            }

            .sw-loader_signing {
                --height: 218px;
                top: auto;
                left: 8px;
                right: 8px;
                bottom: 8px;
                padding: 32px 20px 40px;
            }
        }
    }

    @media (max-width: 450px) and (min-width: 360.98px) {
        &_signing {
            sw-accordion {
                .sw-file .swh-ellipsis{
                    --width: 200px !important;
                }
            }
        }
    }
    @media (max-width: 360px) {
        &_signing {
            sw-accordion {
                .sw-file .swh-ellipsis{
                    --width: 180px !important;
                }
            }
        }
    }
}
