.sw-sign {
    position: relative;
    // margin-left: var(--sw-padding);
    width: 56px;
    height: 56px;
    // padding-right: calc(var(--sw-multiple-px) * 5);

    &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        background: rgba(var(--sw-color-info-rgb), 0.2);
    }

    sw-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 21px;
        height: 21px;
        margin: auto;
        border-radius: 50%;
        border: 2px solid var(--sw-color-white);
        padding: 7px;
        background-color: var(--sw-color-info);
        font-size: 21px;
        color: var(--sw-color-white);
    }

    &.sw-color {
        &::before {
            background: rgba(var(--sw-color-base-rgb), 0.2);
        }
        sw-icon {
            background: var(--sw-color-base);
        }

        &.sw-color-light {
            &::before {
                background: rgba(var(--sw-color-white-rgb), 0.2);
            }
            sw-icon {
                background: var(--sw-color-white);
                color: var(--sw-color-action);
            }
        }
    }
}
