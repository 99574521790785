.sw-control_number-buttons {
    .sw-control__field-group {
        --size-icon: 12px;

        sw-button {
            --multiple: 1.25;
            --height: 24px;
            --border-radius: 50%;

            ::slotted(sw-icon[slot=icon-only]) {
                font-size: var(--size-icon);
            }
        }
    }

    &__data {
        margin: 0 calc(var(--sw-multiple-px) * 3);
        white-space: nowrap;
    }

    .sw-control__note {
        .sw-grid_table & {
            padding-top: 6px;
        }
    }
}
