// все типы аккордионов с переопределяющими переменными компонента
.sw-accordion {
    &_date-group {
        &::part(header) {
            --padding-top: 0;
        }
        &::part(body) {
            --padding-bottom: 0;
        }

        &:not(.sw-accordion--opened) {
            &::part(header) {
                --padding-bottom: 0;
            }
        }
    }

    &_faq {
        --icon-medium-size: 20px;
        --icon-color: var(--sw-text-color-secondary);
        --icon-color-hover: var(--sw-color-primary);
        --icon-color-active: var(--sw-color-primary);

        --open-close-icon-size: 16px;
        --open-close-width: 32px;
        --open-close-height: 32px;
        --open-close-color-hover: var(--sw-color-primary);
        --open-close-color-active: var(--sw-color-primary);

        &::part(header) {
            --padding-top: calc(var(--sw-multiple-px) * 5);
            --border-width: 1px 0 0 0;
            font-size: 16px;
            line-height: 24px;

            &:hover {
                --color: var(--sw-color-primary);
            }
        }
        &::part(body) {
            --padding-top: 0;
            --padding-bottom: calc(var(--sw-multiple-px) * 5);
            --padding-start: calc(var(--sw-multiple-px) * 8);
            --border-width: 0;
        }

        app-faq-answer:last-of-type & {
            --margin-bottom: calc(var(--sw-multiple-px) * 5);

            &:not(.sw-accordion--opened)::part(header) {
                --border-width: 1px 0;
            }
            &::part(body) {
                --border-width: 0 0 1px 0;
            }
        }
    }

    &_info {
        --margin-top: 20px;
        --open-close-color: var(--sw-text-color-secondary);

        &::part(header) {
            --background: var(--sw-background-info);
            --padding-start: 20px;
            --padding-end: 20px;
            --padding-top: 12px;
            --padding-bottom: 12px;
            --border-width: 0;
        }
        &::part(body) {
            --padding-start: 20px;
            --padding-end: 20px;
            --padding-top: 0;
            --padding-bottom: 12px;
            --background: var(--sw-background-info);
            --border-radius: 0 0 var(--sw-border-radius) var(--sw-border-radius);
        }

        &:not(.sw-accordion--opened) {
            &::part(header) {
                --border-radius: var(--sw-border-radius);
            }
        }

        &.sw-accordion--opened {
            &::part(header) {
                --border-radius: var(--sw-border-radius) var(--sw-border-radius) 0 0;
            }
        }

        .sw-table_clear tr {
            &:first-child {
                td {
                    --padding-top: 0;
                }
            }
            &:last-child {
                td {
                    --padding-bottom: 0;
                }
            }
            td {
                --padding-top: 4px;
                --padding-bottom: 4px;
            }
        }
    }

    &_profile {
        sw-icon[slot=icon] {
            --icon-medium-size: 24px;
        }
    }
}

@media (max-width: 576px) {
    app-wastes-widget-group sw-accordion {
        --margin-bottom: 16px;

        &::part(header) {
            --padding-start: 20px;
            --padding-end: 20px;
            --padding-top: 20px;
            --padding-bottom: 20px;

            --border-style: solid;
            --border-color: var(--sw-border-color);
        }

        &:not(.sw-accordion--opened) {
            &::part(header) {
                --border-radius: 8px;
                --border-width: 1px;
            }
        }

        &.sw-accordion--opened {
            &::part(header) {
                --border-radius: 8px 8px 0 0;
                --border-width: 1px 1px 0;
            }
        }

        &::part(body) {
            --padding-start: 20px;
            --padding-end: 20px;
            --padding-top: 0;
            --padding-bottom: 20px;

            --border-width: 0 1px 1px;
            --border-style: solid;
            --border-color: var(--sw-border-color);

            --border-radius: 0 0 8px 8px;
        }

        h6.sw-h6_32 {
            line-height: 24px;

            span.swh-block {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
            }
        }
    }
}
