.sw-dropdown {
    /*
    * @prop --width:
    *
    * @prop --padding-start:
    * @prop --padding-end:
    * @prop --padding-top:
    * @prop --padding-bottom:
    * @prop --background:
    * @prop --border-radius:
    * @prop --border-width:
    * @prop --border-style:
    * @prop --border-color:
    * @prop --box-shadow:
    *
    * @prop --top:
    * @prop --bottom:
    * @prop --left:
    * @prop --right:
    */
    --width: initial;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --background: initial;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --box-shadow: initial;
    --top: initial;
    --bottom: initial;
    --left: initial;
    --right: initial;

    position: relative;

    &__body {
        position: absolute;
        z-index: 161;
        top: var(--top);
        bottom: var(--bottom);
        left: var(--left);
        right: var(--right);
        width: var(--width);
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
        background: var(--background);
        border-radius: var(--border-radius);
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);
        box-shadow: var(--box-shadow);

        .sw-dropdown_hoverable & {
            visibility: hidden;
        }

        .sw-dropdown_hoverable:hover & {
            visibility: visible;
        }
    }
}

// implemented
.sw-dropdown {
    --width: 362px;
    --padding-start: calc(var(--sw-multiple-px) * 4);
    --padding-end: calc(var(--sw-multiple-px) * 4);
    --padding-top: calc(var(--sw-multiple-px) * 5);
    --padding-bottom: calc(var(--sw-multiple-px) * 5);
    --background: var(--sw-color-white);
    --border-radius: var(--sw-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--sw-background-medium);
    --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);;
    --top: 48px;
    // --bottom: 0;
    // --left: 0;
    --right: 0;

    sw-button.sw-dropdown__trigger,
    &__trigger sw-button,
    a.sw-dropdown__trigger,
    &__trigger a {
        --color: inherit;
        --color-link-hover: var(--color);
        --background-hover: var(--background);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        white-space: nowrap;

        sw-icon {
            --sw-icon-small-size: 16px;
            --sw-icon-medium-size: 24px;

            &.sw-icon-medium {
                font-size: var(--sw-icon-medium-size);
            }
        }
    }

    .sw-list_select {
        --margin-start-list: -16px;
        --margin-end-list: -16px;
        --margin-top-list: -20px;
        --margin-bottom-list: -20px;
        --border-radius: 0;
        --box-shadow: none;

        position: static;
        width: calc( 100% - var(--margin-start-list) - var(--margin-end-list));
        border: 0;

        .sw-item {
            --height: 36px;
            --color: var(--sw-text-color);
            --color-hover: var(--sw-text-color);
            margin: 0;
        }
    }
    .sw-options-content .sw-list_select  {
        --margin-top-list: 20px;
        max-height: 210px;
        overflow: auto;
    }

    &_action {
        --top: 28px;
        --width: 210px;
        --color: var(--sw-text-color-secondary);
        float: right;
    }

    .sw-list_vline & {
        --top: 28px;
        --width: 220px;
        margin-left: 20px;
    }

    &_notifications {
        --width: 484px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;

        .sw-dropdown__body {
            // transform: translateX(calc(var(--width) / 2 - 18px));
            display: none;

            &[data-show] {
                display: block;
            }
        }
    }

    &_tooltip {
        sw-tooltip {
            --min-width: 210px;
            --padding-top: calc(var(--sw-multiple-px) * 3);
            --padding-bottom: calc(var(--sw-multiple-px) * 3);
            --padding-start: 0;
            --padding-end: 0;
        }

        .sw-item sw-button {
            display: block;

            &::part(inner) {
                justify-content: flex-start;
            }
        }
    }

    &_settings-filter {
        --top: 36px;
        --left: 0;
        --width: 295px;
        --padding-top: calc(var(--sw-multiple-px) * 3);
        --padding-bottom: calc(var(--sw-multiple-px) * 3);

        sw-button.sw-dropdown__trigger {
            --background: var(--sw-color-info-tint);
            --color: var(--sw-color-base);
        }

        .sw-control__label {
            margin-bottom: 12px;

            & + .sw-radio {
                margin-top: 0;
                margin-bottom: calc(var(--sw-multiple-px) * 4);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @media (max-width: 992px) {
            .sw-dropdown__body {
                --top: auto;
                --left: 8px;
                --right: 8px;
                --bottom: 8px;
                --width: calc(100% - 16px);
                position: fixed;
                z-index: 1001;

                hr {
                    display: none;

                    + sw-button {
                        --height: 44px;
                        display: block;
                    }
                }
            }
        }
    }

    &_telemetry {
        sw-button.sw-dropdown__trigger {
            --color: var(--sw-color-contrast);
        }

        sw-thumbnail {
            margin-right: 12px;
        }

        .sw-dropdown__body {
            .sw-item {
                &--disabled {
                    --background: var(--sw-background-medium);
                    filter: grayscale(1);
                }
            }
            .sw-list {
                .sw-item {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    &_vehicle {
       --padding-bottom: 12px;

        .sw-dropdown__body {
            .sw-list_control-dd {
                --margin-start-list: 0;
                --margin-end-list: 0;
                --margin-top-list: 20px;
                --margin-bottom-list: 0;
            }

            hr {
                --margin-top: 0;
                --margin-bottom: 12px;
                margin-left: -16px;
                margin-right: -16px;
            }

            .sw-close {
                position: absolute;
                right: 16px;
                top: 16px;
            }
        }
    }

    .sw-btn-group .sw-control_select-date &__body {
        --top: 40px;
    }
}
