.sw-slider {
    &__content {
        display: flex;
        align-items: stretch;
        will-change: transform;
        transition: margin-left 300ms;

        > * {
            flex-shrink: 0;
        }
    }
}
