.sw-interrogation {
    @include props-margin;
    @include margin;

    --color: inherit;
    color: var(--color)
}


// implemented
.sw-interrogation {
    --margin-start: calc(var(--sw-multiple-px) * 3);
    --color: var(--sw-placeholder-color);

    sw-icon {
        --sw-icon-small-size: 12px;
    }

    .sw-checkbox_right + & {
        --margin-start: 0;
        --margin-end: calc(var(--sw-multiple-px) * 3);
        // проверить на кросс браузерность
        display: inline-flex;
    }
}
