.sw-section {
    @include props-margin;
    @include margin;

    &__content {
        @include props-padding;
        @include padding;
    }
}

.sw-section {
    --margin-bottom: 40px;
}

/////////

.sw-header-section {
    @include props-margin;
    @include margin;
}

.sw-header-section.sw-block {
    --margin-top: 20px;
    --margin-bottom: 48px;
    --padding-top: 32px;
}

/////////

.sw-section-sign {
    padding-left: calc(var(--sw-multiple-px) * 3);

    > sw-icon {
        float: left;
        margin-right: calc(var(--sw-multiple-px) * 8);

        + * {
            overflow: hidden;
        }
    }

    .sw-modal__content &,
    .sw-simple-page & {
        padding-left: 0;
        padding-bottom: calc(var(--sw-multiple-px) * 6);

        > sw-icon {
            float: left;
            margin-right: calc(var(--sw-multiple-px) * 5);
        }

        p {
            margin-top: 0;
            margin-bottom: 2px;
            font-weight: 500;

            + sw-link,
            + a {
                font-weight: 400;
            }
        }
    }
}
