.sw-form__footer {
    --padding-top: 0;
    --padding-bottom: 0;
    --background: initial;
    --box-shadow: initial;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    background: var(--background);
    box-shadow: var(--box-shadow);

    &::after,
    &::before {
        display: none;
    }
}

// implemented
.sw-form__footer {
    --padding-top: 20px;
    --padding-bottom: 20px;

    // &.swh-sticky {
        --background: var(--sw-background);
        --box-shadow: 0px -15px 15px -10px rgba(239, 240, 242, 0.4);
    // }
}
