.sw-footer {
    /*
    * @prop --padding-top: верхний внутренний отступ
    * @prop --padding-bottom: нижний внутренний отступ
    * @prop --background: фон подвала
    * @prop --color: цвет текста в подвале
    * @prop --color-link: цвет ссылок в подвале
    */
    --color-link: var(--sw-color-action);
    --padding-top: initial;
    --padding-bottom: initial;

    position: relative;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    background: var(--background);
    color: var(--color);

    a {
        color: var(--color-link);
    }
}

.sw-footer {
    --padding-top: 64px;
    --padding-bottom: 40px;
    --background: var(--sw-footer-background);
    --color: #CFD9E0;
    --color-link: #869FB2;
    color: var(--color-link);

    a {
        font-weight: 400;
        line-height: 20px;
        text-decoration: none;
    }

    hr {
        --border-color: #374957;
    }

    &__menu {
        --sw-grid-columns: 15;
        --sw-grid-padding: 0;
        margin-left: 102px;
        color: var(--color);

        .sw-list {
            --margin-top-list: 20px;
            --margin-bottom-list: 36px;
            --margin-bottom: 12px;

            sw-icon {
                --sw-icon-small-size: 16px;
                margin-right: calc(var(--sw-multiple-px) * 4);
                color: var(--color);
            }
        }
    }

    &__development {
        display: flex;
        align-items: center;
        font-size: 11px;
        line-height: 12px;
        color: var(--color-link);

        a {
            line-height: 0;
        }

        img {
            height: 32px;
            width: auto;
        }

        span {
            width: 160px;
            flex-shrink: 0;
            margin-right: 12px;
            opacity: .5;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sw-list_bullet .sw-item {
        --color: var(--color-link);
    }

    @media (max-width: 992px) {
        .sw-footer__footer {
            flex-direction: column;
            align-items: flex-start;

            hr {
                width: 100%;
            }
        }
        .sw-footer__development {
            flex-direction: row-reverse;

            img {
                height: 20px;
            }

            span {
                margin-right: 0;
                margin-left: 12px;
            }
        }
    }

    @media (max-width: 768px) {
        --padding-top: 48px;

        .sw-footer__menu {
            --sw-grid-columns: 12;
        }
    }

    @media (max-width: 576.98px) {
        padding-left: 20px;
        padding-right: 20px;

        .sw-footer__menu {
            margin-left: 0;
        }

        .sw-list_bullet {
            display: block;

            > .sw-item {
                --padding-start: 0;
                --margin-bottom: 12px;

                &:last-child {
                    --margin-bottom: 0;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}
