.sw-totop {
    display: flex;
    justify-content: flex-end;

    // position: absolute;
    // top: -28px;
    // right: 40px;
    position: sticky;
    bottom: 84px;
    // right: 40px;
    z-index: 150;
    pointer-events: none;
    height: 0;

    margin-right: 40px;

    sw-button {
        --height: 48px;
        --border-radius: 50%;
        width: 56px;
        pointer-events: all;
        margin-top: -28px;
    }

    sw-icon {
        margin-right: -4px;
        margin-left: -4px;
        font-size: 24px;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0){
        sw-icon {
            margin-left: unset;
            margin-right: unset;
            margin-inline-start: -4px;
            margin-inline-end: -4px;
        }
    }
}
