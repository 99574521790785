.sw-options-content {
    margin-top: 20px;
    max-height: 210px;
    overflow: auto;

    .sw-dropdown__body & {
        // margin-left: -16px;
        // margin-right: -16px;
        // margin-bottom: -20px;
        margin-top: 0;
        // overflow: visible;
    }
}
