.sw-dateplate {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    &__time {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        sw-icon {
            --sw-icon-small-size: 12px;
            --sw-icon-medium-size: 14px;
            margin-right: 6px;
        }

        &_small {
            font-size: 14px;
            line-height: 20px;

            sw-icon {
                margin-bottom: 2px;
            }
        }
    }

    // *:first-child {
    //     padding-bottom: 2px;
    // }

    .sw-block__content_main__left & {
        .sw-text-action {
            letter-spacing: 0;
            color: var(--sw-text-color);
        }
    }

    h5 &,
    h6 & {
        display: inline-block;
        padding-left: calc(var(--sw-multiple-px) * 3);
        font-weight: 400;
        vertical-align: middle;
    }
}
