sw-button.sw-button_nowrap {
    aside & {
        width: calc(100% - 52px);
    }
}

app-order-status-widget,
app-contract-status-widget {
    app-view-entity-transits {
        .sw-item:first-child:last-child {
            sw-button {
                display: block;
            }
        }
    }
}

app-input-options-chip {
    sw-chip {
        --height: 32px;
        --margin-start: 0;
        --margin-bottom: 8px;
        --padding-start: 16px;
        --padding-end: 16px;
        --border-radius: 24px;
        --font-size: 13px;
        --font-weight: 400;

        &.sw-chip_clear {
            background: var(--sw-background-medium);
        }

        &.sw-chip_solid {
            background: var(--sw-color-base);
            color: var(--sw-color-contrast);
        }
    }
}

app-order-ossig-widget {
    .sw-radio:last-child .sw-radio__label {
        margin-right: 0;
    }
}
