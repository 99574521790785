.sw-cckeditor {
    font-size: 14px;
    line-height: 20px;

    b, strong {
        font-weight: 500;
    }

    i, em {
        font-style: italic;
    }

    ol {
        margin: 0 0 12px;
        padding-left: 20px;

        li {
            margin: 0 0 8px;

            a {
                text-decoration: none;
                font-weight: 500;
                letter-spacing: 0.5px;
            }
        }
    }

    ul {
        margin: 0 0 12px;
        padding-left: 0;
        list-style: none;

        li {
            margin: 0 0 8px;

            a {
                text-decoration: none;
                font-weight: 500;
                letter-spacing: 0.5px;
            }
        }
    }

    p {
        margin: 0  0 12px;

        &:last-child {
            margin: 0;
        }
    }

    a {
        text-decoration: underline;
    }

    h3 {
        margin: 40px 0 20px;
    }

    h6 {
        margin: 0 0 16px;
    }

    table {
        td {
            border: 0;
            padding: 8px;

            p:last-child {
                margin: 0;
            }
        }

        &:not([border]) {
            td:first-child {
                padding-left: 0;
            }
            td:last-child {
                padding-right: 0;
            }
        }

        &[border] {
            border-color: var(--sw-border-color-dark);

            td {
                border: 1px solid var(--sw-border-color-dark);
            }
        }
    }
}
