.sw-referral-card {
    --color-card: var(--sw-text-color-secondary);
    --background: url('/assets/images/junior.jpg');

    &_junior {
        --color-card: var(--sw-text-color-secondary);
        --background: var(--sw-background-medium) url('/assets/images/junior.png');
    }
    &_middle {
        --color-card: var(--sw-color-secondary);
        --background: #EEFDFE url('/assets/images/middle.png');
    }
    &_senior {
        --color-card: var(--sw-color-success);
        --background: #F1F9ED url('/assets/images/senior.png');
    }
    &_king {
        --color-card: var(--sw-color-tertiary);
        --background: #F2F2FE url('/assets/images/king.png');
    }
    &_vip {
        --color-card: var(--sw-color-warning);
        --background: var(--sw-background-info-secondary) url('/assets/images/vip.png');
    }

    max-width: 452px;
    height: 237px;
    border-radius: 12px;
    padding: 24px 20px;
    margin-bottom: 24px;
    background: var(--background) no-repeat calc(100% + 20px) calc(100% - 4px);
    background-size: 250px 250px;

    .sw-h4 {
        line-height: 28px;
        font-weight: 700;
    }

    p {
        margin-top: 0;
    }

    &__percent {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
    }

    &__color {
        color: var(--color-card);
    }

    .sw-percentage-bar {
        border-radius: 4px;

        &__part {
            border-radius: 4px;
            background: var(--color-card);
        }
    }
}

.sw-referral-link {
    max-width: 452px;
    height: 237px;
    border-radius: 12px;
    padding: 24px 20px;
    margin-bottom: 24px;
    background:
        url('/assets/images/referral-link-bg.svg') 261px 100% no-repeat, linear-gradient(180deg, #072240 0%, #08171e 100%);
    color: var(--sw-color-white);

    h5 {
        margin-bottom: 8px;
    }

    p {
        margin: 0 0 32px;
    }

    .sw-control {
        --control-width: 100%;
        margin-bottom: 16px;
        color: var(--sw-text-color);
    }

    + .sw-block {
        --padding-top: 24px;
        --padding-start: 20px;
        --padding-end: 20px;
        border-radius: 12px;
    }
}
