.sw-empty {
    @include props-padding;
    @include props-colors;
    @include props-font();
    @include padding;
    --margin-top: 0;
    --margin-bottom: 0;

    margin-top: var(--margin-top);
    margin-bottom: var(--margin-bottom);
    background: var(--background);

    p {
        @include font();
        color: var(--color);
    }

    text-align: center;
}

// implemented
.sw-empty {
    --margin-top: 120px;
    --color: var(--sw-text-color-secondary);
    max-width: 548px;
    margin-left: auto;
    margin-right: auto;

    h5 {
        margin-bottom: 8px;
    }

    p {
    }

    sw-button {
        margin-top: 24px;
    }

    &_notifications {
        --margin-top: 0;
        --padding-top: calc(var(--sw-multiple-px) * 4);
        --padding-bottom: calc(var(--sw-multiple-px) * 6);
        --background: var(--sw-background-medium);
        --font-size: 12px;
        --line-height: 16px;
    }
}
