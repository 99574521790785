.sw-file {
    --max-height: none;
    --min-height: none;

    display: inline-flex;

    // закоменчено с целью позиционирования баджика на уровне текста, при wrap списка ( или длинного имени файла и его переносе на новою строку)
    // возможно не на маке будет не центрироваться по тексту. Необходимо обратить внимание
    // align-items: center;

    sw-badge {
        --margin-start: calc(var(--sw-multiple-px) * 2);
        --margin-end: calc(var(--sw-multiple-px) * 2);
        margin-top: 2px;
        border-radius: 2px;
        text-transform: uppercase;
        line-height: 16px;

        .sw-control__field &,
        .sw-list &,
        .sw-block__content_main &,
        dd &,
        aside &,
        sw-col &,
        sw-accordion & {
            margin-left: 0;
        }

    }

    .swh-ellipsis {
        max-width: var(--width, initial);
        vertical-align: bottom;
    }

    .sw-list {
        flex-wrap: wrap;
    }

    .sw-list .sw-item:last-child & {
        margin-bottom: calc(var(--sw-multiple-px) * 10);
    }

    & + sw-button {
        margin-top: -8px;

        sw-icon {
            margin-left: -8px;
            margin-right: -8px;
            font-size: 20px;
            color: var(--sw-text-color-secondary);
        }
        @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
            sw-icon {
                margin-left: unset;
                margin-right: unset;
                -webkit-margin-start:  -8px;
                margin-inline-start:  -8px;
                -webkit-margin-end: -8px;
                margin-inline-end: -8px;
            }
        }
    }

    aside & + sw-button {
        float: right;
    }
}
