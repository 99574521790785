.leaflet-popup {
    /*
    * @prop --min-height: минимальная высота тултипа
    * @prop --width: ширина тултипа
    * @prop --border-radius: радиус скругления
    * @prop --border-width: толщина границы
    * @prop --border-style: стиль границы
    * @prop --border-color: цвет границы
    * @prop --background: фон тултипа
    * @prop --box-shadow: тень тултипа
    *
    * @prop --padding-start: внутренний начальный отступ тултипа
    * @prop --padding-end: внутренний конечный отступ тултипа
    * @prop --padding-top: внутренний верхний отступ тултипа
    * @prop --padding-bottom: внутренний нижний отступ тултипа
    *
    * @prop --width-arrow: ширина стрелочки
    * @prop --height-arrow: высота стрелочки
    *
    * @prop --height-marker: высота маркера
    *
    * @prop --width-close: ширина кнопки закрытия
    * @prop --height-close: высота кнопки закрытия
    * @prop --top-close: позиционирование сверху кнопки закрытия
    * @prop --right-close: позиционирование справа кнопки закрытия
    * @prop --background-close: фон кнопки закрытия
    * @prop --background-hover-close: фон при наведении кнопки закрытия
    * @prop --color-close: цвет кнопки закрытия
    * @prop --color-hover-close: цвет при наведении кнопки закрытия
    */
    --min-height: initial;
    --width: initial;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --background: initial;
    --box-shadow: initial;

    --color-close: initial;
    --background-close: initial;
    --color-hover-close: var(--color-close);
    --background-hover-close: var(--background-close);
	position: absolute;
	// text-align: center;
	margin-bottom: calc(var(--height-arrow) + 3px);

    &-content-wrapper {
        overflow-y: auto;
        box-sizing: border-box;
        min-height: var(--min-height);
        max-height: var(--max-height);
        width: var(--width);
        border-radius: var(--border-radius);
        border: var(--border-width) var(--border-style) var(--border-color);
        background: var(--background);
        box-shadow: var(--box-shadow);
        padding: var(--padding-top) var(--padding-end) var(--padding-bottom) var(--padding-start);
    }

    &-content {
        width: 100% !important;
    }

    &-tip {
        border-top: var(--height-arrow) solid var(--background);
        border-left: calc(var(--width-arrow) / 2) solid transparent;
        border-right: calc(var(--width-arrow) / 2) solid transparent;

        &-container {
            position: absolute;
            left: 50%;
            overflow: hidden;
            width: var(--width-arrow);
            height: var(--height-arrow);
            margin-left: calc(var(--width-arrow) / -2);
            pointer-events: none;
        }
    }

    &-scrolled {
        overflow: auto;
        border-bottom: 1px solid red;
        border-top: 1px solid red;
    }

    &-close-button {
        position: absolute;
        z-index: 10;
        top: var(--top-close);
        right: var(--right-close);
        width: var(--width-close);
        height: var(--height-close);
        padding: 0;
        border: none;
        background: var(--background-close);
        line-height: var(--height-close);
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        text-decoration: none;
        color: var(--color-close);

        :hover {
            background: var(--background-hover-close);
            color: var(--color-hover-close);
        }
    }
}

.leaflet-popup {
    --min-height: 100px;
    --max-height: 190px;
    --width: 357px;
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 24px;
    --border-radius: var(--sw-border-radius);
    --background: var(--sw-color-white);

    --width-arrow: 24px;
    --height-arrow: 8px;

    // --height-marker: 47px;

    --top-close: 8px;
    --right-close: 8px;
    --width-close: 20px;
    --height-close: 20px;
    --background-close: var(--sw-color-white);
    --background-hover-close: var(--sw-background-medium);
    --color-close: var(--sw-color-action);

    .sw-list_bullet {
        flex-wrap: wrap;
    }

    .sw-list_gallery {
        --margin-bottom-list: 16px;

        > .sw-item {
            --margin-end: 8px;
        }

        .sw-figure {
            --height: 56px;
            --width: 56px;
            --border-radius: var(--sw-border-radius);
            --border-width: 0;
        }
    }
}
