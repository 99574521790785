.sw-dummy-page {
    position: relative;
    // z-index: 1101;
    background: var(--sw-background);
    height: 100%;

    .sw-modal & {
        position: static;
    }

    &__content {
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 744px;
        height: 100%;
        margin: auto;
        text-align: center;
    }

    h2 {
        margin: 20px 0 16px;
    }

    p {
        font-size: 16px;
        line-height: 24px;
    }

    @media (max-width: 768px) {
        &__content {
            max-width: 490px;
        }
    }

    @media (max-width: 576px) {
        &__content {
            max-width: 392px;
        }
    }
}
