.sw-switcher {
    /**
     * @prop --color: цвет текста чекбокса
     * @prop --color-checked: цвет текста при установленном флажке
     * @prop --color-disabled: цвет текста при отключенном флажке
     */
    @include props-font;

    --color: inherit;
    --color-checked: var(--color);
    --color-disabled: var(--color);

    sw-toggle {
        vertical-align: middle;
    }

    &__label {
        @include font;
        vertical-align: middle;
        color: var(--color);

        .sw-toggle--checked + & {
            color: var(--color-checked);
        }

        .sw-toggle--disabled + & {
            color: var(--color-disabled);
        }
    }
}

.sw-switcher {
    --color: var(--sw-text-color);
    --color-disabled: var(--sw-text-color-secondary);

    &__label {
        --font-weight: 700;
        --font-size: 16px;
        --line-height: 24px;
        margin-left: 8px;

        th & {
            --font-weight: 500;
            --font-size: 13px;
            --line-height: 20px;
            --color: var(--sw-text-color-secondary);
        }
    }
}
