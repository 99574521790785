.sw-contact {
    @include props-margin;
    @include margin;

    display: block;
    font-size: 13px;
    line-height: 20px;
    color: inherit;

    a {
        color: currentColor;
    }
}

// implemented
.sw-contact {
    & + & {
        --margin-top: calc(var(--sw-margin) * 2);
    }
}
