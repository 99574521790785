.sw-avatar {
    @include props-width-height;
    @include props-colors;
    @include props-font;

    --border-radius: initial;
    --color-hover: var(--color);

    @include width-height;
    @include colors;
    @include font;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);

    &:hover {
        color: var(--color-hover);
    }

    &_small {
        width: calc(var(--width) / 2);
        height: calc(var(--width) / 2)
    }
}

.sw-avatar {
    --width: 64px;
    --height: 64px;
    --background: var(--sw-color-primary);
    --border-radius: 50%;
    --color: var(--sw-color-white);
    --font-size: 18px;
    letter-spacing: 0.5px;

    &_small {
        --font-size: 12px;
        --font-weight: 500;
    }

    &_userpic {
        @extend .sw-avatar;

        --width: 48px;
        --height: 48px;
        --background: var(--sw-background);
        --color: var(--sw-text-color-secondary);

        float: left;
        margin-right: calc(var(--sw-multiple-px) * 4);
        border: 1px solid var(--sw-border-color);
    }
}
