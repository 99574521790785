// все типы панелей инструментов с переопределяющими переменными компонента сетки
.sw-toolbar {
    &_table {
        --padding-top: 32px;
        --padding-bottom: 20px;

        app-bid & {
            --padding-top: 0;
        }

        flex-wrap: wrap;

        .sw-btn-group {
            margin: 0 32px 0 16px;

            &:last-child {
                margin-right: 0;
            }
        }

        .sw-control:not(app-sort .sw-control) {
            --height: 30px;
            --margin-bottom: 0;

            .sw-form & {
                --margin-bottom: 0;
            }

            sw-button {
                --height: 30px;
            }
        }

        app-input-default.sw-control-search {
            width: calc(var(--max-width-container) * 0.65);
        }

        // app-select .sw-control,
        app-sort .sw-control {
            --height: 32px;
        }

        .sw-form & {
            --margin-top: var(--sw-margin);
            --padding-top: 14px;
            --padding-bottom: 12px;
        }

        @media (max-width: 992px) {
            &::part(start),
            &::part(end) {
                width: 100%;
                flex-shrink: 0;
            }
            &::part(start) {
                margin-bottom: 32px;
            }

            app-select .sw-control,
            app-sort .sw-control {
                --height: 38px;
                max-width: 100%;
            }

            .sw-dropdown_settings-filter {
                sw-button.sw-dropdown__trigger {
                    height: 40px;

                    sw-icon[slot='end'] {
                        display: none;
                    }
                    sw-icon[slot='start'] {
                        --sw-coef: 0 !important;
                    }
                }
            }

            .sw-list-page__body & {
                .sw-row-elements[slot='end'] {
                    flex-direction: row-reverse;
                    justify-content: flex-end;

                    > * {
                        margin-left: var(--margin-end);

                        &:last-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    &_searchable {
        --width-other-control: 184px;

        &::part(start) {
            width: 100%;
        }

        app-input-default.sw-control-search {
            width: calc(100% - var(--width-other-control) - var(--sw-margin));
        }

        app-select .sw-control,
        app-sort .sw-control {
            --height: 36px;
            width: var(--width-other-control);
        }
    }

    &_contacts {
        --margin-bottom: 2px;

        h6 {
            line-height: 36px;
        }
    }

    &_header-dispatcher {
        --padding-top: 10px;
        --padding-bottom: 10px;
        --padding-start: 16px;
        --padding-end: 16px;
        --border-width: 0 0 1px 0;
        --border-style: solid;
        --border-color: var(--sw-border-color);

        app-input-default {
            width: 330px;
        }

        .sw-tab-status {
            sw-badge {
                --height-xs: 6px;
                --margin-start: 0;
                --margin-end: 8px;
                border-radius: 2px;
                border-bottom: none;
                vertical-align: middle;
            }
        }
    }

    @media (max-width: 992px) {
        &_searchable {
            .sw-control {
                --height: 36px;
            }
        }
    }

    @media (max-width: 576px) {
        sw-accordion &_searchable {
            --width-other-control: 100%;

            .sw-row-elements {
                display: block;
                margin: 0 0 8px;
            }

            app-select .sw-control {
                max-width: 100%;
            }
        }
    }
}

sw-toolbar {
    & + .sw-list_bullet {
        --margin-bottom-list: calc(var(--sw-multiple-px) * 4 + 2px);
    }

    app-select .sw-control,
    app-sort .sw-control,
    app-select-date .sw-control_select-date {
        --border-width: 0;
        --background: var(--sw-color-info-tint);
        --font-size: 14px;
        --color: var(--sw-color-action);

        .sw-control__field-group {
            &__end {
                button {
                    border: none;
                }
            }

            sw-icon {
                color: var(--color);
            }
        }
    }

    app-select .sw-control,
    app-sort .sw-control {
        max-width: 205px;

        // &__field {
        //     flex-shrink: 0;
        // }

        app-reservations & {
            max-width: 100%;
        }
    }

    // .sw-select {
    //     --background: var(--sw-color-info-tint);
    //     --padding-end: 0;
    //     color: var(--color);

    //     &__trigger {
    //         white-space: nowrap;
    //         text-overflow: ellipsis;
    //         overflow: hidden;
    //         border-radius: var(--border-radius);

    //         app-reservations & {
    //             text-overflow: initial;
    //             overflow: visible;
    //         }

    //         sw-icon {
    //             --size-icon: 20px;
    //             flex-shrink: 0;
    //             color: var(--color);

    //             &:first-child {
    //                 margin-right: 4px;
    //             }
    //         }
    //     }
    // }

    @media (max-width: 992px) {
        app-select .sw-control,
        app-sort .sw-control,
        app-select-date .sw-control_select-date,
        .sw-dropdown_settings-filter sw-button.sw-dropdown__trigger {
            --border-width: 1px;
            --border-style: solid;
            --border-color: var(--sw-border-color-dark);
            --background: var(--sw-background-medium);
        }

        app-select .sw-control,
        app-sort .sw-control {
            .sw-control__field-group__end:not(.sw-toolbar_searchable
                    .sw-control__field-group__end) {
                display: none;
            }
        }
    }
}
