.sw-counter {
    &_line {
        display: flex;
        align-items: center;

        .sw-counter__item {
            border-radius: 1px;
            height: 12px;
            width: 2px;
            margin: 0 1px;
            background: var(--sw-color-base, var(--sw-placeholder-color));
        }

        .sw-counter__total {
            font-size: 12px;
            line-height: 12px;

            &:first-child {
                margin-right: 8px;
            }
            &:last-child {
                margin-left: 8px;
            }
        }
    }
}
