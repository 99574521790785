.sw-dispatcher-map {
    position: relative;
    width: 100%;
    height: 100%;

    .leaflet-popup {
        --min-height: 120px;
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-top: 16px;
        --padding-bottom: 16px;

        &-content-wrapper {
            max-height: 240px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .sw-list_underline {
            & > .sw-item:last-child {
                --padding-bottom: 0;
                --margin-bottom: 0;
                --border-width: 0;
            }
        }

        .sw-list_trip {
            --margin-start-list: 0;
        }
    }
}

.sw-dispatcher-on-map-layer {
    position: absolute;
    z-index: 40;
    left: 8px;
    right: 51px;
    top: 8px;
    bottom: 8px;
    pointer-events: none;
}

.sw-dispatcher-map-legend {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    float: left;
    pointer-events: all;

    sw-toolbar {
        --padding-top: 12px;
        --padding-bottom: 4px;
        --padding-start: 16px;
        --padding-end: 16px;
    }

    .sw-list {
        --padding-top-list: 16px;
        --padding-bottom-list: 20px;
        --padding-start-list: 16px;
        --padding-end-list: 16px;
        --padding-bottom: 12px;

        .sw-item {
            display: flex;
            align-items: center;
        }

        sw-icon {
            margin-right: 12px;
        }
    }
}
