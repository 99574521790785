.swh-block {
    display: block;
}

.swh-inline {
    display: inline;
}

.swh-inline-block {
    display: inline-block;
}

.swh-display-table {
    display: table;
}

.swh-flex {
    display: flex;
}

.swh-inline-flex {
    display: inline-flex;
}

.swh-display-grid {
    display: grid;
}

.swh-hide {
    display: none !important;
}

.swh-hide-up {
    display: none !important;
}

.swh-hide-down {
    display: none !important;
}

.swh-hidden {
    visibility: hidden;
}

@media screen and (min-width: 576.98px) {
    .swh-hide-sm-up {
        display: none !important;
    }
}
@media screen and (max-width: 576px) {
    .swh-hide-sm-down {
        display: none !important;
    }
}
@media screen and (min-width: 768.98px) {
    .swh-hide-md-up {
        display: none !important;
    }
}
@media screen and (max-width: 768px) {
    .swh-hide-md-down {
        display: none !important;
    }
}
@media screen and (min-width: 992.98px) {
    .swh-hide-lg-up {
        display: none !important;
    }
}
@media screen and (max-width: 992px) {
    .swh-hide-lg-down {
        display: none !important;
    }
}
@media screen and (min-width: 1024.98px) {
    .swh-hide-xl-up {
        display: none !important;
    }
}
@media screen and (max-width: 1024px) {
    .swh-hide-xl-down {
        display: none !important;
    }
}
@media screen and (min-width: 1280.98px) {
    .swh-hide-xxl-up {
        display: none !important;
    }
}
@media screen and (max-width: 1280px) {
    .swh-hide-xxl-down {
        display: none !important;
    }
}

@media print {
    .swh-hide-print {
        display: none !important;
    }
}

// devices
@media (min-device-width: 576.98px) {
    .swh-hide-device-sm-up {
        display: none !important;
    }
}
@media (max-device-width: 576px) {
    .swh-hide-device-sm-down {
        display: none !important;
    }
}
@media (min-device-width: 768.98px) {
    .swh-hide-device-md-up {
        display: none !important;
    }
}
@media (max-device-width: 768px) {
    .swh-hide-device-md-down {
        display: none !important;
    }
}
@media (min-device-width: 992.98px) {
    .swh-hide-device-lg-up {
        display: none !important;
    }
}
@media (max-device-width: 992px) {
    .swh-hide-device-lg-down {
        display: none !important;
    }
}
@media (min-device-width: 1024.98px) {
    .swh-hide-device-xl-up {
        display: none !important;
    }
}
@media (max-device-width: 1024px) {
    .swh-hide-device-xl-down {
        display: none !important;
    }
}
@media (min-device-width: 1280.98px) {
    .swh-hide-device-xxl-up {
        display: none !important;
    }
}
@media (max-device-width: 1280px) {
    .swh-hide-device-xxl-down {
        display: none !important;
    }
}

// media level 4 (weak support on 04.2023)
/* смартфоны, устройства с сенсорным экраном, с которым работают пальцами */
@media (hover: none) and (pointer: coarse) {
    .swh-hide-touch-device {
        display: none !important;
    }
}
/* мышь, тачпад */
@media (hover: hover) and (pointer: fine) {
    .swh-hide-cursor-device {
        display: none !important;
    }
}
/* устройства с сенсорным экраном, с которым работают стилусом */
@media (hover: none) and (pointer: fine) {
    /* ... */
}
