.sw-percentage-bar {
    @include props-height;
    --background: initial;

    @include height;
    background: var(--background);

    &__part {
        --opacity: 1;
        --min-width: 0;
        display: block;
        float: left;
        height: var(--height);
        opacity: var(--opacity);
        min-width: var(--min-width);

        &.sw-color {
            background: var(--sw-color-base);
        }
    }
}

// implemented 
.sw-percentage-bar {
    --height: 8px;
    --background: var(--sw-background-dark);
    position: relative;

    .sw-block_secondary & {
        --background: var(--sw-color-white);
    }

    &__part {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;

        .process & {
            background: var(--sw-color-success);
        }
        .defuse & {
            background: var(--sw-color-tertiary);
        }
        .utilize & {
            background: var(--sw-color-warning);
        }
        .store & {
            background: var(--sw-color-action);
        }
    }

    &_mini {
        @extend .sw-percentage-bar;
        --height: 4px;
        margin-top: 4px;
    }
}

.sw-percentages {
    display: flex;
    justify-content: space-between;

    &__item {
        position: relative;
        width: 100%;
        padding-left: 14px;
        margin-left: 14px;

        &--hidden {
            display: none;
        }

        &:first-child,
        &--hidden:first-child + & {
            padding-left: 0;
            margin-left: 0;
        }

        .sw-percentage-bar {
            --height: 4px;
            margin-top: 8px;
        }

        &:hover {
            .sw-tooltip-static {
                display: block;
                top: 38px;
            }
        }
    }

    &_column {
        width: 144px;

        .sw-percentages__item {
            padding: 0;
            margin-left: 0;
        }
    }
}
