.sw-filter,
.sw-filter-applied-wrap {
    .sw-tag {
        --height: 24px;
        --border-radius: 12px;
        --padding-start: var(--sw-padding);
        margin-right: var(--sw-margin);
        line-height: 2;

        > span {
            @extend .swh-ellipsis;
            max-width: 350px;
        }

        button {
            padding: 0;
            margin-left: 8px;
        }
    }
}
