.sw-faq__example {
    --padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --background: transparent;
    --margin-bottom: -24px;
    --margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;

    > div {
        margin-bottom: 24px;
    }

    sw-button {
        margin-left: -16px;
    }

    @media (max-width: 576px) {
        --padding-top: 16px;
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-bottom: 16px;
        --background: var(--sw-background);
        --margin-bottom: 0;

        display: block;

        > div {
            margin-bottom: 0;
        }

        sw-button {
            --padding-end: 16px !important;
            margin-top: 24px;
        }
    }
}

.sw-block_faq {
    @extend .sw-block_outline;
    --padding-top: 36px;

    @media (max-width: 576px) {
        --padding-start: 0;
        --padding-end: 0;
        --border-width: 0;
    }
}
