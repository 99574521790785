.swh-pointer {
    cursor: pointer;
}

.swh-events-none {
    pointer-events: none;
}

.swh-opacity {
    @for $i from 1 through 9 {
        &_#{$i} {
            opacity: calc(#{$i} / 10);
        }
    }
}

.swh-sticky {
    position: sticky;
    top: var(--sw-sticky-top);
    bottom: var(--sw-sticky-bottom);
    left: var(--sw-sticky-start);
    right: var(--sw-sticky-end);
    z-index: var(--sw-sticky-z, 150);
    // background: var(--sw-background-color);
}

.swh-popper-z-index {
    z-index: 1000;
}

.swh-bg {
    --background: var(--sw-bg-base);
    background: var(--background);
}

.swh-relative {
    position: relative;
}

.swh-static {
    position: static;
}

.swh-overhide {
    overflow: hidden;
}

.swh-scroll {
    overflow: auto;
}
.swh-scroll-x {
    overflow-x: auto;
    overflow-y: hidden;
}
.swh-scroll-y {
    overflow-x: hidden;
    overflow-y: auto;
}
// for webkit
.swh-scroll_hidden::-webkit-scrollbar {
    display: none;
}
.swh-scroll_hidden-x::-webkit-scrollbar:horizontal {
    display: none;
}
.swh-scroll_hidden-y::-webkit-scrollbar:vertical {
    display: none;
}

.swh-scroll_hidden {
    -ms-overflow-style: none; /* IE и Edge */
    scrollbar-width: none; /* Firefox */
}
.swh-scroll_hidden-x {
    /* нет решения для мозиллы */
}
.swh-scroll_hidden-y {
    margin-right: -14px;
    padding-right: 14px;
}

.swh-ellipsis {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.swh-underconstruction {
    opacity: 0.5;
    cursor: not-allowed !important;

    *,
    sw-button::part(native) {
        pointer-events: none;
        cursor: not-allowed !important;
    }
}

.swh-stop-scroll {
    position: fixed;
    left: 0;
    right: 0;
    padding-right: 4px; // for scroll
}

.swh-word-break {
    word-break: break-all;
}
