.sw-trip-ticket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    &__line {
        margin: 0 30px;
        width: 100%;
        text-align: center;

        .sw-list {
            display: inline-flex;

            &.sw-list_bullet > .sw-item {
                white-space: nowrap;
            }
        }

        sw-trip-line {
            --margin-bottom: 24px;
            --border-width: 2px;
            --border-style: solid;
            --size-point: 12px;
            --color: var(--sw-border-color);

            &::part(start-point) {
                box-sizing: border-box;
                background: transparent;
                border: 2px solid var(--color);
            }

            &::part(line) {
                margin: 0 6px;
            }
        }
    }

    @media (max-width: 1024px) and (min-width: 992.98px) {
        &__line {
            margin: 0 8px;
        }
    }
}
