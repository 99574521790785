body {
    background: var(--sw-background);
}

body.sw-backdrop-no-scroll {
    overflow: hidden;
}

.sw-color-primary {
    --sw-color-base: var(--sw-color-primary) !important;
    --sw-color-base-rgb: var(--sw-color-primary-rgb) !important;
    --sw-color-contrast: var(--sw-color-primary-contrast) !important;
    --sw-color-contrast-rgb: var(--sw-color-primary-contrast-rgb) !important;
    --sw-color-shade: var(--sw-color-primary-shade) !important;
    --sw-color-shade-rgb: var(--sw-color-primary-shade-grb) !important;
    --sw-color-tint: var(--sw-color-primary-tint) !important;
}

.sw-color-secondary {
    --sw-color-base: var(--sw-color-secondary) !important;
    --sw-color-base-rgb: var(--sw-color-secondary-rgb) !important;
    --sw-color-contrast: var(--sw-color-secondary-contrast) !important;
    --sw-color-contrast-rgb: var(--sw-color-secondary-contrast-rgb) !important;
    --sw-color-shade: var(--sw-color-secondary-shade) !important;
    --sw-color-shade-rgb: var(--sw-color-secondary-shade-rgb) !important;
    --sw-color-tint: var(--sw-color-secondary-tint) !important;
}

.sw-color-tertiary {
    --sw-color-base: var(--sw-color-tertiary) !important;
    --sw-color-base-rgb: var(--sw-color-tertiary-rgb) !important;
    --sw-color-contrast: var(--sw-color-tertiary-contrast) !important;
    --sw-color-contrast-rgb: var(--sw-color-tertiary-contrast-rgb) !important;
    --sw-color-shade: var(--sw-color-tertiary-shade) !important;
    --sw-color-shade-rgb: var(--sw-color-tertiary-shade-grb) !important;
    --sw-color-tint: var(--sw-color-tertiary-tint) !important;
}

.sw-color-action {
    --sw-color-base: var(--sw-color-action) !important;
    --sw-color-base-rgb: var(--sw-color-action-rgb) !important;
    --sw-color-contrast: var(--sw-color-action-contrast) !important;
    --sw-color-contrast-rgb: var(--sw-color-action-contrast-rgb) !important;
    --sw-color-shade: var(--sw-color-action-shade) !important;
    --sw-color-shade-grb: var(--sw-color-action-shade-rgb) !important;
    --sw-color-tint: var(--sw-color-action-tint) !important;
}

.sw-color-info {
    --sw-color-base: var(--sw-color-info) !important;
    --sw-color-base-rgb: var(--sw-color-info-rgb) !important;
    --sw-color-contrast: var(--sw-color-info-contrast) !important;
    --sw-color-contrast-rgb: var(--sw-color-info-contrast-rgb) !important;
    --sw-color-shade: var(--sw-color-info-shade) !important;
    --sw-color-shade-grb: var(--sw-color-info-shade-rgb) !important;
    --sw-color-tint: var(--sw-color-info-tint) !important;
}

.sw-color-success {
    --sw-color-base: var(--sw-color-success) !important;
    --sw-color-base-rgb: var(--sw-color-success-rgb) !important;
    --sw-color-contrast: var(--sw-color-success-contrast) !important;
    --sw-color-contrast-rgb: var(--sw-color-success-contrast-rgb) !important;
    --sw-color-shade: var(--sw-color-success-shade) !important;
    --sw-color-shade-rgb: var(--sw-color-success-shade-rgb) !important;
    --sw-color-tint: var(--sw-color-success-tint) !important;
}

.sw-color-warning {
    --sw-color-base: var(--sw-color-warning) !important;
    --sw-color-base-rgb: var(--sw-color-warning-rgb) !important;
    --sw-color-contrast: var(--sw-color-warning-contrast) !important;
    --sw-color-contrast-rgb: var(--sw-color-warning-contrast-rgb) !important;
    --sw-color-shade: var(--sw-color-warning-shade) !important;
    --sw-color-shade-rgb: var(--sw-color-warning-shade-rgb) !important;
    --sw-color-tint: var(--sw-color-warning-tint) !important;
}

.sw-color-danger {
    --sw-color-base: var(--sw-color-danger) !important;
    --sw-color-base-rgb: var(--sw-color-danger-rgb) !important;
    --sw-color-contrast: var(--sw-color-danger-contrast) !important;
    --sw-color-contrast-rgb: var(--sw-color-danger-contrast-rgb) !important;
    --sw-color-shade: var(--sw-color-danger-shade) !important;
    --sw-color-shade-rgb: var(--sw-color-danger-shade-rgb) !important;
    --sw-color-tint: var(--sw-color-danger-tint) !important;
}

.sw-color-light {
    --sw-color-base: var(--sw-color-light, #f4f5f8) !important;
    --sw-color-base-rgb: var(--sw-color-light-rgb, 244, 245, 248) !important;
    --sw-color-contrast: var(--sw-color-light-contrast, #000) !important;
    --sw-color-contrast-rgb: var(--sw-color-light-contrast-rgb, 0, 0, 0) !important;
    --sw-color-shade: var(--sw-color-light-shade, #d7d8da) !important;
    --sw-color-shade-rgb: var(--sw-color-light-shade-rgb, 215, 216, 218) !important;
    --sw-color-tint: var(--sw-color-light-tint, #f5f6f9) !important;
}

.sw-color-medium {
    --sw-color-base: var(--sw-color-medium, #92949c) !important;
    --sw-color-base-rgb: var(--sw-color-medium-rgb, 146, 148, 156) !important;
    --sw-color-contrast: var(--sw-color-medium-contrast, #fff) !important;
    --sw-color-contrast-rgb: var(--sw-color-medium-contrast-rgb, 255, 255, 255) !important;
    --sw-color-shade: var(--sw-color-medium-shade, #808289) !important;
    --sw-color-shade-rgb: var(--sw-color-medium-shade-rgb, 128, 130, 137) !important;
    --sw-color-tint: var(--sw-color-medium-tint, #9d9fa6) !important;
}

.sw-color-dark {
    --sw-color-base: var(--sw-color-dark, #111214) !important;
    --sw-color-base-rgb: var(--sw-color-dark-rgb, 17, 18, 20) !important;
    --sw-color-contrast: var(--sw-color-dark-contrast, #fff) !important;
    --sw-color-contrast-rgb: var(--sw-color-dark-contrast-rgb, 255, 255, 255) !important;
    --sw-color-shade: var(--sw-color-dark-shade, #0f1012) !important;
    --sw-color-shade-rgb: var(--sw-color-dark-shade-rgb, 15, 16, 18) !important;
    --sw-color-tint: var(--sw-color-dark-tint, #292a2c) !important;
}

.sw-bg-base {
    --sw-bg-base: var(--sw-background) !important;
    --sw-bg-base-rgb: var(--sw-background-rgb) !important;
}

.sw-bg-light {
    --sw-bg-base: var(--sw-background-light, var(--sw-background)) !important;
    --sw-bg-base-rgb: var(--sw-background-light-rgb, var(--sw-background-rgb)) !important;
}

.sw-bg-medium {
    --sw-bg-base: var(--sw-background-medium) !important;
    --sw-bg-base-rgb: var(--sw-background-medium-rgb) !important;
}

.sw-bg-dark {
    --sw-bg-base: var(--sw-background-dark) !important;
    --sw-bg-base-rgb: var(--sw-background-dark-rgb) !important;
}

.sw-bg-info {
    --sw-bg-base: var(--sw-background-info) !important;
    --sw-bg-base-rgb: var(--sw-background-info-rgb) !important;
}

@supports ( padding-top: constant(safe-area-inset-top) ){
    html {
        --sw-safe-area-top: constant(safe-area-inset-top);
        --sw-safe-area-bottom: constant(safe-area-inset-bottom);
        --sw-safe-area-left: constant(safe-area-inset-left);
        --sw-safe-area-right: constant(safe-area-inset-right);
    }
}
@supports ( padding-top: env(safe-area-inset-top) ){
    html {
        --sw-safe-area-top: env(safe-area-inset-top);
        --sw-safe-area-bottom: env(safe-area-inset-bottom);
        --sw-safe-area-left: env(safe-area-inset-left);
        --sw-safe-area-right: env(safe-area-inset-right);
    }
}

input::-webkit-date-and-time-value {
    text-align: start;
}
