.sw-vehicle-number {
    /**
    *
    **/
    @include props-block-model;
    @include props-width;
    @include props-height;
    @include props-colors;

    @include block-model;
    @include width;
    @include height;
    @include colors;

    display: inline-flex;
    align-items: flex-end;
    font-family: 'RoadNumbers';
    width: auto;

    &_rus {
        .sw-vehicle-number__part {
            @for $i from 0 through 3 {
                &_#{$i} {
                    @include props-margin;
                    @include props-font;

                    @include margin;
                    @include font;
                }
            }
        }
    }
}

.sw-vehicle-number {
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--sw-color-action-tint);
    --border-radius: var(--sw-border-radius);
    --background: var(--sw-background-info);
    --box-shadow: 0px 0.272727px 1.63636px rgba(0, 0, 0, 0.2);
    --color: var(--sw-color-action);
    outline: 1px solid var(--sw-color-white);

    &--disabled {
        --border-color: var(--sw-border-color);
        --background: var(--sw-background-medium);
        --color: var(--sw-text-color-secondary);
        // --width: auto;
    }

    &_rus {
        // --width: 114px;
        --height: 24px;

        .sw-vehicle-number__part {
            &_0 {
                --margin-start: 4px;
                --margin-end: 4px;
                --font-size: 19px;
                --line-height: 15px;
                width: 11px;
            }
            &_1 {
                --margin-end: 4px;
                --font-size: 22px;
                --line-height: 17px;
                letter-spacing: 3px;
                width: 37px;
            }
            &_2 {
                --margin-end: 4px;
                --font-size: 19px;
                --line-height: 15px;
                letter-spacing: 3px;
                width: 24px;
            }
            &_3 {
                --margin-end: 2px;
                --margin-start: 3px;
                --font-size: 11px;
                --line-height: 17px;
                position: relative;
                width: 24px;
                height: 100%;
                letter-spacing: 1.5px;

                &::before {
                    position: absolute;
                    top: 1px;
                    left: -3px;
                    display: block;
                    content: '';
                    height: 20px;
                    width: 1px;
                    background: var(--color);
                }

                &::after {
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: 2px;
                    content: 'RUS';
                    background: url('/assets/images/flag-ru.svg') no-repeat 100% 50%;
                    background-size: contain;
                    font-family: 'PT Sans Caption', sans-serif;
                    font-weight: 700;
                    font-size: 4.91px;
                    line-height: 6.35px;
                    letter-spacing: 0;
                    color: var(--sw-color-action-tint);
                }
            }

            @media only screen and ( -webkit-min-device-pixel-ratio: 2 ){
                &_0 {
                    --line-height: 10px;
                }
                &_1 {
                    --line-height: 12px;
                }
                &_2 {
                    --line-height: 10px;
                }
            }
        }
        .sw-vehicle-number__noparts {
            margin: 0 10px;
            font-family: 'PT Sans Caption', sans-serif;
            font-size: 6px;
            line-height: 22px;
            white-space: nowrap;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }
        &.sw-vehicle-number--disabled {
            .sw-vehicle-number__part_3 {
                &::before {
                    background: var(--sw-border-color);
                }
                &::after {
                    background-image: url('/assets/images/flag-ru-grey.svg');
                }
            }
        }
    }
}

.sw-control_vehicle {

    .sw-control__field {
        --margin-bottom: 2px;
        --height: auto;
        flex-shrink: 0;
        padding: 0;

        &-group {
            align-items: flex-end;
            width: 205px;
        }

        &__placeholder {
            position: absolute;
            background: transparent;
            pointer-events: none;

            &::placeholder {
                text-align: center;
            }
        }
    }

    input {
        outline: none;
        border: none;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        background: transparent;
        text-align: center;

        &.ng-dirty + .sw-control__field__placeholder {
            opacity: 0;
        }
    }

    &.sw-vehicle-number {
        &_rus {
            .sw-vehicle-number__part {
                &_0 {
                    --margin-start: 12px;
                    // --margin-bottom: 3px;
                    --font-size: 18px;
                    // --line-height: 14px;
                    // height: 23px;
                    width: 17px;
                }
                &_1 {
                    --margin-end: 0;
                    --font-size: 26px;
                    --height: 32px;
                    // --line-height: 20px;
                    width: 64px;

                    input {
                        letter-spacing: 4px;
                    }

                    @media only screen and ( -webkit-min-device-pixel-ratio: 2 ){
                        --margin-bottom: -1px;
                    }
                }
                &_2 {
                    --margin-start: 4px;
                    --margin-end: 12px;
                    // --margin-bottom: 3px;
                    --font-size: 19px;
                    // --line-height: 10px;
                    // height: 23px;
                    width: 39px;

                    input {
                        letter-spacing: 3px;
                    }
                }
                &_3 {
                    --margin-end: 7px;
                    --margin-start: 5px;
                    --font-size: 15.75px;
                    height: 32px;
                    width: 39px;
                    align-self: flex-start;

                    &::before {
                        position: relative;
                        top: 3px;
                        left: -5px;
                        height: 30px;
                        background: var(--sw-border-color-dark);
                    }

                    &::after {
                        left: 5px;
                        right: 5px;
                        bottom: 0;
                        font-size: 7.36px;
                        font-weight: 700;
                        letter-spacing: 5%;
                        color: var(--sw-text-color);
                    }

                    input {
                        letter-spacing: 2px;
                    }
                }
            }
        }
    }
}
