.sw-toast {
    /**
    * @prop --background: Фон всплывашки
    * @prop --color: Цвет всплывашки
    *
    * @prop --border-color: Цвет границы всплывашки
    * @prop --border-radius: Радиус скругления всплывашки
    * @prop --border-width: Толщина границы всплывашки
    * @prop --border-style: Стиль границы всплывашки
    *
    * @prop --white-space: Перенос строки сообщения
    *
    * @prop --box-shadow: Тень всплывашки
    *
    * @prop --min-width: Минимальная ширина всплывашки
    * @prop --width: Ширина всплывашки
    * @prop --max-width: Максимальная ширина всплывашки
    *
    * @prop --min-height: Минимальная высота всплывашки
    * @prop --height: Высота всплывашки
    * @prop --max-height: Максимальная высота всплывашки
    */
    --border-width: 0;
    --border-style: none;
    --border-color: initial;
    --box-shadow: none;
    --min-width: auto;
    --width: auto;
    --min-height: auto;
    --height: auto;
    --max-height: auto;
    --white-space: normal;
    display: flex;
    width: var(--width);
    min-width: var(--min-width);
    max-width: var(--max-width);
    height: var(--height);
    min-height: var(--min-height);
    max-height: var(--max-height);
    border-radius: var(--border-radius);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    background: var(--background);
    box-shadow: var(--box-shadow);
    padding: var(--sw-padding);
    outline: none;
    color: var(--color);
    font-family: var(--sw-font-family, inherit);

    &__content {
        flex: 1;
        overflow: auto;
    }

    &__title {
        margin-bottom: var(--sw-multiple-px);
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: var(--sw-text-color);
    }

    &__message {
        white-space: var(--white-space);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: var(--sw-text-color-secondary);
    }

    &__icon {
        flex-shrink: 0;
        position: relative;
        margin-left: var(--sw-padding);
        padding-right: calc( var(--sw-multiple-px) * 5 );

        & + .sw-toast__content{
            padding-left: calc( var(--sw-multiple-px) * 4 );
            border-left: 1px solid var(--sw-border-color);
        }
    }

    &__close {
        border: none;
        outline: 0;
        background: transparent;
        width: 20px;
        height: 20px;
        color: var(--sw-placeholder-color);
    }

    &-group {
        position: fixed;
        z-index: 1200;
        right: 12px;

        &_top {
            top: 12px;
        }
        &_bottom {
            bottom: 12px;
        }
        &_center,
        &_middle {
            display: flex;
            flex-direction: column;
            top: 0;
            bottom: 0;
            // align-items: center;
            justify-content: center;
        }
    }
}


.sw-toast {
    --background: var(--sw-background);
    --border-radius: var(--sw-border-radius);
    --box-shadow: 0px 10px 20px -10px rgba(144, 150, 162, 0.2);
    --max-width: 416px;
    --width: 416px;
    --max-height: calc(100vh - var(--sw-padding) * 3);
    margin-bottom: var(--sw-padding);

    &__content {
    }

    &__buttons {
        display: flex;

        sw-button {
            margin-top: 48px;

            + sw-button {
                margin-left: var(--sw-margin);
            }
        }
    }

    @media (max-width: 576px) {
        flex-direction: column;
        align-items: center;
        padding: 32px 20px 20px;

        .sw-toast__icon {
            padding-right: 0;
            margin-left: 0;
            margin-bottom: 24px;
        }

        .sw-toast__content {
            padding-left: 0;
            border-left: 0;
            text-align: center;
        }

        .sw-toast__title {
            font-size: 18px;
            line-height: 24px;
        }

        .sw-toast__message {
            font-size: 14px;
            line-height: 20px;
            color: var(--sw-text-color);
        }

        .sw-toast__buttons {
            display: block;

            sw-button {
                display: block;
                margin-top: 32px;
                margin-bottom: 12px;
                margin-left: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .sw-toast__close {
            display: none;
        }
    }
}
