// все типы сеток с переопределяющими переменными компонента сетки
.sw-grid {
    &_global {
        > sw-row > sw-col {
            --sw-grid-column-padding-start: 22px;
            --sw-grid-column-padding-end: 22px;

            .sw-form__footer & {
                --sw-grid-column-padding-top: 0;
                --sw-grid-column-padding-bottom: 0;
            }
        }
    }
    &_form {
        > sw-row > sw-col {
            --sw-grid-column-padding-start: calc(var(--sw-multiple-px) * 3);
            --sw-grid-column-padding-end: calc(var(--sw-multiple-px) * 3);
            --sw-grid-column-padding-top: 0;
            --sw-grid-column-padding-bottom: 0;

            .sw-form_oneline & {
                --sw-grid-column-padding-start: var(--sw-multiple-px);
                --sw-grid-column-padding-end: var(--sw-multiple-px);
            }
        }
    }
    // &_percentage {
    //     > sw-row > sw-col {
    //         --sw-grid-column-padding-start: 28px;
    //         --sw-grid-column-padding-end: 28px;
    //     }
    // }
    &_accordion,
    &_accordion &_form {
        > sw-row > sw-col {
            --sw-grid-column-padding-start: calc(var(--sw-multiple-px) * 4);
            --sw-grid-column-padding-end: calc(var(--sw-multiple-px) * 4);
            --sw-grid-column-padding-top: 10px;
            --sw-grid-column-padding-bottom: 10px;
        }

        > sw-row:last-child > sw-col {
            --sw-grid-column-padding-bottom: 0;
        }
    }
    &_card {
        margin: 0 calc(var(--sw-multiple-px) * -4);

        > sw-row > sw-col {
            --sw-grid-column-padding-start: calc(var(--sw-multiple-px) * 4);
            --sw-grid-column-padding-end: calc(var(--sw-multiple-px) * 4);
            --sw-grid-column-padding-bottom: calc(var(--sw-padding) * 2);
        }
    }
    &_trip {
        > sw-row > sw-col {
            --sw-grid-column-padding-start: 10px;
            --sw-grid-column-padding-end: 10px;
        }
    }
    &_flight {
        --sw-grid-columns: 15;
        > sw-row > sw-col {
            --sw-grid-column-padding-start: 16px;
            --sw-grid-column-padding-end: 16px;
        }

        &-head {
            --sw-grid-columns: 15;
            > sw-row > sw-col {
                --sw-grid-column-padding-top: 0;
                // --sw-grid-column-padding-start: 16px;
                // --sw-grid-column-padding-end: 16px;
            }
        }
    }
    &_filter {
        --padding-grid: calc(var(--sw-multiple-px) * 3);
        margin: 0 calc(var(--padding-grid) * -1);

        > sw-row > sw-col {
            --sw-grid-column-padding-start: var(--padding-grid);
            --sw-grid-column-padding-end: var(--padding-grid);
        }
    }
    &_center {
        max-width: 800px;

        > sw-row > sw-col {
            --sw-grid-column-padding-top: 0;
            --sw-grid-column-padding-bottom: 0;
        }
    }
    &_mini {
        > sw-row > sw-col {
            --sw-grid-column-padding-start: 8px;
            --sw-grid-column-padding-end: 8px;
        }
    }

    &_global,
    &_form,
    // &_percentage,
    &_accordion,
    &_trip,
    &_flight,
    &_flight-head,
    &_center,
    &_mini {
        > sw-row > sw-col {
            &:first-child {
                --sw-grid-column-padding-start: 0;
            }
            &:last-child {
                --sw-grid-column-padding-end: 0;
            }
        }
    }
}

sw-grid {
    .sw-block_pane & {
        --sw-grid-padding: calc(var(--sw-multiple-px) * 2);

        > sw-row > sw-col {
            --sw-grid-column-padding-start: calc(var(--sw-multiple-px) * 2);
            --sw-grid-column-padding-end: calc(var(--sw-multiple-px) * 2);
        }
    }

    .sw-chart-area-line__subtitle {
        position: absolute;
        z-index: 1;
        top: 88px;
    }
}

sw-row {
    &.sw-list_vline {
        > sw-col.sw-item::before {
            --padding-start: calc(var(--sw-multiple-px) * 8);
        }

        &:first-child {
            > sw-col {
                --sw-grid-column-padding-top: 0;
            }
        }
        &:last-child {
            > sw-col {
                --sw-grid-column-padding-bottom: 0;
            }
        }
    }

    &.sw-row_vertical-zero {
        > sw-col {
            --sw-grid-column-padding-top: 0;
            --sw-grid-column-padding-bottom: 0;
        }
    }
}

sw-col {
    &.sw-col_top-zero {
        --sw-grid-column-padding-top: 0 !important;
    }
    &.sw-col_bottom-zero {
        --sw-grid-column-padding-bottom: 0 !important;
    }
}

@media (max-width: 1024px) {
    .sw-grid_center {
        max-width: 100%;
        margin: 0 36px;
    }
}

@media (max-width: 576px) {
    .sw-grid_center {
        margin: 0;
    }
}
