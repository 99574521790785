.sw-dispatcher-widget {
    --padding-top: 12px;
    --padding-bottom: 12px;
    --padding-start: 16px;
    --padding-end: 16px;
    --margin-bottom: 0;
    --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    --width: 332px;
    float: left;
    margin-right: 24px;
    pointer-events: all;

    .sw-list_between {
        font-size: 12px;
        line-height: 16px;

        sw-badge {
            --margin-start: 0;
            --margin-end: 8px;
        }

        .sw-item {
            > *:last-child {
                display: flex;
                justify-content: space-between;
                width: 90px;
                text-align: right;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    .sw-percentage-bar__part {
        position: static;
    }
}
