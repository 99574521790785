// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Thin'), local('Gordita-Thin'),
//         url('/assets/fonts/web-license/Gordita-thin.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-thin.woff') format('woff');
//     font-weight: 100;
//     font-style: normal;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }
// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Thin Italic'), local('Gordita-Thin-Italic'),
//         url('/assets/fonts/web-license/Gordita-thin-italic.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-thin-italic.woff') format('woff');
//     font-weight: 100;
//     font-style: italic;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }

// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Light'), local('Gordita-Light'),
//         url('/assets/fonts/web-license/Gordita-light.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-light.woff') format('woff');
//     font-weight: 200;
//     font-style: normal;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }
// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Light Italic'), local('Gordita-LightItalic'),
//         url('/assets/fonts/web-license/Gordita-light-italic.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-light-italic.woff') format('woff');
//     font-weight: 200;
//     font-style: italic;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }

// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Regular'), local('Gordita-Regular'),
//         url('/assets/fonts/web-license/Gordita-regular.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-regular.woff') format('woff');
//     font-weight: 400;
//     font-style: normal;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }
// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Italic'), local('Gordita-Italic'),
//         url('/assets/fonts/web-license/Gordita-regular-italic.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-regular-italic.woff') format('woff');
//     font-weight: 400;
//     font-style: italic;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }

// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Medium'), local('Gordita-Medium'),
//         url('/assets/fonts/web-license/Gordita-medium.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-medium.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }
// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Medium Italic'), local('Gordita-MediumItalic'),
//         url('/assets/fonts/web-license/Gordita-medium-italic.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-medium-italic.woff') format('woff');
//     font-weight: 500;
//     font-style: italic;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }

// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Bold'), local('Gordita-Bold'),
//         url('/assets/fonts/web-license/Gordita-bold.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-bold.woff') format('woff');
//     font-weight: 600;
//     font-style: normal;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }
// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Bold Italic'), local('Gordita-BoldItalic'),
//         url('/assets/fonts/web-license/Gordita-bold-italic.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-bold-italic.woff') format('woff');
//     font-weight: 600;
//     font-style: italic;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }

// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Black'), local('Gordita-Black'),
//         url('/assets/fonts/web-license/Gordita-black.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-black.woff') format('woff');
//     font-weight: 700;
//     font-style: normal;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }
// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Black Italic'), local('Gordita-BlackItalic'),
//         url('/assets/fonts/web-license/Gordita-black-italic.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-black-italic.woff') format('woff');
//     font-weight: 700;
//     font-style: italic;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }
// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Black'), local('Gordita-Black'),
//         url('/assets/fonts/web-license/Gordita-ultra.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-ultra.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }

// @font-face {
//     font-family: 'Gordita';
//     font-display: swap;
//     src:
//         // local('Gordita Black Italic'), local('Gordita-BlackItalic'),
//         url('/assets/fonts/web-license/Gordita-ultra-italic.woff2') format('woff2'),
//         url('/assets/fonts/web-license/Gordita-ultra-italic.woff') format('woff');
//     font-weight: 900;
//     font-style: italic;

//     size-adjust: 94%;
//     ascent-override: 100%;
// }

@font-face {
    font-family: 'Gordita';
    font-display: swap;
    src: local('Gordita Regular'), local('Gordita-Regular'),
        url('/assets/fonts/Gordita-Regular.woff2') format('woff2'),
        url('/assets/fonts/Gordita-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gordita';
    font-display: swap;
    src: local('Gordita Italic'), local('Gordita-Italic'),
        url('/assets/fonts/Gordita-Italic.woff2') format('woff2'),
        url('/assets/fonts/Gordita-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gordita';
    font-display: swap;
    src: local('Gordita Medium'), local('Gordita-Medium'),
        url('/assets/fonts/Gordita-Medium.woff2') format('woff2'),
        url('/assets/fonts/Gordita-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gordita';
    font-display: swap;
    src: local('Gordita Medium Italic'), local('Gordita-Medium-Italic'),
        url('/assets/fonts/Gordita-Medium-Italic.woff2') format('woff2'),
        url('/assets/fonts/Gordita-Medium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gordita';
    font-display: swap;
    src: local('Gordita Bold'), local('Gordita-Bold'),
        url('/assets/fonts/Gordita-Bold.woff2') format('woff2'),
        url('/assets/fonts/Gordita-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'RoadNumbers';
    font-display: swap;
    src: local('RoadNumbers'), local('RoadNumbers2.0'),
        url('/assets/fonts/RoadNumbers2.0.otf') format('otf'),
        url('/assets/fonts/RoadNumbers2.0.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@700&display=swap');

html {
    font-family: var(--sw-font-family);
    font-weight: 400;
    font-size: 13px;
    color: var(--sw-text-color);
}

a {
    background-color: transparent;
    color: var(--sw-link-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0; // необходимо будет уточнение
}

h1,
h2,
h3 {
    font-weight: 400;
}
h1,
.sw-h1[class] {
    padding-bottom: 32px;
    font-size: 45px;
    line-height: 52px;

    + .sw-h1_subtitle {
        padding-bottom: 50px;
    }

    @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 48px;
    }

    @media (max-width: 576px) {
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
    }
}
h2,
.sw-h2[class] {
    font-size: 36px;
    line-height: 44px;
}
h3,
.sw-h3[class] {
    font-size: 28px;
    line-height: 36px;
}

h4,
.sw-h4[class] {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
}

h5,
.sw-h5[class] {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

h6,
.sw-h6[class] {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    &.sw-h6_32 {
        line-height: 32px;
    }

    sw-button {
        vertical-align: middle;
    }
}

p {
    margin-top: var(--sw-multiple-px);
    margin-bottom: var(--sw-multiple-px);
}

strong {
    font-weight: 700;
}

small {
    font-size: 75%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

span.sw-color {
    color: var(--sw-color-base);
}

.sw-text {
    // body text sizes
    &-size {
        &_xl {
            font-size: 18px;
            line-height: 24px;
        }
        &_l {
            font-size: 16px;
            line-height: 24px;
        }
        &_m {
            font-size: 14px;
            line-height: 20px;
        }
        &_m_reduced {
            font-size: 14px;
            line-height: 16px;
        }
        &_s {
            font-size: 13px;
            line-height: 20px;
        }
        &_s_reduced {
            font-size: 13px;
            line-height: 16px;
        }
        &_xs {
            font-size: 12px;
            line-height: 16px;
        }
        &_xs_caps {
            text-transform: uppercase;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 1px;
        }
        &_xs_reduced {
            font-size: 12px;
            line-height: 12px;
        }
        &_mini {
            font-size: 11px;
            line-height: 12px;
        }
        &_minicaps {
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            text-transform: uppercase;
        }
    }

    // body font-weight;
    &-weight_normal {
        font-weight: 400;
    }
    &-weight_medium {
        font-weight: 500;
    }
    &-weight_strong {
        font-weight: 700;
    }

    // text action
    &-action {
        font-weight: 500;
        color: var(--sw-color-action);

        &.sw-text-size_xl,
        .sw-text-size_xl & {
            letter-spacing: 0.75px;
        }
        &.sw-text-size_l,
        .sw-text-size_l &,
        &.sw-text-size_m,
        .sw-text-size_m & {
            letter-spacing: 0.5px;
        }
        &.sw-text-size_s,
        .sw-text-size_s & {
            letter-spacing: 0.25px;
        }
    }

    // text secondary
    &_secondary {
        color: var(--sw-text-color-secondary);
    }

    // text total
    &_total {
        font-size: 20px;
        font-weight: 500;
        line-height: 21px;
    }

    &_field-height {
        line-height: 36px
    }

    &_inner {
        a {
            @extend .sw-text-action;
            text-decoration: none;
        }
    }
}
//////////
.sw-icon_fill {
    --sw-icon-medium-size: 20px;
    width: 20px;
    height: 20px;
    padding: 6px;
    border-radius: var(--sw-border-radius);
}
