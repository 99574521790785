.sw-trip {
    &__point {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: var(--sw-color-base);
        border: 2px solid var(--sw-color-contrast);
        box-shadow: 0 0 0 2px var(--sw-color-base);
        margin-right: 18px;

        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: var(--sw-color-contrast);

        .sw-block_info & {
            width: 28px;
            height: 28px;
            margin-right: 40px;
            font-size: 14px;
            line-height: 24px;

            .ua-Safari & {
                line-height: 28px;
            }
        }
    }
    &__start,
    &__end {
        display: flex;

        sw-link sw-icon {
            --margin-icon: 2px;
        }
    }

    &__start {
        position: relative;
        padding-bottom: calc(var(--sw-padding) * 2);

        &::before {
            content: '';
            position: absolute;
            top: 38px;
            bottom: 12px;
            left: 10px;
            width: 2px;
            background: radial-gradient(
                        circle,
                        var(--sw-placeholder-color) 0.6px,
                        transparent 0.78px
                    )
                    0 0,
                radial-gradient(circle, var(--sw-placeholder-color) 0, transparent 0.78px) 2px 2px;
            background-size: 2px 2px;
            background-repeat: repeat;

            .sw-block_info & {
                left: 12px;
                width: 4px;
                background: radial-gradient(circle, var(--sw-placeholder-color) 2px, transparent 0)
                        0 0,
                    radial-gradient(circle, var(--sw-placeholder-color) 0px, transparent 0px) 4px
                        4px;
                background-size: 4px 8px;
            }
        }
    }
    &__end {
        padding-bottom: calc(var(--sw-multiple-px) * 2);
    }

    &__comment {
        padding-left: 67px;
        margin-top: calc(var(--sw-multiple-px) * 6);
    }

    .sw-block_info & {
        margin-left: 2px;

        h5 {
            margin-bottom: 4px;

            sw-link {
                font-weight: 400;
            }
        }

        &__start {
            padding-bottom: calc(var(--sw-multiple-px) * 7);
        }
    }

    @media (max-width: 576px) {
        &__comment {
            padding-left: 0;
        }

        .sw-block_info & {
            h5 {
                font-size: 16px;

                .sw-dateplate {
                    display: block;
                    padding-left: 0;
                }
            }

            &__point {
                width: 16px;
                height: 16px;
                margin-right: 12px;
                font-size: 0;
                line-height: 14px;

                &_icon {
                    background: transparent;
                    border: 0;
                    box-shadow: none;

                    sw-icon {
                        --sw-icon-xs-size: 20px;
                        color: var(--sw-color-base);
                    }
                }
            }

            &__start::before {
                left: 7px;
                top: 22px;
                bottom: 5px;
                width: 2px;
                background: radial-gradient(
                        circle at 25% 25%,
                        var(--sw-placeholder-color) 10%,
                        transparent 15%
                    ),
                    radial-gradient(
                        circle at 75% 75%,
                        var(--sw-placeholder-color) 10%,
                        transparent 15%
                    );
                background-size: 4px 4px;
            }
        }
    }
}
