.sw-status-save {
    --margin-start: 0;
    --margin-end: 0;
    --margin-bottom: 0;
    --font-size: 13px;
    --font-weight: 400;
    --color: var(--sw-text-color);

    sw-icon {
        margin-right: 8px;
    }
}

app-status {
    > div {
        display: inline-block;
        max-width: 100%;
        vertical-align: middle;
    }
}
