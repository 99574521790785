.sw-tabs-cmp {
    /**
     * @prop --background: фон панели вкладок
     * @prop --padding-start: начальный отступ панели вкладок
     * @prop --padding-end: конечный отступ панели вкладок
     * @prop --padding-top: верхний отступ панели вкладок
     * @prop --padding-bottom: нижний отступ панели вкладок
     * @prop --border-radius: радиус скругления границы панели вкладок
     * @prop --border-width: толщина границы панели вкладок
     * @prop --border-style: начертание границы панели вкладок
     * @prop --border-color: цвет границы панели вкладок
     * @prop --height: высота панели вкладок
     * @prop --box-shadow: тень панели вкладок
     * @prop --transition: переход состояний вкладки
     *
     *
     * @prop --tab-background: фон вкладки
     * @prop --tab-background-focused: фон вкладки при фокусе
     * @prop --tab-background-hover: фон вкладки при наведении
     * @prop --tab-background-selected: фон выбранной вкладки
     * @prop --tab-background-disabled: фон отключенной вкладки
     *
     * @prop --tab-padding-start: начальный отступ вкладки
     * @prop --tab-padding-end: конечный отступ вкладки
     * @prop --tab-padding-top: верхний отступ вкладки
     * @prop --tab-padding-bottom: нижний отступ вкладки
     * @prop --tab-margin-start: начальный внешний отступ вкладки
     * @prop --tab-margin-end: конечный внешний отступ вкладки
     * @prop --tab-margin-top: верхний внешний отступ вкладки
     * @prop --tab-margin-bottom: нижний внешний отступ вкладки
     *
     * @prop --tab-border-radius: радиус скругления границы вкладки
     * @prop --tab-border-width: толщина границы вкладки
     * @prop --tab-border-style: начертание границы вкладки
     * @prop --tab-border-color: цвет границы вкладки
     * @prop --tab-border-color-focused: цвет границы вкладки при фокусе
     * @prop --tab-border-color-hover: цвет границы вкладки при наведении
     * @prop --tab-border-color-selected: цвет границы выбранной вкладки
     * @prop --tab-border-color-disabled: цвет границы отключенной вкладки
     *
     * @prop --color: цвет текста вкладки
     * @prop --color-focused: цвет текста вкладки при фокусе
     * @prop --color-hover: цвет текста вкладки при наведении
     * @prop --color-selected: цвет текста выбранной вкладки
     * @prop --color-disabled: цвет текста отключенной вкладки
     */
    --height: initial;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --tab-border-color-focused: var(--tab-border-color);
    --tab-border-color-hover: var(--tab-border-color);
    --tab-border-color-selected: var(--tab-border-color);
    --tab-border-color-disabled: var(--tab-border-color);
    --background: initial;
    --box-shadow: none;
    --color: inherit;
    --color-focused: var(--color);
    --color-hover: var(--color);
    --color-selected: var(--color);
    --color-disabled: var(--color);
    --font-size: initial;

    display: block;
    overflow: hidden;

    .sw-tabs-cmp__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: auto;
        height: var(--height);
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
        border-radius: var(--border-radius);
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);
        background: var(--background);
        box-shadow: var(--box-shadow);
        color: var(--color);
        text-align: center;
        user-select: none;
        z-index: 10;

        overflow-y: auto;
    }

    .sw-tab-btn {
        flex-shrink: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        height: 100%;
        margin-left: var(--tab-margin-start);
        margin-right: var(--tab-margin-end);
        margin-top: var(--tab-margin-top);
        margin-bottom: var(--tab-margin-bottom);
        padding-left: var(--tab-padding-start);
        padding-right: var(--tab-padding-end);
        padding-top: var(--tab-padding-top);
        padding-bottom: var(--tab-padding-bottom);
        border-radius: var(--tab-border-radius);
        border-width: var(--tab-border-width);
        border-style: var(--tab-border-style);
        border-color: var(--tab-border-color);
        background: var(--tab-background);

        font-family: var(--sw-font-family, inherit);
        font-size: var(--font-size);
        font-weight: 400;
        line-height: 1;
        color: var(--color);

        outline: none;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        text-transform: inherit;
        white-space: nowrap;
        user-select: none;
        cursor: pointer;

        appearance: none;
        transition: var(--transition);

        &--focused,
        &:focus {
            background: var(--tab-background-focused);
            border-color: var(--tab-border-color-focused);
            color: var(--color-focused);
        }

        &:hover {
            background: var(--tab-background-hover);
            border-color: var(--tab-border-color-hover);
            color: var(--color-hover);
        }

        &--selected {
            background: var(--tab-background-selected);
            border-color: var(--tab-border-color-selected);
            color: var(--color-selected);
        }

        &:disabled,
        &[disabled],
        &--disabled {
            background: var(--tab-background-disabled);
            border-color: var(--tab-border-color-disabled);
            color: var(--color-disabled);

            cursor: default;
            pointer-events: none;
        }

        sw-icon {
            font-size: 12px;

            &:first-child {
                margin-right: 8px;
            }

            &:last-child {
                margin-left: 8px;
            }

            &:only-child {
                margin-left: -6px;
                margin-right: -6px;
                font-size: 16px;
            }
            @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
                &:first-child {
                    margin-right: unset;
                    -webkit-margin-end: 8px;
                    margin-inline-end: 8px;
                }
                &:last-child {
                    margin-left: unset;
                    -webkit-margin-start:  8px;
                    margin-inline-start:  8px;
                }
                &:only-child {
                    margin-left: unset;
                    margin-right: unset;
                    -webkit-margin-start:  -6px;
                    margin-inline-start:  -6px;
                    -webkit-margin-end: -6px;
                    margin-inline-end: -6px;
                }
            }
        }

        &.swh-underconstruction {
            opacity: .5;
            cursor: not-allowed !important;
        }
    }
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .sw-tabs-cmp__wrapper {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: var(--padding-start);
        padding-inline-start: var(--padding-start);
        -webkit-padding-end: var(--padding-end);
        padding-inline-end: var(--padding-end);

        .sw-tab-btn {
            margin-left: unset;
            margin-right: unset;
            -webkit-margin-start: var(--tab-margin-start);
            margin-inline-start: var(--tab-margin-start);
            -webkit-margin-end: var(--tab-margin-end);
            margin-inline-end: var(--tab-margin-end);

            padding-left: unset;
            padding-right: unset;
            -webkit-padding-start: var(--tab-padding-start);
            padding-inline-start: var(--tab-padding-start);
            -webkit-padding-end: var(--tab-padding-end);
            padding-inline-end: var(--tab-padding-end);
        }
    }
}

.sw-tabs-cmp.sw-color {
    .sw-tab-btn {
        --tab-border-color-focused: var(--sw-color-shade);
        --color-selected: var(--sw-color-contrast);

        &--focused,
        :focus {
            background: var(--tab-background-focused);
        }

        &--selected {
            color: var(--sw-color-contrast);
        }
    }
}

.sw-tabs-cmp.sw-color .sw-tabs-cmp__wrapper,
.sw-tabs-cmp.sw-color .sw-tab-btn {
    background: var(--sw-color-base);
    color: rgba(var(--sw-color-contrast-rgb), 0.7);
}

// implemented
.sw-tabs-cmp {
    --color: var(--sw-text-color);
    --height: 48px;
    --font-size: 16px;

    .sw-tabs-cmp__wrapper {
        --padding-bottom: 8px;
        margin-bottom: calc(-1 * var(--padding-bottom));
    }

    .sw-tab-btn {
        // --tab-background: var(--background);
        --tab-background-selected: transparent;
        --tab-border-width: 0 0 2px 0;
        --tab-border-style: solid;
        --tab-border-color: transparent;
        --tab-border-color-selected: var(--sw-text-color);
        --tab-margin-end: 24px;

        // &:last-child {
        //     --tab-margin-end: 0;
        // }

        &--selected {
            font-weight: 500;
        }
    }
}
