.sw-timeline {
    display: flex;
    align-items: flex-end;

    .sw-map-wrapper &,
    // .sw-dispatcher-map &,
    .sw-dispatcher-on-map-layer & {
        position: absolute;
        bottom: 8px;
        z-index: 150;
        border-radius: var(--sw-border-radius);
        background: var(--sw-color-white);
        padding: 10px 12px;
    }

    .sw-map-wrapper & {
        left: 8px;
        right: 8px;
        width: calc(100% - 16px);
    }

    .sw-dispatcher-on-map-layer & {
        bottom: 0;
        left: 404px;
        right: -42px;
        transition: left 300ms ease-in-out;
        will-change: left;
        pointer-events: all;
    }

    .sw-dispatcher-on-map-layer .sw-dispatcher-panel--closed ~ & {
        left: 16px;
    }

    &__slider {
        &-wrapper {
            max-width: 1080px;
            width: 100%;
            margin: 0 24px -4px;
        }
    }

    &__graph {
        height: 40px;
        width: 100%;
    }

    &__labels {
        display: flex;
        justify-content: space-between;
        font-size: 11px;
        line-height: 12px;
        color: var(--sw-text-color-secondary);

        span:first-child {
            visibility: hidden;
        }
    }

    .sw-btn-play {
        margin-right: 22px;
    }

    .sw-btn-speed {
        --border-radius: 50%;
        --border-color: transparent;
        --border-color-activated: var(--sw-border-color-dark);
        --background-activated: var(--sw-color-white);
        --padding-start: 0;
        --padding-end: 0;

        width: 32px;
        margin: 0 2px;
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
    }

    //// ngx-slider
    .ngx-slider {
        margin: 2px 0 6px;

        .ngx-slider-pointer {
            background-color: var(--sw-text-color);
            width: 3px;
            height: 15px;
            border-radius: 2px;
            top: -6px;

            &::after {
                display: none;
            }
        }
        .ngx-slider-bar-wrapper {
            margin-top: -8px;
            padding-top: 8px;

            &.ngx-slider-selection-bar {
                visibility: visible !important;
            }
        }
        .ngx-slider-bar {
            background-color: var(--sw-border-color);
        }
        .ngx-slider-selection {
            background-color: var(--sw-text-color);
        }
        .ngx-slider-bubble {
            display: none;
        }
    }
}
