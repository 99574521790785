.sw-paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_secondary {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .sw-announcement & {
            margin-top: 40px;
        }

        .sw-btn-group {
            &:last-child {
                sw-button {
                    &.swh-hidden {
                        display: none;
                    }

                    margin-left: 8px;

                    &::part(native) {
                        border: var(--border-width) var(--border-style) var(--border-color);
                    }
                }
            }
        }
    }

    @media (max-width: 992px) {
        .sw-btn-group sw-button.sw-btn_solid {
            --background: var(--sw-color-white);
            font-weight: 400;
        }
    }

    @media (max-width: 400px) {
        &_secondary {
            display: block;

            .sw-btn-group {
                &:last-child {
                    margin-top: 8px;

                    sw-button {
                        &:first-child,
                        &.swh-hidden + sw-button {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}
