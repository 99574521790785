.sw-list {
    /*
    * @prop --background-list: фон списка
    *
    * @prop --border-radius-list: радиус скругления списка
    * @prop --border-width-list: толщина границы списка
    * @prop --border-style-list: стиль границы списка
    * @prop --border-color-list: цвет границы списка
    *
    * @prop --padding-top-list: внутренний верхний отступ списка
    * @prop --padding-end-list: внутренний конечный отступ списка
    * @prop --padding-bottom-list: внутренний нижний отступ списка
    * @prop --padding-start-list: внутренний начальный отступ списка
    *
    * @prop --margin-top-list: внешний верхний отступ списка
    * @prop --margin-end-list: внешний конечный отступ списка
    * @prop --margin-bottom-list: внешний нижний отступ списка
    * @prop --margin-start-list: внешний начальный отступ списка
    * @prop --box-shadow: тень списка
    *
    * @prop --max-height: максимальная высота списка
    *
    * @prop --background: фон итема
    * @prop --background-hover: фон итема при наведении
    * @prop --background-selected: фон выбранного итема
    *
    * @prop --border-radius: радиус скругления итема
    * @prop --border-width: толщина границы итема
    * @prop --border-style: стиль границы итема
    * @prop --border-color: цвет границы итема
    *
    * @prop --padding-top: внутренний верхний отступ итема
    * @prop --padding-end: внутренний конечный отступ итема
    * @prop --padding-bottom: внутренний нижний отступ итема
    * @prop --padding-start: внутренний начальный отступ итема
    *
    * @prop --margin-top: внешний верхний отступ итема
    * @prop --margin-end: внешний конечный отступ итема
    * @prop --margin-bottom: внешний нижний отступ итема
    * @prop --margin-start: внешний начальный отступ итема
    *
    * @prop --min-height: минимальная высота итема
    *
    * @prop --list-style: стиль списка
    *
    * @prop --color: цвет текста
    * @prop --color-hover: цвет текста при наведении
    * @prop --color-selected: цвет текста выбранного пункта
    * @prop --font-size: размер шрифта
    * @prop --font-weight: жирность шрифта
    * @prop --line-height: межстрочный интервал для текста
    *
    * @prop --size-icon: размер иконки
    */
    --min-height: initial;
    --border-width: initial;
    --border-color: initial;
    --border-style: initial;
    --padding-start: initial;
    --padding-end: initial;
    --padding-top: initial;
    --padding-bottom: initial;
    --margin-start: initial;
    --margin-end: initial;
    --margin-top: initial;
    --margin-bottom: initial;
    --background: initial;
    --background-hover: var(--background);
    --background-selected: var(--background);
    --color: inherit;
    --color-hover: var(--color);
    --color-selected: var(--color);
    --font-size: inherit;
    --font-weight: inherit;
    --line-height: inherit;

    --max-height: initial;
    --padding-start-list: 0;
    --padding-end-list: 0;
    --padding-top-list: 0;
    --padding-bottom-list: 0;
    --margin-start-list: 0;
    --margin-end-list: 0;
    --margin-top-list: 0;
    --margin-bottom-list: 0;
    --backgound-list: initial;
    --border-radius-list: initial;
    --border-width-list: initial;
    --border-color-list: initial;
    --border-style-list: initial;
    --box-shadow: none;

    max-height: var(--max-height);
    border-radius: var(--border-radius-list);
    border-width: var(--border-width-list);
    border-style: var(--border-style-list);
    border-color: var(--border-color-list);
    padding: var(--padding-top-list) var(--padding-end-list) var(--padding-bottom-list)
        var(--padding-start-list);
    margin: var(--margin-top-list) var(--margin-end-list) var(--margin-bottom-list)
        var(--margin-start-list);
    background: var(--background-list);
    box-shadow: var(--box-shadow);
    list-style: var(--list-style);

    &.swh-no-margin {
        margin: 0;
    }

    .sw-item {
        display: list-item;
        min-height: var(--min-height);
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
        margin-left: var(--margin-start);
        margin-right: var(--margin-end);
        margin-top: var(--margin-top);
        margin-bottom: var(--margin-bottom);
        border-radius: var(--border-radius);
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);
        background: var(--background);
        list-style: var(--list-style);
        font-size: var(--font-size);
        font-weight: var(--font-weight);
        line-height: var(--line-height);
        color: var(--color);

        &:hover,
        &.active {
            background: var(--background-hover);
            color: var(--color-hover);
        }

        &--selected,
        &--selected:hover,
        &:active,
        &:active:hover {
            background: var(--background-selected);
            color: var(--color-selected);
        }
    }

    //types
    &_h {
        display: flex;
        align-items: center;
    }

    &_bullet {
        .sw-item {
            // &::before {
            //     content: '';
            //     display: inline-block;
            //     margin-right: var(--padding-start);
            //     margin-bottom: 2px;
            //     width: 2px;
            //     height: 2px;
            //     background: var(--color);
            //     border-radius: 50%;
            // }

            // &:first-child {
            //     --padding-start: 0;

            //     &::before {
            //         display: none;
            //     }
            // }
            padding-left: 0;

            &::after {
                content: '';
                display: inline-block;
                margin-left: var(--padding-end);
                margin-bottom: 2px;
                width: 2px;
                height: 2px;
                background: var(--color);
                border-radius: 50%;
            }

            &:last-child {
                --padding-start: 0;

                &::after {
                    display: none;
                }
            }

            a {
                text-decoration: none;
            }

            > *:not(sw-tooltip) {
                display: inline-block;
            }
        }

        @media (max-width: 576px) {
            .sw-item {
                padding-left: 0;

                &::before {
                    display: none;
                }

                // TODO: can be delete start
                &::after {
                    content: '';
                    display: inline-block;
                    margin-left: var(--padding-end);
                    margin-bottom: 2px;
                    width: 2px;
                    height: 2px;
                    background: var(--color);
                    border-radius: 50%;
                }

                &:last-child {
                    --padding-start: 0;

                    &::after {
                        display: none;
                    }
                }
                // TODO: can be delete finish
            }
        }
    }

    &_vline {
        > .sw-item {
            display: inline-flex;

            &::before {
                content: '';
                border-left: 1px solid var(--border-color);
                padding-right: var(--padding-start);
            }

            &:first-child {
                --padding-start: 0;

                &::before {
                    display: none;
                }
            }

            &_noline {
                &::before {
                    display: none;
                }
            }
        }
    }

    &_select {
        width: 100%;
        overflow: auto;

        .sw-item {
            --height: initial;
            --border-radius: 0;
            position: relative;
            min-height: var(--height, 36px);
            cursor: pointer;
        }

        a.sw-item,
        .sw-item.sw-link,
        button.sw-item {
            display: block;
            width: 100%;
            line-height: var(--line-height);
            text-decoration: none;
            letter-spacing: 0;
            text-align: left;
        }
    }

    &_control-dd {
        @extend .sw-list_select;
        position: absolute;
        top: var(--position-top, var(--height, 0)); // = field height
        left: 0;
        z-index: 160;
        // width: 100%;
        // overflow: auto;

        // .sw-item {
        //     --height: initial;
        //     --border-radius: 0;
        //     position: relative;
        //     min-height: var(--height, 36px);
        //     cursor: pointer;
        // }

        // a.sw-item,
        // .sw-item.sw-link,
        // button.sw-item {
        //     display: block;
        //     width: 100%;
        //     line-height: var(--line-height);
        //     text-decoration: none;
        //     letter-spacing: 0;
        //     text-align: left;
        // }

        &-wrapper {
            position: relative;
            padding-bottom: var(--padding-bottom);
        }

        app-select-vehicle & {
            position: static;
        }

        .sw-dropdown & {
            sw-button.sw-item {
                text-align: left;

                &::part(inner) {
                    justify-content: flex-start;
                }
            }
        }
    }

    &_gallery {
        overflow: hidden;
        position: relative;
        flex-wrap: nowrap;
        transition: margin-left 0.5s ease-in;
    }

    &_between {
        > .sw-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .sw-block__content_end & {
            --margin-top-list: 26px;
        }

        // subtype
        &_dot-leaders {
            @extend .sw-list_between;
            > .sw-item {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    bottom: 4px;
                    opacity: 0.5;
                    border-bottom: 1px dotted var(--color-dot);
                }

                & > * {
                    position: relative;
                    z-index: 1;
                    background: var(--sw-background);

                    &:first-child {
                        padding-right: 8px;
                    }
                    &:last-child {
                        padding-left: 8px;
                    }
                }
            }
        }
    }

    &_menu {
        clear: both;

        .sw-item:not(a, sw-button) {
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0;
            --padding-end: 0;
        }

        sw-button {
            --padding-start: 0;
            --padding-end: 0;
            --padding-top: 0;
            --padding-bottom: 0;
            --height: auto;
            --background: transparent;
            --background-hover: var(--background);
            --background-activated: var(--background);
            --background-focused: var(--background);
            --color-hover: var(--color);
            --color-focused: var(--color);
            --color-activated: var(--color);
            display: block;
            width: 100%;
            text-align: center;
            font-weight: var(--font-weight);
            line-height: var(--line-height);
            font-size: var(--font-size);
            letter-spacing: 0;

            &::part(inner) {
                justify-content: flex-start;
            }
        }

        a {
            display: block;
            color: var(--color);
            text-decoration: none;

            &:hover {
                color: var(--color-hover);
            }

            &:not(.sw-item) {
                padding-left: var(--padding-start);
                padding-right: var(--padding-end);
                padding-top: var(--padding-top);
                padding-bottom: var(--padding-bottom);
            }
        }

        // subtype
        &_rounded {
            @extend .sw-list_menu !optional;

            sw-icon {
                border-radius: 50%;
            }
        }
    }
}

// implemented
.sw-list {
    --list-style: none;
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 20px;

    // types
    &_h {
        > .sw-item {
            --margin-end: calc(var(--sw-multiple-px) * 3);

            .sw-coordinates {
                margin-bottom: 0;
            }
        }
    }

    &_easy {
        > .sw-item + .sw-item {
            --padding-top: calc(var(--sw-multiple-px) * 4);

            app-profile-licenses & {
                --padding-top: calc(var(--sw-multiple-px) * 6);
            }
        }

        > .sw-item {
            sw-tooltip & {
                --padding-top: calc(var(--sw-multiple-px) * 3);
            }
        }
    }

    &_bullet {
        > .sw-item {
            --padding-start: calc(var(--sw-multiple-px) * 3);
            --padding-end: calc(var(--sw-multiple-px) * 3);
            --margin-end: 0;
            --font-size: 12px;
            --color: var(--sw-text-color-secondary);
        }
    }

    &_vline {
        --padding-start: calc(var(--sw-multiple-px) * 5);
        --border-color: var(--sw-border-color);

        .sw-checkbox__label,
        .sw-radio__label {
            margin-right: 0;
        }

        &.sw-list_h > .sw-item {
            --margin-end: 0;
        }

        &_mini {
            @extend .sw-list_vline;
            --padding-start: calc(var(--sw-multiple-px) * 3);
        }
    }

    &_select {
        --padding-start: 12px;
        --padding-end: 12px;
        --padding-top: calc(var(--sw-multiple-px) * 2);
        --padding-bottom: calc(var(--sw-multiple-px) * 2);
        --background-hover: var(--sw-background-dark);
        --background-selected: var(--sw-color-action);
        --color-selected: var(--sw-color-action-contrast);

        --max-height: 208px;
        --background-list: var(--sw-color-white);
    }

    &_control-dd {
        --max-height: 208px;
        --border-radius-list: var(--sw-border-radius);
        --border-width-list: 1px;
        --border-style-list: solid;
        --border-color-list: var(--sw-background-medium);
        --padding-top-list: 12px;
        --padding-bottom-list: 12px;
        --margin-top-list: 3px;
        --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);

        &-wrapper {
            --height: 36px;
            --padding-bottom: calc(var(--sw-multiple-px) * 2);
        }

        .sw-item_group {
            --background-hover: var(--background);
            --background-selected: var(--background);

            --color: var(--sw-text-color-secondary);
            --color-hover: var(--color);
            --color-selected: var(--color);
            user-select: none;
        }

        .sw-dropdown & {
            sw-button.sw-item {
                --background: transparent;
                --background-hover: var(--sw-background-dark);
                --padding-start: 12px;
                --padding-end: 12px;
                --padding-top: 8px;
                --padding-bottom: 8px;
                text-align: left;

                &::part(inner) {
                    --padding-start: 0;
                    --padding-end: 0;
                    justify-content: flex-start;
                }

                &:active:hover {
                    &::part(inner) {
                        background: var(--background-selected);
                        color: var(--color-selected);
                    }
                }
            }
        }

        .sw-block_on-map & {
            --box-shadow: 0;
            --border-width-list: 0;
        }
    }

    &_gallery {
        > .sw-item {
            --margin-end: var(--sw-multiple-px);
        }

        .sw-figure {
            // --width: 30.64%;
            --width: 271px;

            &_medium {
                // --width: 68.28%;
                --width: 604px;
            }
        }
    }

    &_between {
        > .sw-item {
            --margin-bottom: var(--sw-multiple-px);
        }

        &_dot-leaders {
            > .sw-item {
                --margin-top: 0;
                --margin-bottom: calc(var(--sw-multiple-px) * 5);

                &:last-child {
                    --margin-bottom: 0;
                }
            }
            --color-dot: var(--sw-text-color-secondary);
        }
    }

    &_underline {
        --margin-bottom: 0;
        --padding-top: calc(var(--sw-multiple-px) * 2);
        --padding-bottom: calc(var(--sw-multiple-px) * 2);
        --border-width: 0 0 1px 0;
        --border-style: solid;
        --border-color: var(--sw-border-color);
        --border-radius: 0;
    }

    &_info {
        --margin-bottom: calc(var(--sw-multiple-px) * 3);
    }

    &_menu {
        --multiple: 4;
        --margin-bottom: 8px;
        --padding-top: calc(var(--sw-multiple-px) * var(--multiple));
        --padding-bottom: calc(var(--sw-multiple-px) * var(--multiple));
        --padding-start: calc(var(--sw-multiple-px) * var(--multiple));
        --padding-end: calc(var(--sw-multiple-px) * var(--multiple));
        --border-radius: 0;
        --background-hover: var(--sw-background-info);
        --color-hover: var(--color);

        .sw-item {
            &--disabled {
                opacity: 0.5;
            }
        }

        sw-icon {
            flex-shrink: 0;
            --sw-icon-medium-size: 20px;
            font-size: var(--sw-icon-medium-size);
            margin-right: calc(var(--sw-multiple-px) * var(--multiple));
        }

        sw-button {
            --color: var(--sw-text-color);
            color: var(--color);

            &:not(.sw-item) {
                --padding-start: calc(var(--sw-multiple-px) * var(--multiple));
                --padding-end: calc(var(--sw-multiple-px) * var(--multiple));
                --padding-top: calc(var(--sw-multiple-px) * var(--multiple));
                --padding-bottom: calc(var(--sw-multiple-px) * var(--multiple));
            }
        }

        // subtype
        &_rounded {
            --multiple: 2;
            --border-radius: var(--sw-border-radius);
            --margin-bottom-list: 32px;

            .sw-item {
                --font-size: 14px;
                --font-weight: 500;
                display: flex;
                align-items: center;

                &:hover,
                &.active {
                    sw-icon {
                        background: transparent;
                    }
                }

                .sw-text-size_xs {
                    font-weight: 400;
                }
            }

            sw-icon {
                padding: 10px;
                background: var(--sw-background-medium);
                margin-right: calc(var(--sw-multiple-px) * 3);
            }
        }
    }

    &_checkable {
        --margin-bottom: 16px;

        .sw-list .sw-item {
            --margin-bottom: 8px;
        }
    }

    &_overline {
        --margin-bottom: 0;
        --padding-top: calc(var(--sw-multiple-px) * 8);
        --padding-bottom: calc(var(--sw-multiple-px) * 8);
        --border-width: 1px 0 0 0;
        --border-style: solid;
        --border-color: var(--sw-border-color);
        --border-radius: 0;
    }

    &_e-sign {
        --margin-bottom: calc(var(--sw-multiple-px) * 10);
        --margin-top-dl: calc(var(--sw-multiple-px) * 4);

        .sw-e-sign + div {
            width: 100%;
        }

        textarea {
            border-style: dashed;
            border-color: var(--sw-placeholder-color);
            border-radius: var(--sw-border-radius);

            resize: vertical;
            width: 100%;
        }
    }

    &_carrier-task {
        @extend .sw-list_underline;
        --padding-top: calc(var(--sw-multiple-px) * 6);
        --padding-bottom: calc(var(--sw-multiple-px) * 6);

        .sw-item {
            &:first-child {
                --padding-top: 0;
            }

            &:last-child {
                --border-width: 0;
                --padding-bottom: 0;

                .sw-grid_flight-head:last-child {
                    > sw-row > sw-col {
                        --sw-grid-column-padding-bottom: 0;
                    }
                }
            }
        }

        app-carrier-tasks-task:not(:first-of-type) .sw-block_info {
            --margin-top: calc(var(--sw-multiple-px) * 2);
        }
    }

    &_history {
        > .sw-item {
            --margin-bottom: calc(var(--sw-multiple-px) * 7);
            --padding-start: calc(var(--sw-multiple-px) * 8);

            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 5px;
                width: 9px;
                height: 9px;
                border: 1px solid var(--sw-placeholder-color);
                border-radius: 50%;
            }

            &::after {
                content: '';
                position: absolute;
                left: 5px;
                top: 16px;
                bottom: calc(var(--margin-bottom) * -1 - 5px);
                width: 1px;
                background: var(--sw-placeholder-color);
            }

            &:first-child {
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }

            app-browse-default {
                width: 156px;
                margin-right: calc(var(--sw-multiple-px) * 8);

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &.sw-color {
            > .sw-item {
                &::before {
                    border-color: var(--sw-color-base);
                    background: var(--sw-color-base);
                }
                &::after {
                    background: var(--sw-color-base);
                }
            }
        }
    }

    &_ticket {
        @extend .sw-list_history;

        > .sw-item {
            &::before {
                top: 3px;
                width: 12px;
                height: 12px;
                border: 2px solid var(--sw-color-primary);
            }

            &::after {
                left: 7.5px;
                top: 25px;
                bottom: calc(var(--margin-bottom) * -1 + 3px);
            }
            &:last-child {
                &::before {
                    border-color: var(--sw-color-action);
                }
            }
        }
    }

    &_trip {
        @extend .sw-list_history;
        --margin-start-list: 16px;
        --margin-top-list: 12px;

        > .sw-item {
            --padding-start: 16px;
            --margin-bottom: 12px;
            --font-size: 13px;
            --line-height: 16px;

            &::before {
                top: 3px;
                width: 5px;
                height: 5px;
                background: var(--sw-color-base, var(--sw-placeholder-color));
                // border: 2px solid var(--sw-color-primary);
            }

            &::after {
                left: 3px;
                top: 10px;
                // bottom: calc(var(--margin-bottom) * -1 + 3px);
            }
            &:last-child {
                &::before {
                    border-color: var(--sw-color-base, var(--sw-placeholder-color));
                }
            }
        }

        &_time {
            > .sw-item {
                --padding-start: 66px;

                .sw-item__time {
                    --line-height: 20px;
                    position: absolute;
                    left: 0;
                }

                &::before {
                    left: 50px;
                }
                &::after {
                    left: 53px;
                }
            }
        }
    }

    &_vtabs {
        --color: var(--sw-text-color);
        // --color-hover: var(--color);
        // --color-selected: var(--color);
        --font-size: 13px;
        --font-weight: 400;
        --line-height: 20px;
        --padding-start: calc(var(--sw-multiple-px) * 4 - 2px);
        --padding-end: calc(var(--sw-multiple-px) * 5);
        --padding-top: calc(var(--sw-multiple-px) * 2);
        --padding-bottom: calc(var(--sw-multiple-px) * 2);
        --border-width: 0 0 0 2px;
        --border-color: transparent;
        --border-style: solid;
        --border-radius: 0;

        aside & {
            --margin-start-list: calc(var(--sw-multiple-px) * -4);
            --margin-top-list: calc(var(--sw-multiple-px) * 2);
        }

        aside.sw-aside_left & {
            --margin-start-list: calc(var(--sw-multiple-px) * -5);
        }

        .sw-item {
            cursor: pointer;

            &--active {
                --font-weight: 500;
                --border-color: var(--sw-text-color);
            }
        }
    }

    &_search-org {
        @extend .sw-list_underline;
        --margin-top-list: 56px;

        > .sw-item {
            display: flex;
        }

        sw-icon {
            margin-right: 16px;
            color: var(--sw-placeholder-color);
        }

        .sw-list_bullet {
            --margin-list-top: 8px;
            --font-size: 13px;
            --line-height: 20px;
        }
    }
}
