.swh-text-center {
    text-align: center !important;
}

.swh-text-justify {
    text-align: justify !important;
}

.swh-text-start {
    text-align: start !important;
}

.swh-text-end {
    text-align: end !important;
}

.swh-text-left {
    text-align: left !important;
}

.swh-text-right {
    text-align: right !important;
}

.swh-text-nowrap {
    white-space: nowrap !important;
}

.swh-text-wrap {
    white-space: normal !important;
}

.swh-text-prewrap {
    white-space: pre-wrap !important;
}

@media (min-width: 576.98px) {
    .swh-text-sm-center {
        text-align: center !important;
    }

    .swh-text-sm-justify {
        text-align: justify !important;
    }

    .swh-text-sm-start {
        text-align: start !important;
    }

    .swh-text-sm-end {
        text-align: end !important;
    }

    .swh-text-sm-left {
        text-align: left !important;
    }

    .swh-text-sm-right {
        text-align: right !important;
    }

    .swh-text-sm-nowrap {
        white-space: nowrap !important;
    }

    .swh-text-sm-wrap {
        white-space: normal !important;
    }

    .swh-text-sm-prewrap {
        white-space: pre-wrap !important;
    }
}
@media (min-width: 768px) {
    .swh-text-md-center {
        text-align: center !important;
    }

    .swh-text-md-justify {
        text-align: justify !important;
    }

    .swh-text-md-start {
        text-align: start !important;
    }

    .swh-text-md-end {
        text-align: end !important;
    }

    .swh-text-md-left {
        text-align: left !important;
    }

    .swh-text-md-right {
        text-align: right !important;
    }

    .swh-text-md-nowrap {
        white-space: nowrap !important;
    }

    .swh-text-md-wrap {
        white-space: normal !important;
    }

    .swh-text-md-prewrap {
        white-space: pre-wrap !important;
    }
}
@media (min-width: 992px) {
    .swh-text-lg-center {
        text-align: center !important;
    }

    .swh-text-lg-justify {
        text-align: justify !important;
    }

    .swh-text-lg-start {
        text-align: start !important;
    }

    .swh-text-lg-end {
        text-align: end !important;
    }

    .swh-text-lg-left {
        text-align: left !important;
    }

    .swh-text-lg-right {
        text-align: right !important;
    }

    .swh-text-lg-nowrap {
        white-space: nowrap !important;
    }

    .swh-text-lg-wrap {
        white-space: normal !important;
    }

    .swh-text-lg-prewrap {
        white-space: pre-wrap !important;
    }
}
@media (min-width: 1200px) {
    .swh-text-xl-center {
        text-align: center !important;
    }

    .swh-text-xl-justify {
        text-align: justify !important;
    }

    .swh-text-xl-start {
        text-align: start !important;
    }

    .swh-text-xl-end {
        text-align: end !important;
    }

    .swh-text-xl-left {
        text-align: left !important;
    }

    .swh-text-xl-right {
        text-align: right !important;
    }

    .swh-text-xl-nowrap {
        white-space: nowrap !important;
    }

    .swh-text-xl-wrap {
        white-space: normal !important;
    }

    .swh-text-xl-prewrap {
        white-space: pre-wrap !important;
    }
}
