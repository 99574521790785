.sw-form-group {
    @include props-margin;
    @include margin;
}

// implemented
.sw-form-group {
    --margin-bottom: calc(var(--sw-multiple-px) * 7);

    &:last-of-type {
        --margin-bottom: 0;
    }
}
