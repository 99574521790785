.swh-align-self-start {
    align-self: flex-start !important;
}

.swh-align-self-end {
    align-self: flex-end !important;
}

.swh-align-self-center {
    align-self: center !important;
}

.swh-align-self-stretch {
    align-self: stretch !important;
}

.swh-align-self-baseline {
    align-self: baseline !important;
}

.swh-align-self-auto {
    align-self: auto !important;
}

.swh-flex-wrap {
    flex-wrap: wrap !important;
}

.swh-flex-nowrap {
    flex-wrap: nowrap !important;
}

.swh-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.swh-justify-content-start {
    justify-content: flex-start !important;
}

.swh-justify-content-center {
    justify-content: center !important;
}

.swh-justify-content-end {
    justify-content: flex-end !important;
}

.swh-justify-content-around {
    justify-content: space-around !important;
}

.swh-justify-content-between {
    justify-content: space-between !important;
}

.swh-justify-content-evenly {
    justify-content: space-evenly !important;
}

.swh-align-items-start {
    align-items: flex-start !important;
}

.swh-align-items-center {
    align-items: center !important;
}

.swh-align-items-end {
    align-items: flex-end !important;
}

.swh-align-items-stretch {
    align-items: stretch !important;
}

.swh-align-items-baseline {
    align-items: baseline !important;
}

.swh-shrink {
    flex-shrink: 0;
}

.swh-flex-row {
    flex-direction: row;
}

.swh-flex-column {
    @extend .swh-flex;
    flex-direction: column;
}

.swh-flex-row-revers {
    @extend .swh-flex;
    flex-direction: row-reverse;
}

.swh-flex-column-reverse {
    @extend .swh-flex;
    flex-direction: column-reverse;
}

