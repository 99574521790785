.sw-alert {
    @include props-position;
    @include props-block-model;
    @include props-width;

    @include position;
    @include block-model;
    @include width;

    position: fixed;
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sw-alert {
    --top: 0;
    --bottom: 0;
    --left: 0;
    --right: 0;

    .sw-toast {
        --max-width: 100%;
        --width: 976px;
        // --padding-top: calc(var(--sw-padding) * 3);
        // --padding-bottom: calc(var(--sw-padding) * 3);
        // --padding-start: calc(var(--sw-padding) * 3);
        // --padding-end: calc(var(--sw-padding) * 3);
        padding: calc(var(--sw-padding) * 3);

        &__title {
            font-size: 22px;
            line-height: 32px;
        }

        &__message {
            p {
                @extend .sw-text-size_m;
                margin-top: var(--sw-margin);

                strong {
                    font-weight: 500;
                }
            }
        }

        .sw-form .sw-map-wrapper {
            --height: 180px;
        }

        &.sw-alert_small {
            --max-width: 664px;

            .sw-toast__message {
                color: var(--sw-text-color);
            }
        }
    }

    &_medium {
        .sw-toast {
            --width: 664px;
        }
    }

    & + .sw-backdrop {
        --background: var(--sw-background-medium);
        --opacity: 1;
    }

    #cadesplugin_ovr & {
        .sw-toast {
            --width: 450px;
            position: relative;

            &__close {
                position: absolute;
                right: 16px;
                top: 16px;
            }
        }
    }

    @media (max-width: 576px) {
        top: auto;
        left: 8px;
        right: 8px;
        bottom: 8px;
        z-index: 1102;

        + .sw-backdrop {
            z-index: 1101;
        }
    }
}
