.sw-checkbox {
    /**
     * @prop --background: фон флажка
     * @prop --background-checked: фон при установленном флажке
     * @prop --background-disabled: фон при отключенном флажке
     * @prop --disabled-opacity: прозрачность чекбокса при отключенном флажке
     *
     * @prop --color: цвет текста чекбокса
     * @prop --color-checked: цвет текста при установленном флажке
     * @prop --color-disabled: цвет текста при отключенном флажке
     *
     * @prop --transition: переход
     *
     * @prop --border-radius: радиус скругления
     * @prop --border-width: толщина границы
     * @prop --border-style: стиль границы
     * @prop --border-color: цвет границы
     * @prop --border-color-checked: цвет границы при установленном флажке
     * @prop --border-color-disabled: цвет границы при отключенном флажке
     *
     * @prop --checkmark-color: Цвет галочки флажка при установленном флажке
     *
     * @prop --size: Размер значка флажка.
     */

    --background-checked: var(--sw-color-primary);
    --background-disabled: initial;
    --border-color-checked: var(--sw-color-primary);
    --border-color-disabled: initial;
    --color-checked: var(--color);
    --color-disabled: var(--color);
    --checkmark-color: var(--sw-color-primary-contrast);
    --transition: none;
    position: relative;
    user-select: none;
    z-index: 2;

    &.sw-color {
        --background-checked: var(--sw-color-base);
        --border-color-checked: var(--sw-color-base);
        --checkmark-color: var(--sw-color-contrast);
    }

    label {
        position: relative;
    }

    &__label {
        position: relative;
        display: flex;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        border: 0;
        background: transparent;
        cursor: pointer;
        appearance: none;
        outline: none;
        align-items: flex-start;

        &::-moz-focus-inner {
            border: 0;
        }

        &::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' viewBox='0 0 11 8'%3E%3Cpath d='M0 4.17391L0.6875 3.47826L3.78125 6.6087L10.3125 0L11 0.695652L3.78125 8L0 4.17391Z' fill='white' /%3E%3C/svg%3E");
            flex-shrink: 0;
            border-radius: var(--border-radius);
            position: relative;
            width: var(--size);
            height: var(--size);
            margin-left: calc(var(--size) / 2);
            margin-right: calc(var(--size) / 2);
            transition: var(--transition);
            border-width: var(--border-width);
            border-style: var(--border-style);
            border-color: var(--border-color);
            background: var(--background);
            box-sizing: border-box;
            text-align: center;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        clip: rect(0 0 0 0);
        opacity: 0;
        overflow: hidden;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:checked + .sw-checkbox__label {
            color: var(--color-checked);

            &::before{
                // content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' viewBox='0 0 11 8'%3E%3Cpath d='M0 4.17391L0.6875 3.47826L3.78125 6.6087L10.3125 0L11 0.695652L3.78125 8L0 4.17391Z' fill='white' /%3E%3C/svg%3E");
                border-color: var(--border-color-checked);
                background: var(--background-checked);

                color: var(--checkmark-color);
            }
        }

        &:disabled + .sw-checkbox__label {
            pointer-events: none;
            color: var(--color-disabled);

            &::before {
                opacity: var(--disabled-opacity);
            }
        }
        &:disabled:not(:checked) + .sw-checkbox__label {

            &::before {
                content: '';
                background: var(--background-disabled);
                border-color: var(--border-color-disabled);
            }
        }
    }

    // types
    &_right {
        direction: rtl;
        text-align: left;

        .sw-checkbox__label {
            justify-content: flex-end;
        }
    }
}

// implemented
.sw-checkbox {
    --border-radius: var(--sw-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--sw-border-color-dark);
    --background: transparent;
    --background-checked: var(--sw-color-action);
    --background-disabled: var(--sw-background-dark);
    --border-color-checked: var(--sw-color-action);
    --border-color-disabled: var(--sw-border-color-dark);
    --color: var(--sw-text-color);
    --color-disabled: var(--sw-text-color-secondary);
    --size: 16px;
    --disabled-opacity: 0.5;

    &__label {
        margin-right: calc(var(--sw-multiple-px) * 3);

        app-order-position-date-group-item &,
        app-contract-order-position-date-group-item & {
            margin-right: 0;
        }

        &::before {
            line-height: 14px;

            @media only screen and ( -webkit-min-device-pixel-ratio: 2 ){
                line-height: 16px;
            }
        }

        &.sw-label-hidden {
            margin-right: 0;
        }
    }

    &:not(.swh-inline-block, .sw-item) + &:not(.swh-inline-block, .sw-item),
    .sw-control__label + &:not(.swh-inline-block, .sw-item) {
        margin-top: calc(var(--sw-multiple-px) * 5);
    }

    .sw-control &,
    .sw-list_control-dd & {
        --background-selected: var(--background);
        --color-selected: var(--color-checked);
        --border-radius: var(--sw-border-radius);

        border: 0;

        .sw-checkbox__label {
            &::before {
                margin-left: 0;
                margin-right: calc(var(--size) / 2);
            }
        }

        &.sw-checkbox_right {
            .sw-checkbox__label {
                &::before {
                    margin-right: 0;
                    margin-left: calc(var(--size) / 2);
                }
            }
        }

        &.sw-item {
            border-radius: 0;
        }

        &.sw-item--selected {

            .sw-checkbox__label::before{
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' viewBox='0 0 11 8'%3E%3Cpath d='M0 4.17391L0.6875 3.47826L3.78125 6.6087L10.3125 0L11 0.695652L3.78125 8L0 4.17391Z' fill='white' /%3E%3C/svg%3E");
                border-color: var(--border-color-checked);
                background: var(--background-checked);

                color: var(--checkmark-color);
            }
        }
    }
}
