// https://web-standards.ru/articles/native-and-custom-select/
// Both native and custom selects must have the same width/height.
.sw-select-native,
.sw-select {
    position: relative;
    width: 100%;
    height: 100%;
}

// Make sure the custom select does not mess with the layout
.sw-select {
    --padding-start: initial;
    --padding-end: initial;
    --border-radius: initial;
    --background: initial;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    padding-left: var(--padding-start);
    padding-right: var(--padding-end);
    border-radius: var(--border-radius);
}

// This media query detects devices where the primary
// input mechanism can hover over elements. (e.g. computers with a mouse)
// @media (hover: hover) {
// Since we are using a mouse, it's safe to show the custom select.
.sw-select {
    display: block;
}

// In a computer using keyboard? Then let's hide back the custom select
// while the native one is focused:
.sw-select-native:focus + .sw-select {
    display: none;
}
// }

//
// Rest of the styles to create the custom select.
// Just make sure the native and the custom have a similar "box" (the trigger).
//

.sw-select-native {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    outline: 0;
    margin-left: 24px; // невидимый запас под возможную иконку
    // TODO: подумать, как лучше оформить, так как иконка может быть не всегда

    app-select-filter & {
        margin-left: 0;
    }
}

.sw-select__trigger {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--background);
    cursor: pointer;

    span {
        @include text-ellipsis;
    }

    & + .sw-control__field {
        --padding-field-start: 0;
        --padding-field-end: 0;
        position: absolute;
        top: 0;
        opacity: 0;
        background: var(--sw-background);

        &--focused {
            opacity: 1;
        }
    }
}

.sw-select {
    --padding-start: 12px;
    --padding-end: 12px;
    --border-radius: var(--sw-border-radius);
    --background: var(--sw-color-white);

    &_secondary {
        --background: var(--sw-color-info-tint);
        --padding-end: 0;
        color: var(--color);

        .sw-select__trigger {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: var(--border-radius);

            app-reservations & {
                text-overflow: initial;
                overflow: visible;
            }

            sw-icon {
                --size-icon: 20px;
                flex-shrink: 0;
                color: var(--color);

                &:first-child {
                    margin-right: 4px;
                }
            }
        }
    }

    &_text {
        // position: relative;
        display: inline-block;
        margin: 4px 0;

        .sw-select__label {
            margin-right: 8px;
            // вид отхода на этапе создания заявки
            // font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }

        .sw-list_control-dd {
            top: 16px;
            // right: 0;
            left: 0;
            width: auto;
            min-width: 140px;

            .sw-checkbox {
                min-width: 160px;

                &__label {
                    margin-right: 0;
                }
            }
        }
    }

    @media (max-width: 992px) {
        &_secondary {
            --background: var(--sw-background-medium);
        }
    }
}

.sw-control_select-secondary {
    
}
