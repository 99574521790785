.sw-scheduler {
    @include props-width;

    @include width;
}

.sw-scheduler {
    --margin-bottom-list: 12px;
    --width: 348px;
    --max-width: 100%;

    &.sw-list {
        --padding-top: var(--sw-multiple-px);
        --padding-bottom: var(--sw-multiple-px);

        .sw-scheduler__day {
            display: inline-block;
            width: 22px;

            + span {
                margin-left: calc(var(--sw-multiple-px) * 3);
            }
        }
    }

    @media (max-width: 780px) {
        --width: 100%;
    }

    @media (max-width: 576px) {
        --margin-bottom-list: 0;

        &.sw-list  {
            .sw-item {
                display: block;

                > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                > span {
                    display: block;
                    text-align: right;
                }
            }
        }
    }
}
