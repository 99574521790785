.sw-gallery {
    &-preview {
        @include props-width-height;
        @include width-height;

        position: relative;

        &__more {
            /*
            * @prop --opacity: прозрачность кнопки more
            * @prop --border-radius: скругление кнопки more
            */
            --opacity: initial;
            --border-radius: initial;

            @include props-position;
            @include props-width-height;
            @include props-colors;
            @include props-font;
            @include position;
            @include width-height;
            @include colors;
            @include font;

            position: absolute;
            background: rgba(var(--background), var(--opacity));
            border-radius: var(--border-radius);
            text-align: center;
        }
    }

    &-slider {
        @include props-width;
        @include width;

        position: relative;

        > sw-button {
            --border-radius: 50%;
            position: absolute;
            z-index: 150; // так как галерея содержит карту, и кнопка должна быть над картой.
            top: 50%;
            margin-top: -18px;

            &.sw-gallery-slider__prev {
                left: -18px;
            }
            &.sw-gallery-slider__next {
                right: -18px;
            }
        }

        &__content {
            will-change: transform;
            transition: margin-left 300ms;
        }
    }

    &-single {
        @include props-width;
        @include width;

        position: relative;

        > sw-button {
            position: absolute;
            top: 50%;
            margin-top: -18px;

            &.sw-gallery-single__prev {
                left: 20px;
            }
            &.sw-gallery-single__next {
                right: 20px;
            }
        }
    }
}

.sw-gallery {
    &-preview {
        --width: 161px;
        --height: 120px;

        .sw-figure {
            --width: 161px;
            --height: 120px;
        }

        &__more {
            --top: 0;
            --bottom: 0;
            --right: 0;
            --width: 28px;
            --height: 120px;
            --background: var(--sw-color-black-rgb);
            --opacity: 0.3;
            --border-radius: 0px 4px 4px 0px;
            --line-height: var(--height);
            --font-size: 11px;
            --font-weight: 500;
            --color: var(--sw-color-white);
        }
    }

    &-slider {
        --width: 100%;

        &_square .sw-list_gallery {
            > .sw-item {
                --margin-end: 4px;
            }

            .sw-figure {
                --width: 260px;
                --height: 260px;
                --border-width: 0;
                cursor: pointer;

                sw-button {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                }

                &_medium {
                    --width: 336px;
                }
                &_btn {
                    --border-width: 1px;
                    display: flex;
                }
            }
        }
    }

    &-single {
        .sw-figure {
            --height: 455px;
            --width: 100%;
            margin: 0;
        }

        sw-button.sw-color-action {
            --border-color: var(--sw-color-action);
            --border-color-hover: var(--sw-color-action);
        }
    }

    @media (min-width: 1180.98px), (max-width: 1024px) and (min-width: 820.98px) {
        &-preview {
            app-wasteplace-widget .sw-dl_inline &,
            app-facility-widget .sw-dl_inline & {
                display: none;
            }
        }
    }

    @media (max-width: 1180px) and (min-width: 1024.98px), (max-width: 820px) {
        &-preview {
            app-wasteplace-widget &:not(.sw-dl_inline &),
            app-facility-widget &:not(.sw-dl_inline &) {
                display: none;
            }

            app-wasteplace-widget .sw-dl_inline &,
            app-facility-widget .sw-dl_inline & {
                --height: 106px;
                --width: 156px;
                margin-left: 8px;

                .sw-figure {
                    --height: 106px;
                    --width: 156px;
                }

                .sw-gallery-preview__more {
                    --height: 106px;
                }
            }
        }
    }

    @media (max-width: 576px) {
        &-preview {
            app-wasteplace-widget .sw-dl_inline &,
            app-facility-widget .sw-dl_inline & {
                --height: 72px;
                --width: 104px;

                .sw-figure {
                    --height: 72px;
                    --width: 104px;
                }

                .sw-gallery-preview__more {
                    --height: 72px;
                }
            }
        }
    }
}
