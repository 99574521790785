.swh-no-padding {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.swh-padding {
    --sw-padding: calc(var(--sw-multiple-px) * var(--sw-coef));

    --padding-start: var(--sw-padding, 12px);
    --padding-end: var(--sw-padding, 12px);
    --padding-top: var(--sw-padding, 12px);
    --padding-bottom: var(--sw-padding, 12px);
    padding-left: var(--sw-padding, 12px) !important;
    padding-right: var(--sw-padding, 12px) !important;
    padding-top: var(--sw-padding, 12px) !important;
    padding-bottom: var(--sw-padding, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .swh-padding {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: var(--sw-padding, 12px) !important;
        padding-inline-start: var(--sw-padding, 12px) !important;
        -webkit-padding-end: var(--sw-padding, 12px) !important;
        padding-inline-end: var(--sw-padding, 12px) !important;
    }
}

.swh-padding-top {
    --sw-padding: calc(var(--sw-multiple-px) * var(--sw-coef));
    --padding-top: var(--sw-padding, 12px);
    padding-top: var(--sw-padding, 12px) !important;
}
.swh-padding-start {
    --sw-padding: calc(var(--sw-multiple-px) * var(--sw-coef));
    --padding-start: var(--sw-padding, 12px);
    padding-left: var(--sw-padding, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .swh-padding-start {
        padding-left: unset;
        -webkit-padding-start: var(--sw-padding, 12px) !important;
        padding-inline-start: var(--sw-padding, 12px) !important;
    }
}

.swh-padding-end {
    --sw-padding: calc(var(--sw-multiple-px) * var(--sw-coef));
    --padding-end: var(--sw-padding, 12px);
    padding-right: var(--sw-padding, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .swh-padding-end {
        padding-right: unset;
        -webkit-padding-end: var(--sw-padding, 12px) !important;
        padding-inline-end: var(--sw-padding, 12px) !important;
    }
}

.swh-padding-bottom {
    --sw-padding: calc(var(--sw-multiple-px) * var(--sw-coef));
    --padding-bottom: var(--sw-padding, 12px);
    padding-bottom: var(--sw-padding, 12px) !important;
}
.swh-padding-vertical {
    --sw-padding: calc(var(--sw-multiple-px) * var(--sw-coef));
    --padding-top: var(--sw-padding, 12px);
    --padding-bottom: var(--sw-padding, 12px);
    padding-top: var(--sw-padding, 12px) !important;
    padding-bottom: var(--sw-padding, 12px) !important;
}
.swh-padding-horizontal {
    --sw-padding: calc(var(--sw-multiple-px) * var(--sw-coef));
    --padding-start: var(--sw-padding, 12px);
    --padding-end: var(--sw-padding, 12px);
    padding-left: var(--sw-padding, 12px) !important;
    padding-right: var(--sw-padding, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .swh-padding-horizontal {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: var(--sw-padding, 12px) !important;
        padding-inline-start: var(--sw-padding, 12px) !important;
        -webkit-padding-end: var(--sw-padding, 12px) !important;
        padding-inline-end: var(--sw-padding, 12px) !important;
    }
}

.swh-no-margin {
    --margin-start: 0;
    --margin-end: 0;
    --margin-top: 0;
    --margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.swh-margin {
    --sw-margin: calc(var(--sw-multiple-px) * var(--sw-coef));
    --margin-start: var(--sw-margin, 12px);
    --margin-end: var(--sw-margin, 12px);
    --margin-top: var(--sw-margin, 12px);
    --margin-bottom: var(--sw-margin, 12px);
    margin-left: var(--sw-margin, 12px) !important;
    margin-right: var(--sw-margin, 12px) !important;
    margin-top: var(--sw-margin, 12px) !important;
    margin-bottom: var(--sw-margin, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .swh-margin {
        margin-left: unset !important;
        margin-right: unset !important;
        -webkit-margin-start: var(--sw-margin, 12px) !important;
        margin-inline-start: var(--sw-margin, 12px) !important;
        -webkit-margin-end: var(--sw-margin, 12px) !important;
        margin-inline-end: var(--sw-margin, 12px) !important;
    }
}

.swh-margin-top {
    --sw-margin: calc(var(--sw-multiple-px) * var(--sw-coef));
    --margin-top: var(--sw-margin, 12px) !important;
    margin-top: var(--sw-margin, 12px) !important;
}
.swh-margin-start {
    --sw-margin: calc(var(--sw-multiple-px) * var(--sw-coef));
    --margin-start: var(--sw-margin, 12px);
    margin-left: var(--sw-margin, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .swh-margin-start {
        margin-left: unset !important;
        -webkit-margin-start: var(--sw-margin, 12px) !important;
        margin-inline-start: var(--sw-margin, 12px) !important;
    }
}

.swh-margin-end {
    --sw-margin: calc(var(--sw-multiple-px) * var(--sw-coef));
    --margin-end: var(--sw-margin, 12px);
    margin-right: var(--sw-margin, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .swh-margin-end {
        margin-right: unset !important;
        -webkit-margin-end: var(--sw-margin, 12px) !important;
        margin-inline-end: var(--sw-margin, 12px) !important;
    }
}

.swh-margin-bottom {
    --sw-margin: calc(var(--sw-multiple-px) * var(--sw-coef));
    --margin-bottom: var(--sw-margin, 12px);
    margin-bottom: var(--sw-margin, 12px) !important;
}
.swh-margin-vertical {
    --sw-margin: calc(var(--sw-multiple-px) * var(--sw-coef));
    --margin-top: var(--sw-margin, 12px);
    --margin-bottom: var(--sw-margin, 12px);
    margin-top: var(--sw-margin, 12px) !important;
    margin-bottom: var(--sw-margin, 12px) !important;
}
.swh-margin-horizontal {
    --sw-margin: calc(var(--sw-multiple-px) * var(--sw-coef));
    --margin-start: var(--sw-margin, 12px);
    --margin-end: var(--sw-margin, 12px);
    margin-left: var(--sw-margin, 12px) !important;
    margin-right: var(--sw-margin, 12px) !important;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .swh-margin-horizontal {
        margin-left: unset !important;
        margin-right: unset !important;
        -webkit-margin-start: var(--sw-margin, 12px) !important;
        margin-inline-start: var(--sw-margin, 12px) !important;
        -webkit-margin-end: var(--sw-margin, 12px) !important;
        margin-inline-end: var(--sw-margin, 12px) !important;
    }
}

.swh-no-border {
    border: none !important;
}
