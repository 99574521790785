dl {
    --width-dt: initial;
    --width-dd: initial;
    --padding-end-dt: initial;
    --padding-start-dd: initial;
    --margin-bottom-dt: initial;
    --margin-bottom-dd: initial;
    --margin-top-dl: initial;
    --margin-bottom-dl: initial;

    --color-dt: inherit;
    --color-dd: inherit;

    margin-top: var(--margin-top-dl);
    margin-bottom: var(--margin-bottom-dl);

    dt {
        padding-right: var(--padding-end-dt);
        margin: 0 0 var(--margin-bottom-dt);

        color: var(--color-dt);
    }

    dd {
        margin: 0 0 var(--margin-bottom-dd);
        color: var(--color-dd);
    }

    &.sw-dl_inline {
        clear: both;
        overflow: hidden;

        dt {
            float: left;
            clear: left;
            width: var(--width-dt);
        }

        dd {
            float: left;
            width: var(--width-dd);
            overflow: hidden;
        }
    }

    &.sw-dl_dot-leaders {
        @extend .sw-dl_inline;
        --color-dot: inherit;

        dt {
            display: inline-flex;
            padding-right: 0;

            & > * {
                flex-shrink: 0;
            }

            &::after {
                content: '';
                position: relative;
                width: 100%;
                top: -7px;
                opacity: 0.5;
                margin-left: 2px;
                border-bottom: 1px dotted var(--color-dot);
            }
        }
        dd {
            padding-left: var(--padding-start-dd);
        }
    }

    &.sw-dl_easy {
        dt {
        }
        dd {
        }
    }
}

// implemented
dl {
    &.sw-dl_inline {
        --width-dt: 184px;
        --width-dd: calc(100% - var(--width-dt));
        --padding-end-dt: calc(var(--sw-multiple-px) * 4);
        --margin-bottom-dt: calc(var(--sw-multiple-px) * 3);
        --margin-bottom-dd: calc(var(--sw-multiple-px) * 3);
        --color-dt: var(--sw-text-color-secondary);
        line-height: 20px;
        font-size: 13px;

        .sw-modal & {
            --margin-bottom-dt: calc(var(--sw-multiple-px) * 3);
            --margin-bottom-dd: calc(var(--sw-multiple-px) * 3);
        }

        .sw-coordinates {
            margin-bottom: 0;
        }

        dd {
            .sw-list_between > .sw-item {
                --margin-bottom-dd: 0;
            }
        }

        .sw-section &:last-child {
            dt:last-of-type,
            dd:last-of-type {
                --margin-bottom-dt: 0;
                --margin-bottom-dd: 0;
            }
        }

        &_short {
            @extend .sw-dl_inline;
            --width-dt: 80px;
            --color-dt: var(--sw-text-color);
            --margin-bottom-dt: 4px;
            --margin-bottom-dd: 4px;
            font-size: 14px;
        }
    }

    &.sw-dl_dot-leaders {
        --width-dt: 32%;
        --width-dd: 60%;
        --padding-start-dd: calc(var(--sw-multiple-px) * 6);
        --margin-bottom-dt: calc(var(--sw-multiple-px) * 4);
        --margin-bottom-dd: calc(var(--sw-multiple-px) * 4);
        --color-dt: rgba(var(--sw-text-color-rgb, 0.6));
        --color-dot: var(--sw-text-color-secondary);
        --margin-bottom-dl: 0;

        &_nowrap {
            @extend .sw-dl_dot-leaders;
            dt {
                white-space: nowrap;
            }
        }

        dt:last-of-type,
        dd:last-of-type {
            --margin-bottom-dt: 0;
            --margin-bottom-dd: 0;
        }

        .sw-list_bullet {
            --color: var(--sw-text-color);

            sw-link {
                font-weight: 400;
            }
        }

        .sw-section-sign & {
            --width-dt: 35%;
        }
    }

    &.sw-dl_medium {
        // --width-dt: 30.6%;
        // --width-dd: 50%;
        clear: left;
    }

    &.sw-dl_easy {
        --margin-bottom-dt: var(--sw-multiple-px);
        --margin-bottom-dd: calc(var(--sw-multiple-px) * 3);
        --color-dt: var(--sw-text-color-secondary);
        margin: 0;
        line-height: 20px;

        dt {
            font-size: 11px;
            line-height: 12px;
        }
    }

    @media (max-width: 576px) {
        &.sw-dl_inline {
            --margin-bottom-dt: 0;

            dt, dd {
                float: none;
                clear: none;
                width: 100%
            }

            dd:last-child {
                margin-bottom: 0;
            }
        }
    }
}
