.sw-tab {
    &-status {
        @include props-margin;
        @include margin;

        display: inline-flex;
        align-items: flex-end;

        &__item {
            @include props-padding;
            @include props-border;
            --border-color-active: var(--border-color);
            --border-color-hover: var(--border-color);
            --border-color-disabled: var(--border-color);
            --border-color-active-disabled: var(--border-color);

            @include props-colors;
            --background-active: var(--background);
            --color-active: var(--color);
            --background-hover: var(--background);
            --color-hover: var(--color);
            --background-disabled: var(--background);
            --color-disabled: var(--color);
            --background-active-disabled: var(--background);
            --color-active-disabled: var(--color);

            @include padding;
            @include border;
            @include colors;

            margin-right: var(--sw-multiple-px);
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-decoration: none;
            cursor: pointer;

            > * {
                display: inline-block;
                border-bottom: 1px dashed var(--background-active);

                @media only screen and ( -webkit-min-device-pixel-ratio: 2 ){
                    position: relative;
                    top: 1px;
                }
            }

            &--active,
            &.active,
            &--disabled,
            &:hover {
                > * {
                    border-bottom-color: transparent;
                }
            }

            &--active,
            &.active {
                background: var(--background-active);
                border-color: var(--border-color-active);
                color: var(--color-active);
            }

            &:hover {
                background: var(--background-hover);
                border-color: var(--border-color-hover);
                color: var(--color-hover);
            }

            &--disabled {
                background: var(--background-disabled);
                border-color: var(--border-color-disabled);
                color: var(--color-disabled);
                pointer-events: none;

                &.sw-tab-status__item--active,
                &.active {
                    background: var(--background-active-disabled);
                    border-color: var(--border-color-active-disabled);
                    color: var(--color-active-disabled);
                }
            }
        }
    }
}

// implemented
.sw-tab {
    &-status {
        --margin-start: calc(var(--sw-multiple-px) * 8);

        .os-Windows & {
            position: relative;
            top: 3px;
        }

        &__item {
            --padding-start: calc(var(--sw-multiple-px) * 4);
            --padding-end: calc(var(--sw-multiple-px) * 4);
            --padding-top: calc(var(--sw-multiple-px) * 2);
            --padding-bottom: calc(var(--sw-multiple-px) * 2);

            --color: var(--sw-color-action);
            --background-active: var(--sw-color-action);
            --color-active: var(--sw-color-action-contrast);
            --background-hover: var(--sw-background-medium);
            --color-hover: var(--sw-color-action);
            --background-disabled: var(--sw-background-dark);
            --color-disabled: var(--sw-text-color-secondary);
            --background-active-disabled: var(--sw-text-color-secondary);
            --color-active-disabled: var(--sw-text-color-contrast);

            --border-radius: 16px;
            --border-style: solid;
            --border-width: 1px;
            --border-color: transparent;
            --border-color-active: var(--background-active);
            --border-color-hover: var(--sw-border-color);
            --border-color-disabled: var(--background-disabled);
            --border-color-active-disabled: var(--background-active-disabled);
        }
    }
}
