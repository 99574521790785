.sw-stepper {
    @extend .sw-list;
    display: flex;
    align-items: center;

    > .sw-item {
        position: relative;
        cursor: pointer;

        &--disabled {
            background: var(--background-disabled);
            pointer-events: none;
        }

        &--selected {
            &:hover {
                background: var(--background-selected);
            }
        }
    }
}

// implemented
.sw-stepper {
    .sw-header & {
        --min-height: 56px;
        --padding-start: 37px;
        --padding-end: 12px;
        --padding-top: 16px;
        --padding-bottom: 16px;
        --background: var(--sw-header-background);
        // --background-hover: var(--sw-color-primary);
        --background-selected: var(--sw-color-primary);

        > .sw-item {
            flex-shrink: 0;
            min-width: 213px;
            cursor: default;
            pointer-events: none;

            &:first-child {
                &::before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 0;
                    clip-path: url(#step-arrow);
                    width: 18px;
                    height: var(--min-height);
                    transform: translateY(-50%);
                    background-color: var(--background);
                }
            }

            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                top: 50%;
                left: calc(100% - 0.5px);
                clip-path: url(#step-arrow);
                width: 18.5px;
                height: var(--min-height);
                transform: translateY(-50%);
                background-color: var(--background);
                background-image: url('/assets/images/step-border.svg');
                background-position: 100% 50%;
            }

            &--selected,
            &:active {
                &::after {
                    background-color: var(--background-selected);
                }
            }
            // &:hover {
            //     &::after {
            //         background-color: var(--background-hover);
            //     }
            // }
        }

        sw-step {
            --font-size: 14px;
            --font-weight: 500;
            --line-height: 24px;
            --color-muted: var(--sw-text-color-secondary);

            --sign-margin: 10px;
            --sign-background: var(--sw-text-color-secondary);
            --sign-background-muted: var(--sw-text-color-secondary);
            --sign-background-active: var(--sw-color-white);
            --sign-color-muted: var(--sw-color-white);
            --sign-size: 24px;

            &::part(sign) {
                line-height: var(--sign-size);
            }

            &.sw-step_check {
                --sign-color: var(--background);
                --sign-color-active: var(--background-selected);
            }
            &.sw-step_outline {
                --sign-color-active: var(--sw-color-white);
            }
        }

        @media (max-width: 1313px) {
            > .sw-item {
                --padding-start: 33px;
                --padding-end: 8px;
            }

            sw-step {
                --font-size: 13px;
            }
        }

        @media (max-width: 1250px) {
            > .sw-item {
                min-width: 100px;
            }

            sw-step {
                --font-size: 12px;
            }
        }
        @media (max-width: 1188px) {
            position: relative;
            left: -30px;
        }
        
        @media (max-width: 1160px) {
            sw-step {
                &::part(sign) {
                    display: none;
                }
            }
        }
    }

    &_content {
        --min-height: 40px;
        --padding-start: 12px;
        --padding-end: 12px;
        --padding-top: 12px;
        --padding-bottom: 12px;
        --background: var(--sw-color-action);
        --background-disabled: var(--sw-background-dark);
        --background-selected: var(--sw-color-primary);
        --color: var(--sw-color-white);

        > .sw-item {
            flex-shrink: 0;
            min-width: 25%;
            cursor: default;
            pointer-events: none;

            // &:first-child {

            //     &::before {
            //         content: '';
            //         position: absolute;
            //         z-index: 1;
            //         top: 50%;
            //         left: 0;
            //         clip-path: url(#step-arrow);
            //         width: 18px;
            //         height: var(--min-height);
            //         transform: translateY(-50%);
            //         background-color: var(--background);
            //     }
            // }

            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 100%;
                clip-path: url(#step-arrow);
                width: 22px;
                height: var(--min-height);
                transform: translateY(-50%);
                background-color: var(--background);
                background-image: url('/assets/images/step-border-2.svg');
                background-position: 100% 50%;
            }

            &:last-of-type {
                &::after {
                    display: none;
                }
            }

            &--selected,
            &:active {
                &::after {
                    background-color: var(--background-selected);
                }
            }
            &--disabled {
                &::after {
                    background-color: var(--background-muted);
                }
            }
            // &:hover {
            //     &::after {
            //         background-color: var(--background-hover);
            //     }
            // }
        }

        sw-step {
            --font-size: 12px;
            --font-weight: 500;
            --line-height: 14px;
            --color-muted: var(--sw-text-color-secondary);
            --color-active: var(--sw-color-white);
            justify-content: center;
        }
    }
}
