.sw-dispatcher-page {
    height: 100vh;
    overflow: hidden;
    background: var(--sw-border-color);

    .sw-header {
        padding-left: 8px;
        padding-right: 12px;

        &__left {
            .sw-btn_outline {
                --border-radius: 50%;
                --background: rgba(var(--sw-color-white-rgb), 0.1);
                --background-hover: rgba(var(--sw-background-medium-rgb), 0.1);
                // --background-activated: ;
                // --background-disabled: ;
                --border-color: rgba(var(--sw-background-info-rgb), 0.3);
                --border-color-hover: rgba(var(--sw-background-info-rgb), 0.1);
                // --border-color-focused: ;
                // --border-color-disabled: ;
                --color: var(--sw-color-white);
                // --color-disabled: ;
            }
        }
        &__title {
            padding: 10px 16px;
            border-right: 1px solid rgba(224, 222, 220, 0.5);
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;
        }

        .sw-btn-group {
            margin: 0 24px; // right 32
        }

        .sw-control_datetime .sw-control {
            --background: transparent;
            --border-width: 0;
            --color: var(--sw-color-white);
            --font-size: 16px;
            width: 165px;
            cursor: pointer;

            &__field {
                font-weight: 500;

                &_decor {
                    // проверить в винде и на кросс браузерность
                    // position: absolute;
                    // z-index: 5;
                    // bottom: 5px;
                    // left: var(--padding-field-start);
                    padding-bottom: 2px;
                    border-bottom: 1px dashed var(--sw-color-white);
                    font-size: 16px;
                    font-weight: 500;
                    pointer-events: none;
                }

                &-wrapper {

                    input {
                        opacity: 0;
                        pointer-events: none;
                    }
                }

                &-group {
                    sw-icon {
                        margin: 0 -6px;
                        color: var(--sw-color-white);
                    }

                    &__start {
                        .sw-btn_clear {
                            --background-hover: rgba(var(--sw-color-white-rgb), 0.1);
                            --background-activated: rgba(var(--sw-background-medium-rgb), 0.1);
                            --background-focused: var(--background-hover);
                            --color: var(--sw-color-white);
                        }
                    }
                }
            }

            &__note {
                display: none;
            }
        }
    }

    .sw-dropdown_notifications .sw-dropdown__body {
        transform: translateX(0);
    }

    .sw-footer {
        --background: var(--sw-background);
        --color: var(--sw-text-color);
        --padding-top: 0;
        --padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
        border-top: 1px solid var(--sw-border-color);

        app-planning-page & {
            margin: 0 8px;
        }

        &__btn-group {
            sw-button.sw-btn_clear {
                --border-radius: 0;
                --color: var(--sw-text-secondary);
                --color-hover: var(--sw-color-tint);
                --color-activated: var(--sw-color-base);
                border-right: 1px solid var(--sw-border-color);
                width: 35px;
            }
        }

        .sw-list_h {
            > .sw-item {
                --margin-end: calc(var(--sw-multiple-px) * 6);
            }

            sw-icon {
                --sw-icon-xs-size: 12px;
                --sw-icon-small-size: 16px;

                &:first-child:not(:nth-last-child(2)) {
                    margin-right: 8px;
                }

                &:last-child {
                    margin-left: 4px;
                }
            }

            .sw-text-size_s_reduced {
                vertical-align: middle;
            }
        }

        .sw-dropdown {
            --width: 250px;

            &__trigger {
                cursor: pointer;
            }
            &__body {
                --top: auto;
                --bottom: 20px;
            }
        }

        .sw-clock {
            span {
                display: inline-block;
                width: 125px;
            }
        }
    }

    .sw-dispatcher-settings {
        --padding-start: 12px;
        --padding-end: 12px;
        --padding-bottom: 4px;

        p {
            margin: 0 0 16px;
            font-size: 13px;
            line-height: 20px;
            color: var(--sw-text-color-secondary);
        }

        .sw-control {
            --margin-bottom: 16px;

            &_short {
                .sw-control__label {
                    --font-size-label: var(--font-size);
                    font-weight: 400;
                }
                .sw-control__field-group {
                    max-width: 140px;
                }
            }
        }
    }

    &__content {
        height: calc(100% - 32px - 56px); // footer + header
        background: var(--sw-background);
        border-radius: 3px 3px 0 0;

        app-planning-page & {
            height: calc(100% - 40px - 56px); // footer + header
            margin: 8px 8px 0;
        }

        .sw-dispatcher-settings {
            width: 236px;
        }

        .sw-btn-filter {
            --background: var(--background-hover);

            sw-badge[slot=end]{
                position: static;

                @media only screen and ( -webkit-max-device-pixel-ratio: 1 ){
                    line-height: var(--height-large);
                }
            }
        }
    }

    table {
        tr {
            &.sw-row_fix-height {
                --child-count: 1;

                td {
                    min-height: 59px;
                    height: calc(59px * var(--child-count));
                    overflow: hidden;
                }

                &.sw-row_fix-height_mini {
                    td {
                        min-height: 41px;
                        height: calc(41px * var(--child-count));
                    }
                }
            }
        }

        td,
        th {
            --padding-start: 6px;
            --padding-end: 6px;

            &:first-child {
                --padding-start: 16px;
            }
        }

        th {
            --padding-bottom: 4px; // если в таблице есть кнопка

            sw-button {
                margin-top: -8px;
            }

            &:not(.sw-col_sortable) {
                sw-button {
                    opacity: 0;
                }

                &:hover {
                    sw-button {
                        opacity: 1;
                    }
                }
            }

            &.swh-sticky {
                --padding-bottom: 1.5px;
            }
        }

        td {
            --padding-top: 12px;
            --padding-bottom: 12px;

            sw-badge.sw-badge-large {
                --height-large: 20px;
                --font-size-large: 13px;
                --font-weight: 400;

                &:first-child:last-child {
                    --margin-start: 0;
                    --margin-end: 0;
                }
            }

            sw-chip {
                --margin-start: 0;
                --margin-end: 0;
            }

            .sw-col_fix-height {
                min-height: 32px;
                max-height: 32px;
                overflow: hidden;
            }

            &.swh-sticky {
                --padding-start: 3px;
                --padding-end: 3px;
            }
        }

        app-task-table td {
            --padding-top: 6px;
            --padding-bottom: 6px;
        }

        .sw-row_danger td:first-child,
        .sw-row_warning td:first-child {
            position: relative;

            &::before {
                position: absolute;
                top: 13px;
                left: 0;
                content: '';
                padding: 0;
                width: 4px;
                height: 16px;
            }
        }
        .sw-row_danger td:first-child::before {
            background: var(--sw-color-danger);
        }
        .sw-row_warning td:first-child::before {
            background: var(--sw-color-warning);
        }

        .sw-row_dashed td {
            --border-style: dashed;
        }

        .sw-table__action-wrap {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            display: flex;
            align-items: flex-start;
            background: linear-gradient(
                270deg,
                var(--sw-color-white) 75%,
                rgba(var(--sw-color-white-rgb), 0.8) 94.14%
            );
            box-shadow: -2px 2px 15px rgba(var(--sw-shadow-color-rgb), 0.4);
            min-width: 40px;
            padding: 4px 3px 0 3px;

            sw-button.sw-color:hover {
                sw-icon {
                    color: currentColor;
                }
            }

            sw-button.sw-btn-filter {

                &.sw-btn_clear {
                    --background: transparent;
                    --color: var(--sw-text-color);
                }
                &.sw-btn_solid sw-icon {
                    color: var(--sw-color-white);
                }
            }
        }

        app-position-table .sw-table__action-wrap {
            // width: 70px
        }
    }

    .sw-block_pane--expanded {
        top: 121px;
        height: calc(100% - 153px);
        width: 50%;
    }

    .sw-planning-base {
        position: relative;
        height: calc(100% - 114px);

        .sw-settings-column {
            position: absolute;
            top: 0;
            right: 4px;
            z-index: 152;
            background: var(--sw-background-medium);
        }
    }

    .sw-filter-applied-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 12px 16px 4px;

        .sw-tag {
            margin-bottom: 8px;
        }
    }
}
