.sw-icon-wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-left: 8px;
    border-radius: 50%;
    background: var(--sw-color-action);
    text-align: center;
    color: var(--sw-color-action-contrast);

    sw-icon {
        --sw-icon-small-size: 12px;
    }
}

.sw-logs {
    word-wrap: break-word;
    overflow-y: auto;
    max-height: 400px;
}

app-sent-status-widget {
    .sw-chip_clear {
        --padding-start: 0;
        --padding-end: 0;
    }
}

app-profile-license-address {
    .sw-toolbar_table {
        --padding-top: 16px;
        --padding-bottom: 16px;

        &::part(start) {
            width: 100%;
        }

        [slot=start] {
            display: block;
        }
    }
    // .sw-paginator_secondary {
    //     padding-bottom: 20px;
    // }

    .sw-page {
        --padding-bottom: 20px;
    }
}
