.sw-dummy {
    --sw-dummy-bg: #2F80ED;
    --sw-dummy-bg-img: url(#{$path}/img/art.svg);
    --sw-dummy-bg-repeat: no-repeat;
    --sw-dummy-bg-pos: inherit;
    --sw-dummy-bg-size: contain;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    width: 100%;
    padding: var(--sw-dummy-padding, 80px 100px);

    background-color: var(--sw-dummy-bg);
    background-image:   var(--sw-dummy-bg-img);
    background-repeat: var(--sw-dummy-bg-repeat);
    background-size: var(--sw-dummy-bg-size);
    background-position: var(--sw-dummy-bg-pos);
}
