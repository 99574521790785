.sw-layout {
    &_press-footer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;

        &__content {
            height: 100%;
            overflow: auto;
        }
    }

    &_flex {
        --margin-side: 44px;
        display: flex;
        align-items: flex-start;
        width: 100%;

        aside {
            flex-shrink: 0;

            &.sw-aside_left {
                width: 280px;
                margin-right: var(--margin-side);
            }

            &.sw-aside_right {
                margin-left: var(--margin-side);
            }
        }

        main {
            width: 100%;
        }
    }
}
