.sw-tag {
    /**
    * @prop --background: фон тега
    * @prop --background-hover: фон тега при наведении
    * @prop --background-disabled: фон тега при отключенной теге
    *
    * @prop --color: цвет текста тега
    * @prop --color-hover: цвет текста тега при наведении
    * @prop --color-disabled: цвет текста тега при отключенной теге
    *
    * @prop --transition: переход
    *
    * @prop --border-radius: радиус скругления
    * @prop --border-width: толщина границы
    * @prop --border-style: стиль границы
    * @prop --border-color: цвет границы
    * @prop --border-color-hover: цвет границы при наведении
    * @prop --border-color-disabled: цвет границы при отключенной кнопке
    *
    * @prop --box-shadow: тень тега
    * @prop --opacity: прозрачность тега
    *
    * @prop --padding-top: внутренний верхний отступ
    * @prop --padding-end: внутренний правый отступ
    * @prop --padding-bottom: внутренний нижний отступ
    * @prop --padding-start: внутренний левый отступ
    *
    * @prop --multiple: число увеличения кратности, необходимо для расчета изменения размеров тега.
    *
    * @prop --height: высота тега
    * @prop --width: ширина тега
    */
    @include props-block-model;
    @include props-width-height;
    @include props-colors;

    @include block-model;
    @include width-height;
    @include colors;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--sw-multiple-px);
    opacity: var(--opacity);

    font-size: 13px;
    font-weight: 500;

    button {
        outline: none;
        background: transparent;
        border: none;
        padding: 4px;

        sw-icon {
            --sw-icon-medium-size: 12px;
            color: var(--sw-text-color-secondary);
        }
    }

    &s-group {
    }
}

.sw-tag {
    --height: 36px;
    --background: var(--sw-background-medium);
    --border-radius: var(--sw-border-radius);
    --padding-start: 16px;
    --padding-end: 12px;
    --color: var(--sw-text-color);

    /* переменые для блоков с голубым фоном */
    // --background: var(--sw-color-white);
    // --border-color: var(--sw-border-color-dark);
    // --border-width: 1px;
    // --border-style: solid;

    &.sw-color {
        --background: var(--sw-color-base);
        --color: var(--sw-color-contrast);

        sw-icon {
            color: var(--color);
        }
    }

    // types
    &_small { // 32px
        height: calc(var(--height) - var(--sw-multiple-px));
    }
    &_large { // 44px
        height: calc(var(--height) + var(--sw-multiple-px) * 2 );
        font-size: 14px;
        letter-spacing: 0.5px;
    }
}
