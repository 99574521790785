.sw-row-elements {
    @include props-margin;
}

// implemented
.sw-row-elements {
    --margin-end: calc(var(--sw-multiple-px) * 4);

    > * {
        margin-right: var(--margin-end);

        &:last-child {
            margin-right: 0;
        }
    }
}
